import React,{useEffect,useState,useCallback, Fragment} from 'react';
import {Marker,InfoWindow} from '@react-google-maps/api';
import LogoSuzukiOn from '../assets/SuzukiPinOn.svg';
import LogoSuzukiOff from '../assets/SuzukiPinOff.svg';
import Typography  from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';



export default function InfoMarker(props) {
  
    const [isVisibleWindow,setisVisibleWindow] = React.useState(false);  
    const {id,posicion, nombre,direccion,isEncendido, urlImagen} = props.dataX;
    //console.log(posicion);
   
    const divStyle ={
        width:'100%',
        background: `white`,
        border: `1px #ccc`,
        padding: 10
    }

    function clickEnMarker () {
       // console.log("di click en un marker");
        setisVisibleWindow(true);
    }

    const cerrandoVentana = () =>{
        setisVisibleWindow(false);
    }

    return (
        <div>
             <Marker onClick={clickEnMarker} position={ {lat: posicion.latitude, lng: posicion.longitude}} 
                    icon ={(isEncendido) ? LogoSuzukiOn : LogoSuzukiOff} />
             {isVisibleWindow ? <InfoWindow
                    onCloseClick = {cerrandoVentana}
                    position={{lat: posicion.latitude, lng: posicion.longitude}} 
                   >
                   <Grid container direction ="column" justify="center"  alignItems="center" style={divStyle}>
                    <img alt="imagenSucursal" width = "300px" height="auto" style={{ marginBottom:20, borderStyle: 'groove', borderColor:'red' }}src={urlImagen}/>
                    <Typography color ="primary"  variant="h6">{nombre}</Typography>
                    <Typography color ="textSecondary" variant="body1">{direccion}</Typography>
                    </Grid>
              </InfoWindow> : <></>}
                
        </div>
    )
}


             

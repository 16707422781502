import {createStore,combineReducers, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk'; //para hacer promesas con redux es un middleware
//Siempre llamar al reducer
import incidenciasReducer from './incidenciasDucks';
import sucursalReducer from './sucursalDucks';


//Añadimos reducers del proyecto
const rootReducer = combineReducers({
    incidencias: incidenciasReducer,//aqui añadir mas reducers
    sucursal: sucursalReducer,
})

//Preguntamos si tenemos la extencion de redux tools o si no usamos la extencion compose
const composeEnhacers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore(){

    const store = createStore(rootReducer, composeEnhacers(applyMiddleware(thunk)))
    return store;

}
import React,{useEffect,useState} from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MUIDataTable from 'mui-datatables';
import {makeStyles} from '@material-ui/core/styles';
import AddUsuarioIcon from '@material-ui/icons/AddCircle';
import ListItemIcon  from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListItem  from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import {db} from '../backEnd/firebase';
import {ModalAdminUsuariosContext} from '../contexts/modalAdminUsuariosProvider';
import { useContext } from 'react';
//Estilos de la tabla de usuarios
const estilos = makeStyles(theme =>( {
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    button:{
        margin: theme.spacing(3),
        marginRight: theme.spacing(6)
    },
    buttonModal:{
      margin: theme.spacing(1),
    },
    textoIcono:{
        verticalAlign : 'middle',
        display:'inline-flex',
        margin: 'inherit',
        color:'blue'
    },
    tituloModalEliminar:{
        color: theme.palette.secondary.light
    },
    textoModalEliminar:{
        color: theme.palette.secondary.dark
    },
    icono:{
        verticalAlign : 'middle',
        display:'inline-flex',
        backgroundColor: 'yellow',
        color: '#fff',
        background: 'yellow',
        margin: '400'
    },imgUser:{
    
      margin: theme.spacing(1),
      width: theme.spacing(9),
      height: theme.spacing(9),
    }, dividerVertical:{
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2)
    },avatarSize: {
      width:theme.spacing(13),
      height: theme.spacing(13),
      marginBottom: theme.spacing(2)
    }
}));

//Variables de configuracion de la tabla
const columns = ["Nombre de usuario"," ", "Sucursal","Nivel de acceso", "Correo", "Acciones"];
const options = {
    filterType: 'checkbox',
    filter: true,
    rowsPerPageOptions:[5,10,100],
    rowsPerPage :5,
    selectableRowsHeader:false,
    selectableRows:'none',
    textLabels: {
      body: {
        noMatch: "lo sentimos, busqueda no encontrada",
        toolTip: "Sort",
        columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: "Pagina siguiente",
        previous: "Pagina anterior",
        rowsPerPage: "Usuarios por pagina:",
        displayRows: "de",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    }
  };

function AdminUsuarios() {

  //Estados del componente para Read
  const [usuarios,setUsuarios]= useState([]);

  
  //context del modal de Admin de Usuarios
  const {handleClickOpenEliminarUserModal,
    handleClickOpenEditarUserModal,handleClickOpenAddUserModal,
     ModalAdminUsuarios} = useContext(ModalAdminUsuariosContext);

   //Declaramos la funcion del efecto
   const obtenerUsuarios = async()=>{
    try{ 
     await db.collection('usuarios').onSnapshot(dataUsuarios =>{

    console.log(dataUsuarios.docs);
    //convertimos el objeto de fb a un array para el estado
     // const arrayUsuarios = dataUsuarios.docs.map(doc =>({id:doc.id,... doc.data()}));
     const arrayUsuarios = dataUsuarios.docs.map(doc => doc.data());
     console.log(arrayUsuarios);
     setUsuarios(arrayUsuarios);
      }).catch(err=>{
        console.log(err);
      });
     
    }
    catch (error){
      console.log(error);
    }
  }

  //Efectos del componente
   useEffect(()=>{
   
  //llamamos la funcion del efecto
  obtenerUsuarios();

   },[]);


   //llamamos el estilo a la funcion
    const classes = estilos();
 //Iconos de editar y borrar de la tabla
     const iconosDeRowElement = (user) =>{ 
       
     return(
     <ListItem >
     <ListItemIcon  onClick={()=> {handleClickOpenEditarUserModal(user);}} >
         <IconButton color='inherit' ><EditIcon fontSize='large' style = {{color:'#3377AA'}}/></IconButton>
     </ListItemIcon>
     <ListItemIcon  onClick={()=> {handleClickOpenEliminarUserModal(user);}}>
         <IconButton color='secondary' ><DeleteIcon fontSize='large' color='secondary'/></IconButton>  
     </ListItemIcon>                   
     </ListItem>)
     }

     const imgUsuario=(urlImg)=>  {
       return(
     <Box display="flex" justifyContent ="center" mb={0.5} pt={0.5}>
     <Avatar alt="user" src={urlImg} className={classes.imgUser} /> </Box> 
     )};
 
  //Data de la tabla
const data = usuarios.map(usuario => (
 // console.log(usuario)//((typeof (usuario.sucursal)) === 'undefined') ? "":usuario.sucursal.nombre 
  [usuario.nombre,imgUsuario(usuario.imgUsuario),(typeof (usuario.sucursal)) === 'undefined' ? "":usuario.sucursal.nombre,usuario.rol,usuario.email,iconosDeRowElement(usuario)]
));
    

return (
        <Container maxWidth='xl'>
            <Typography color='textSecondary' variant={'h5'}>Administrador de usuarios</Typography>
            <Divider/>
            <Grid container direction="row" justify="flex-end" alignItems="center">    
                <Button  onClick={handleClickOpenAddUserModal} variant="contained" size='large' 
                color="primary" className = {classes.button}  startIcon={<AddUsuarioIcon/>}>
                    Añadir usuario 
                </Button>
            </Grid>   
            <MUIDataTable
                title={"Lista de usuarios"}
                data={data}
                columns={columns}
                options={options}
            />
            <ModalAdminUsuarios/>
        </Container>  
    )
}

export default AdminUsuarios

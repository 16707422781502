import React,{useEffect,useState,useCallback,createContext,useMemo,useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Divider  from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import EstadosLista from '../assets/listaEstados';
import Progress from '@material-ui/core/LinearProgress';
import {db} from '../backEnd/firebase';
import {useDropzone} from 'react-dropzone';
import Dropzone from 'react-dropzone';
import RootRef from '@material-ui/core/RootRef';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/CancelRounded';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';
import { id } from 'date-fns/esm/locale';



//context que sera usado en componentes
export const ModalContenidosContext = createContext();

const estilos = makeStyles(theme =>( {
  colores:{
    okBlue: theme.palette.primary
  },
  button:{
      margin: theme.spacing(3),
      marginRight: theme.spacing(6)
  },
  buttonModal:{
    margin: theme.spacing(1),
  },
  textoIcono:{
      verticalAlign : 'middle',
      display:'inline-flex',
      margin: 'inherit',
      color:'blue'
  },
  tituloModalEliminar:{
      color: theme.palette.secondary.light
  },
  textoModalEliminar:{
      color: theme.palette.secondary.dark
  },
  icono:{
      verticalAlign : 'middle',
      display:'inline-flex',
      backgroundColor: 'yellow',
      color: '#fff',
      background: 'yellow',
      margin: '400'
  },closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:theme.spacing(0),
  }
}));



const ModalContenidosProvider = (props) => {
   
   //Estilos de componente
   const classes = estilos();

   //Variables de Modal de eliminar Contenido
   const [openModalEliminarContenido, setOpenModalEliminarContenido] = React.useState(false);
   const handleClickOpenEliminarContenido = (Contenido) => {setOpenModalEliminarContenido(true);setSelectedContenido(Contenido);};
   const handleCloseEliminarContenido  = () => {setOpenModalEliminarContenido(false);};

    //Variables de Modal Editar Contenido
    const [openModalEditar, setOpenModalEditar] = React.useState(false);
    const handleClickOpenEditarContenidoModal = (Contenido) => {setOpenModalEditar(true);setSelectedContenido(Contenido)};
    const handleCloseEditarModal = () => {setOpenModalEditar(false);};
    
    //Variables de Modal Añadir Contenido
    const [openModalAddContenido, setOpenModalAddContenido] = React.useState(false);
    const handleClickOpenAddContenidoModal = () => {setOpenModalAddContenido(true);};
    const handleCloseAddContenidoModal = () => {setOpenModalAddContenido(false);};

    const [selectedContenido,setSelectedContenido] = useState({});

    //Modal de video
    const [openVideoModal,setOpenVideoModal] = useState(false);
    const handleClickOpenVideoModal = (url) => {setOpenVideoModal(true); setSelectedContenido(url)};
    const handleClickCloseVideoModal = () => {setOpenVideoModal(false);};

    //VAriables para los snack
    const Alert = (props) => {
      return (<MuiAlert elevation={6} variant="filled" {...props} />);
    }
    const [abrirSnackBar,setAbrirSnackBar] = useState(false);

const AlertaSnackBar =()=>{
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAbrirSnackBar(false);
  }; 

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAbrirSnackBar(false);
  }

  const slideTransition = (props) =>{
    return (<Slide {...props} direction="down" />)
  }
 
  return(
    <Snackbar
          anchorOrigin={{ vertical:'top', horizontal:'center' }}
          open={abrirSnackBar}
          onClose={handleCloseSnackBar}
          autoHideDuration={2000}
          TransitionComponent={slideTransition}
          key={slideTransition}
        >
        <Alert onClose={handleClose}   severity="success" className={classes.colores}>
          contenido editado
        </Alert>
    </Snackbar>)
}

const ModalContenidos = ()=>{

  const [cerrarclickBack,setCerrarClickBack] = useState(false);
  //VAriables para los snack
 // const [abrirSnackBar,setAbrirSnackBar] = useState(false);

  /* CREAR Contenido */
  const [nombre,setNombre] = useState('');

  /* EDITAR UNA Contenido */ 
  const [nombrePivotEdit,setnombrePivotEdit] = useState(selectedContenido.nombre);

 //Variables de Error y loading
 const [nError,setnError] = useState(null);
 const [nOkResponse,setOkResponse] = useState(null);
 const [loadingFbQuery,setloadingFbQuery] = useState(false);
 const [botonesEnabled,setbotonesEnabled] = useState(true);

  //Variables y funciones de Selector lista de reproduccion
  const [URLContenido, setURLContenido] = React.useState('');


  //Contenido nuevo
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    marginBottom:'6px',
    borderWidth: 4,
    borderRadius: 4,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    wordWrap:'break-word'
  };
  const activeStyle = {
    borderColor: '#2196f3'
  };
  const acceptStyle = {
    borderColor: '#00e676'
  };
  const rejectStyle = {
    borderColor: '#ff1744'
  };
  
  //Configuracion del drop zone 
  const videoMaxSize = 100000000;//10MB 
  const[localURLcontenido,setlocalURLcontenido] = useState('');
  const[uploadPorcentaje, setUploadPorcentaje] = useState(0);
  const[updloadProgressText,setUploadProgressText] = useState('');

 const onDropeoRechazado = (res) =>{
    console.log(res[0]);
   console.log(JSON.stringify(res[0]));
    if(res[0].errors[0].code =="file-too-large")
      {
        setnError("El video es demasiado largo, debe ser menor de " + videoMaxSize/1000000 + " MB");
      }
    else if(res[0].errors[0].code =="file-invalid-type"){
      setnError("Introduce un archivo .mp4");
    }
    else{
      setnError(res[0].errors[0].code);
    }
 }

const onDropeoAceptado = res =>{

  console.log(JSON.stringify(res));
  console.log("archivo aceptado");
  setnError("");
  //Hay que setear la url del archivo y quiza un preview o un algo que indicque que se tiene listo para subir
  setlocalURLcontenido(res[0].path);
  setURLContenido(res);
}

//Hook del drop zone
const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject
    } = useDropzone({accept: 'video/mp4',maxSize:videoMaxSize,multiple:false,
                    onDropRejected:onDropeoRechazado,onDropAccepted:onDropeoAceptado});
  
//Estilo del dropZone
    const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isDragActive,
      isDragReject,
      isDragAccept
    ]); 
    const {ref, ...rootProps} = getRootProps({style})
    //Upload Handler
    const optionsUploader= {
      onUploadProgress:(progressEvent) =>{
        const {loaded,total} = progressEvent;
        let percent = Math.floor(loaded*100/total)
        setUploadProgressText(`${(loaded/1000000).toFixed(2)}Mb de ${(total/1000000).toFixed(2)}Mb | ${percent}%`)
        console.log(`${loaded.toFixed(2)}kbof ${total.toFixed(2)}kb | ${percent}%`)

        if(percent < 100){
          setUploadPorcentaje(percent);
        }
      }
    }

 //Funcion para eliminar Contenido
 const eliminarContenido =  useCallback(async () =>{
  db.collection('contenidos').doc(selectedContenido.id).delete()
                 .then(respuesta => {
                     console.log('Contenido eliminada de los documentos ' + respuesta)
                     handleCloseEliminarContenido();
                    return "usuario Eliminado con Exito";
                 }).catch(error=>{
                   return error
                 }); 
  
  });

//Funcion para crear una Contenido
const crearContenido = () =>{

  setnError(null);
  setloadingFbQuery(true);
  setbotonesEnabled(false);
  console.log("Vamos a intentar añadir una Contenido")
 
  if(!nombre.trim() && !localURLcontenido.trim()){
    console.log('no hay datos ');
    setloadingFbQuery(false);
    setbotonesEnabled(true);
    setnError('Campos vacios');
    return
  }

  if(!nombre.trim() ){
    console.log('no hay nombre ');
    setloadingFbQuery(false);
    setbotonesEnabled(true);
    setnError('Campo de nombre vacio');
    return
  }
  else if( !localURLcontenido.trim()){
    console.log('no hay archivo');
    setloadingFbQuery(false);
    setbotonesEnabled(true);
    setnError('No has agregado un video');
    return
  }
  console.log('vamos a revisar')
 
  //Añadimos usuario
  fbCrearContenido();
  
}
const fbCrearContenido = async() =>{

  setCerrarClickBack(true);
  let data = new FormData();
  let jeders = {headers: {'Content-Type':'multipart/form-data'}};
  let dato = URLContenido;
  data.append("archivo",dato[0]);
  axios.post('https://suzukiv2.sangsang.mx/ArchivosVideoImagen/index.php',data,optionsUploader,jeders).
    then(res =>{
      console.log(res);
        if( res.data === "hubo un error en el lado del Servidor"){
        setnError("Video NO subido, " + res.data);
        setloadingFbQuery(false);
        setbotonesEnabled(true);
      }


    setUploadPorcentaje(100,setTimeout(() => {
      if (res.data === "El archivo " + dato[0].path +" ha sido subido."){
        setOkResponse("El archivo " + dato[0].path +" ha sido subido.")
      }
      setloadingFbQuery(false);
      setbotonesEnabled(true);
      setUploadPorcentaje(0);
    }, 1000));
    
    
  }).then(()=>{

    console.log("Vamos a comunicarnos con firestore")
    db.collection('contenidos').add({
      nombre:nombre,
      URLContenido:'https://suzukiv2.sangsang.mx/ArchivosVideoImagen/uploads/'+ dato[0].path ,
    }).then((res)=>{
      console.log(res)
     // setOkResponse("Video agregado " + res);
      setbotonesEnabled(true);
      setloadingFbQuery(false);
      setCerrarClickBack(false);
      handleCloseEditarModal(false);
  
    }).catch(e=>{
      console.error(e);
      setnError(e);
      setbotonesEnabled(true);
      setloadingFbQuery(false);
      setCerrarClickBack(false);
    });
  })
}

//Funcion para Updatear una Contenido 
const updateContenido = () =>{
  setnError(null);
  setloadingFbQuery(true);
  setbotonesEnabled(false);
  console.log("Vamos a intentar editar una Contenido")
  let contenidoData = {};

  if(!nombre.trim() && !localURLcontenido.trim()){
    console.log('no hay datos ');
    setloadingFbQuery(false);
    setbotonesEnabled(true);
    setnError('Campos vacios');
    return
  }
 
  if(localURLcontenido.trim()  && nombre.trim()){
    console.log('mandar ambos');
    contenidoData = {...contenidoData,nombre: nombre,URLContenido: 'https://suzukiv2.sangsang.mx/ArchivosVideoImagen/uploads/'+ URLContenido[0].path}
  } else if(!localURLcontenido.trim()){
    console.log('solo Mandare nombre');
    contenidoData = {...contenidoData,nombre: nombre}
  } else if(!nombre.trim()){
    console.log('solo Mandare Video');
    contenidoData = {...contenidoData,URLContenido: 'https://suzukiv2.sangsang.mx/ArchivosVideoImagen/uploads/'+ URLContenido[0].path}
  }
  

  console.log('vamos a comunicar con la DB el data ' + JSON.stringify(contenidoData) )

  //Actualizamos Contenido
   fbEditarContenido(contenidoData);
}
const fbEditarContenido = async(contenidoData) =>{

  //console.log(contenidoData);
  if(contenidoData.nombre  && contenidoData.URLcontenido ){
    console.log("actualizare nombre y video por lo tanto preparare el video");
    setCerrarClickBack(true);
    let data = new FormData();
    let jeders = {headers: {'Content-Type':'multipart/form-data'}};
    let dato = URLContenido;
    data.append("archivo",dato[0]);
    axios.post('https://suzukiv2.sangsang.mx/ArchivosVideoImagen/index.php',data,optionsUploader,jeders).
      then(res =>{
        console.log(res);
        //Handling a error on update
      if( res.data === "hubo un error en el lado del Servidor"){
          setnError("Video NO subido, " + res.data);
          setloadingFbQuery(false);
          setbotonesEnabled(true);
        }
        //eséramos poquito para que se vea el 100 % dibujado
      setUploadPorcentaje(100,setTimeout(() => {
        if (res.data === "El archivo " + dato[0].path +" ha sido subido."){
          setOkResponse("El archivo " + dato[0].path +" ha sido subido.")
        }
        setloadingFbQuery(false);
        setbotonesEnabled(true);
        setUploadPorcentaje(0);
      }, 1000));
      
      //Pasamos a hacer el update en la db
      
    }).then(db.collection('contenidos').doc(selectedContenido.id).update(contenidoData))
    .then((res)=>{
      console.log("Video contenido updateado " + res)
      setOkResponse("Contenido actualizado ");
      setURLContenido(contenidoData.URLContenido);
      //setAbrirSnackBar(true);
      //setbotonesEnabled(true);
      //setloadingFbQuery(false);
      handleCloseEditarModal(false);
    }).catch(e=>{
      console.error(e);
      setnError(e.Error);
    });
  
  } else if( contenidoData.nombre  ){

    console.log("solo actualizare nombre");
    await db.collection('contenidos').doc(selectedContenido.id).update(contenidoData)
  .then((res)=>{
    console.log("Video contenido updateado " + res)
    setOkResponse("Nombre actualizado ");
    //setAbrirSnackBar(true);
    //setbotonesEnabled(true);
    //setloadingFbQuery(false);
    handleCloseEditarModal(false);
      
    }).catch(e=>{
      console.error(e);
      setnError(e.Error);
    });
  } else if ( contenidoData.URLcontenido !== null){
  console.log("actualizare video por lo tanto preparare el video");
    setCerrarClickBack(true);
    let data = new FormData();
    let jeders = {headers: {'Content-Type':'multipart/form-data'}};
    let dato = URLContenido;
    data.append("archivo",dato[0]);
    axios.post('https://suzukiv2.sangsang.mx/ArchivosVideoImagen/index.php',data,optionsUploader,jeders).
      then(res =>{
        console.log(res);
        //Handling a error on update
          if( res.data === "hubo un error en el lado del Servidor"){
          setnError("Video NO subido, " + res.data);
          setloadingFbQuery(false);
          setbotonesEnabled(true);
        }
        //eséramos poquito para que se vea el 100 % dibujado
      setUploadPorcentaje(100,setTimeout(() => {
        if (res.data === "El archivo " + dato[0].path +" ha sido subido."){
          setOkResponse("El archivo " + dato[0].path +" ha sido subido.")
        }
        setloadingFbQuery(false);
        setbotonesEnabled(true);
        setUploadPorcentaje(0);
      }, 1000));
      
      //Pasamos a hacer el update en la db
      
    }).then(db.collection('contenidos').doc(selectedContenido.id).update(contenidoData))
    .then((res)=>{
      console.log("Video contenido updateado " + res)
      setOkResponse("Contenido actualizado ");
      setURLContenido(contenidoData.URLContenido);
      //setAbrirSnackBar(true);
      //setbotonesEnabled(true);
      //setloadingFbQuery(false);
      handleCloseEditarModal(false);
    }).catch(e=>{
      console.error(e);
      setnError(e.Error);
    });
  
  }
}

console.log("modal contenidos")

return(<>

        <>{/*MODAL ELIMINAR Contenido */}
          <Dialog open={openModalEliminarContenido} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-eliminar-contenido"
             onClose={handleCloseEliminarContenido} disableBackdropClick = {cerrarclickBack} >
                 <DialogTitle className={classes.tituloModalEliminar} id="alert-dialog-title-EliminarContenido">{"¿Desea eliminar la Contenido " + selectedContenido.nombre + "?"}</DialogTitle>
                 <DialogContent>
                    <DialogContentText className={classes.textoModalEliminar} id="alert-dialog-eliminar-contenido">
                      Si se borra la Contenido NO podra ser recuperada y todos los datos de analiticos seran tambien eliminados.
                    </DialogContentText>
                 </DialogContent>
                 <DialogActions>
                     <Button variant="contained" color="primary" onClick={handleCloseEliminarContenido}>
                         Cancelar
          </Button>
          <Button className={classes.buttonModal} variant="contained" color="secondary" onClick={() => eliminarContenido ()} autoFocus>
                         Borrar
          </Button>
                 </DialogActions>
             </Dialog></>
        <>{/*MODAL EDITAR SUCUSAL */}
        <form className={classes.root} noValidate autoComplete="off">
        <Dialog maxWidth= 'sm' fullWidth open={openModalEditar} disableBackdropClick = {cerrarclickBack} 
        onClose={handleCloseEditarModal} aria-labelledby="form-dialog-Editar">
        <DialogTitle id="formEditarContenidoTitulo" style={{display:'flex',justifyContent:'space-between'}}>
        {"Editar Video contenido "} 
        <Typography  display='inline' variant ='h6' color="primary" >{ selectedContenido.nombre}</Typography>
        </DialogTitle>
        <Divider/>
             <DialogContent >
             <DialogContentText className={classes.root} pt='1'>
               Actualiza el video contenido 
            </DialogContentText>
            <Grid  container spacing = {2}direction="row" justify="space-around" alignItems="center">
        <Grid item xs={5}>
     <RootRef rootRef={ref}>
      <Paper {...rootProps}>  <>
        <input {...getInputProps()} />
           { localURLcontenido ? <p>{localURLcontenido}</p> : <p>Arrastra o da click para agregar el nuevo video</p>}
           { uploadPorcentaje === 0 ? <></> :<>
          <Box alignItems="flex-start">
           <Box   width="100%" mr={1} p={0}>
            <LinearProgress  variant="determinate"  value={uploadPorcentaje}/>
          </Box>
           <Box minWidth={35}>           
             <Typography variant="body2" color="textSecondary">{updloadProgressText}</Typography>
            </Box>
            </Box> </>}
            </>
      </Paper>
    </RootRef>
    </Grid>
       
        
        <Grid item xs={6}>
        <Typography  variant="subtitle1" align="left" color="primary" children={selectedContenido.nombre}/>
         <TextField
            autoFocus
            margin="dense"
            id="nameAddContenido"
            label="cambiar nombre"
            value={nombre}
            onChange={e=>{ setNombre(e.target.value);}}
            type="text"
            variant="outlined"
          /> 
           </Grid></Grid>
        </DialogContent>
        <Divider/>
        {  nError && ( <Typography  variant="subtitle1" align="center" color="error" children={nError}/> ) }
        {  nOkResponse && ( <Typography  variant="subtitle1" align="center" color="primary" children={nOkResponse}/> ) }
        { loadingFbQuery && ( <Progress/>) }
        <DialogActions>
          <Button className={classes.buttonModal} variant='contained' onClick={handleCloseEditarModal} color="secondary" disabled ={!botonesEnabled}>
            Cancelar
          </Button>
          <Button className={classes.buttonModal} variant = 'contained' onClick={updateContenido} color="primary" disabled ={!botonesEnabled}>
            Actualizar
          </Button>
        </DialogActions>
      </Dialog>
      </form></>
      <>{/*MODAL AÑADIR Contenido */}
        <form id="formContenido" className={classes.root} noValidate autoComplete="off">
        <Dialog maxWidth= 'sm' fullWidth open={openModalAddContenido} disableBackdropClick = {cerrarclickBack} 
        onClose={handleCloseAddContenidoModal} aria-labelledby="form-dialog-addContenido">
        <DialogTitle id="formAddContenidoTitulo">Añade Video contenido</DialogTitle>
        <Divider/>
             <DialogContent >
             <DialogContentText className={classes.root} pt='1'>
               Añade un Video
            </DialogContentText>
            <Grid  container spacing = {2} direction="row" justify="space-around" alignItems="center">
        <Grid item xs={5}>

     <RootRef rootRef={ref}>
      <Paper {...rootProps}>  <>
        <input {...getInputProps()} />
      
           { localURLcontenido ? <p>{localURLcontenido}</p> :  <p>Arrastra o da click para añadir un video .mp4</p>}
           { uploadPorcentaje === 0 ? <></> :<>
          <Box alignItems="flex-start">
           <Box   width="100%" mr={1} p={0}>
            <LinearProgress  variant="determinate"  value={uploadPorcentaje}/>
          </Box>
           <Box minWidth={35}>           
             <Typography variant="body2" color="textSecondary">{updloadProgressText}</Typography>
            </Box>
            </Box> </>}
            </>
      </Paper>
    </RootRef>
    </Grid>  
        <Grid item xs={6}>
         <TextField
            autoFocus
            margin="dense"
            id="nameAddContenido"
            label="Nombre de Contenido"
            value={nombre}
            onChange={e=>{ setNombre(e.target.value);}}
            type="text"
            variant="outlined"
          /> 
           </Grid></Grid>
        </DialogContent>
        {  nError && ( <Typography  variant="subtitle1" align="center" color="error" children={nError}/> ) }
        {  nOkResponse && ( <Typography  variant="subtitle1" align="center" color="primary" children={nOkResponse}/> ) }
        { loadingFbQuery && ( <Progress/>) }
        <Divider/>
       
        <DialogActions>
          <Button className={classes.buttonModal} variant='contained' onClick={handleCloseAddContenidoModal} color="secondary" disabled ={!botonesEnabled}>
            Cancelar
          </Button>
          <Button  className={classes.buttonModal} variant = 'contained' onClick={crearContenido} color="primary" disabled ={!botonesEnabled}>
            Añadir Contenido
          </Button>
        </DialogActions>
      </Dialog>
      </form></>
      <>{/*MODAL VIZUALIZADOR DE VIDEO */}
        <Dialog open={openVideoModal} fullWidth maxWidth='md' disableBackdropClick = {cerrarclickBack}  aria-labelledby="form-dialog-video">
            <DialogTitle id="form-dialog-title" style={{display:'flex',justifyContent:'space-between'}} >
            {"Video contenido "}
              <Typography  display='inline' variant ='h6' color="primary" >{selectedContenido.nombre}</Typography>
              <IconButton className={classes.closeButton} variant='contained' onClick={handleClickCloseVideoModal} color="secondary">
                  <CancelIcon fontSize='large'/>
              </IconButton> 
              </DialogTitle>
            <Divider/>
            <DialogContent scroll='paper' >
            <Grid  container spacing={2} direction="row" justify="center" alignItems="flex-start">
               <video height={1080*0.6} controls src={selectedContenido.URLContenido} autoPlay/>
            </Grid>
            </DialogContent>
         </Dialog>   
        </>
    </>)
}


return (
  <ModalContenidosContext.Provider value={{handleClickOpenEliminarContenido, handleClickOpenEditarContenidoModal,
                                      handleClickOpenAddContenidoModal,handleClickOpenVideoModal, ModalContenidos,AlertaSnackBar}}> 
      {props.children}
  </ModalContenidosContext.Provider>
)          
}

export default ModalContenidosProvider;


import {db,storage,firebase} from '../backEnd/firebase'


//ESTADO o constantes 
const dataInicialDuckSucursal = {
    sucursal:[], 
    loading:false

}


// TYPES
const UPDATE_SUCURSAL_EXITO = "UPDATE_SUCURSAL_EXITO";
const LOADING ="LOADING"
const LOAD_SUCURSAL = "LOAD_SUCURSAL_SUCURSAL"

//REDUCER (reduce a una constante los datos)
export default function sucursalesReducer(state = dataInicialDuckSucursal, action){
    

    switch(action.type){

            case LOAD_SUCURSAL :
              return{...state,sucursal:action.payload};

            case LOADING:
              return{...state,loading:true }
            
            case  UPDATE_SUCURSAL_EXITO:
            return {...state, sucursal: action.payload, loading:false};

            default:
              return state;

    }
}

export const loadSucursalAction = (sucursalX) => async (dispatch,getState)=>{
  console.log("sucursal en var" + JSON.stringify(sucursalX) + "Sucurs id" + sucursalX.id)

  try {
    db.collection('sucursales').doc(sucursalX.id).onSnapshot(dataSucursal => {
     console.log("Fetch redux datasucursal.docs" + JSON.stringify(dataSucursal.data()));
     
     dispatch({
      type: LOAD_SUCURSAL,
      payload: dataSucursal.data()
    })


     console.log("Sucursal despuyes de lodeada por uid desde el Redux" + getState().sucursal)
     
 })
}
catch (error) {
 console.log("err3")
 console.log("error en fetching docymentos" + error);
}


}


//actions (aqui se cargan los datos) funcion de flecha que devuelve otra funcion de flecha
export const updateSucursalAction = (sucursal,formularioData) => async (dispatch,getState) =>{

  console.log(getState().sucursal.sucursal);

    dispatch({
        type: LOADING
    })
    //Llamamos variables de estado
    const {sucursalX} = getState().sucursal.sucursal;
    console.log(sucursalX);
    console.log("updateando desde el layer de Ducks Sucursal");

    if(formularioData.urlImagen){
      console.log("si hay data de archivo image")
      let storageRef = firebase.storage().ref();
      let sucursalImagesRef = storageRef.child('imgSucursales/'+sucursal.id)
      await sucursalImagesRef.put(formularioData.urlImagen[0])
      let fileUrl = await sucursalImagesRef.getDownloadURL();
      formularioData = {...formularioData,urlImagen: fileUrl}
    
    }

    try{
      console.log(sucursalX + " ANTES de la conexion a fb")
      await db.collection('sucursales').doc(sucursal.id).update(formularioData);
      //Actualizamos local
      console.log(sucursalX + " despues de la conexion a fb")
      const sucursalY = {... getState().sucursal.sucursal,formularioData}
      
      dispatch({
        type: UPDATE_SUCURSAL_EXITO,
        payload: sucursalY
      })

    }catch(error){
      console.log(error)
    }
}

//actions (aqui se cargan los datos) funcion de flecha que devuelve otra funcion de flecha
export const deleteSucursalAction = (sucursal) => async (dispatch,getState) =>{

    var batch = db.batch();
    let deleteRef1 =  db.collection('sucursales').doc(sucursal.id);
    batch.delete(deleteRef1);
    let deleteRef2;
    await db.collection('usuarios').where("sucursal.id","==",sucursal.id).get().
    then(function (querySnapshot){
  
    //console.log("en el query de borrar");
      querySnapshot.forEach((doc)=>{
        console.log("sucursal... " + doc.id+ "=>"+ JSON.stringify(doc.data()));
        //Update Ref en el usuario
        deleteRef2 = db.collection('usuarios').doc(doc.data().email);
        batch.update(deleteRef2,{"sucursal": ''});
      })
      
      batch.commit().then(function(){
        //handleCloseEliminarSucursal();
      });
  
    }).catch(e=>{console.log(e + " error :" + e.error)})




}

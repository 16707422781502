import React, {useState,useEffect,useMemo} from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ConectadoWifi from  '@material-ui/icons/Wifi';
import DesconectadoWifi from '@material-ui/icons/WifiOff';
import ListItem  from '@material-ui/core/ListItem';
import {makeStyles} from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import Button from '@material-ui/core/Button';
import AddSucursalIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { useDropzone } from 'react-dropzone';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import RootRef from '@material-ui/core/RootRef';
import UpdateIcon from '@material-ui/icons/Sync';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/CancelRounded';
import TextField from '@material-ui/core/TextField';

import { db } from '../backEnd/firebase';
import {UsuarioContext} from '../contexts/usuarioProvider';
import { useDispatch, useSelector } from 'react-redux';
import { updateSucursalAction,loadSucursalAction } from '../redux/sucursalDucks';

const estilos = makeStyles(theme =>( {
    root:{
        marginTop: theme.spacing(2),
    },
    paper:{
        margin:'20px',
        padding:'20px',
      
    },
    button:{
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(6)
    },
    buttonModal:{
      margin: theme.spacing(1),
    },
    textoIcono:{
        verticalAlign : 'super',
       
    },
    tituloModalEliminar:{
        color: theme.palette.secondary.light
    },
    textoModalEliminar:{
        color: theme.palette.secondary.dark
    }, 
    dividerHorizontal:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
     },
    icono:{
        verticalAlign : 'middle',
        display:'inline-flex',
        backgroundColor: 'yellow',
        color: '#fff',
        background: 'yellow',
        margin: '400'
    }, dividerVertical:{
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2)
      },
    divisor:{
        marginBottom: theme.spacing(3)
    },divStyle:{
        width:'100%',
        background: `white`,
        border: `1px #ccc`,
    
        padding: 10
    },
    fotoSucursal:{
        marginTop: '3%', marginBottom: '3%',
        verticalAlign:'middle', marginBottom:20, 
        borderStyle: 'groove', borderColor:'red'
    }
}));

const options = {
    filterType: 'checkbox',
    filter: true,
    rowsPerPageOptions:[3,10,100],
    rowsPerPage :3,
    selectableRowsHeader:false,
    selectableRows:'none'
  };

const columns = ["Sucursal", "Ciudad","Lista de reproducción", "Estatus", "Ultima conexión","Acciones"];
       
const handleClickOpen =()=> {
    return(
    <> hola</>)
}
const handleClickOpenEditarModal =()=> {
    return(
        <> hola</>)
}
const handleClickOpenAddSucursalModal=()=> {
    return(
        <> hola</>)
}

//variables de estados de iconos
const encendido =  <ListItem >
                        <ListItemIcon><ConectadoWifi fontSize='large' color='primary'/></ListItemIcon>   
                        <Typography  color='primary' variant="subtitle2" children='Conectado'/>   
                    </ListItem>;              
const apagado = <ListItem>
                    <ListItemIcon><DesconectadoWifi fontSize='large'  color='secondary'/></ListItemIcon>
                    <Typography  color='secondary' variant="subtitle2" children='Desconectado'/>     
                </ListItem>;
const iconosDeRowElement = <ListItem >
                <ListItemIcon  onClick={handleClickOpenEditarModal} >
                    <IconButton color='inherit' ><EditIcon fontSize='large' style = {{color:'#3377AA'}}/></IconButton>
                </ListItemIcon>
                <ListItemIcon  onClick={handleClickOpen}>
                    <IconButton color='secondary' ><DeleteIcon fontSize='large' color='secondary'/></IconButton>  
                </ListItemIcon>                   
                </ListItem>
const data = [
    ["Pedregal","[CMX]" ,"Lista de Agosto", encendido, "11/02/2020, 1:13:30 PM",iconosDeRowElement],
    ["Aeropuerto","[CMX]" ,"Lista de Agosto", encendido, "11/03/2020, 07:10:30 PM",iconosDeRowElement],
    ["Interlomas","[MEX]","Lista de Agosto", apagado, "23/05/2020, 07:10:30 PM",iconosDeRowElement],
    ["La Villa","[CMX]","Lista de Agosto", encendido, "27/05/2020, 07:10:30 PM",iconosDeRowElement],
    ["Coacalco","[MEX]" , "Lista de Agosto", apagado, "06/06/2020, 07:10:30 PM",iconosDeRowElement],
    [" Vallejo","[CMX]", "Lista de Agosto", encendido, "11/06/2020, 07:10:30 PM",iconosDeRowElement],
    ["Polanco","[CMX]","Lista de Agosto", apagado, "12/06/2020, 07:10:30 PM",iconosDeRowElement],
    ["Tlalpan", "[CMX] ","Lista de Agosto", encendido, "02/04/2020, 07:10:30 PM",iconosDeRowElement],
    ["Universidad","[CMX]","Lista de Agosto", apagado, "29/05/2020, 07:10:30 PM",iconosDeRowElement],
   ];        

function Sucursal(props) {

      //Inicializamos el context del usuario
  const {usuario} = React.useContext(UsuarioContext);

    //Notacion de Redux en documentacion
    const dispatch = useDispatch()
    const sucursalR = useSelector(store => store.sucursal.sucursal);
    const updatingSucursalData = useSelector(store => store.sucursal.loading)

    //Cargamos los estilos de la sucursal
    const classes = estilos();
    const [sucursal,setSucursal] = useState(usuario.rol ==='admin' ? props.sucursal : usuario.sucursal);
   
    const [isEditMode, setIsEditMode] = useState(false);
    const handleEditSucursalMode = (isOn) => { setIsEditMode(isOn); };

    const [nError, setnError] = useState(null);
    const [URLContenido, setURLContenido] = React.useState('');


    //const [updatingSucursalData, setUpdatingSucursalData] = useState(false);
    const [nErrorEditSucursal, setnErrorEditSucursal] = useState(null);

    //Formulario
    const [direccion,setDireccion] = useState('');
    const [posicion,setPosicion] = useState({latitude:'',longitude:''});
    const [encargado,setEncargado] = useState('');
    const [telefono,setTelefono] = useState('');
    const [mailContacto,setMailContacto] = useState('');

    
 
   
     //Contenido nuevo
     const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        marginBottom: '6px',
        borderWidth: 4,
        borderRadius: 4,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        wordWrap: 'break-word'
    };
    const activeStyle = {
        borderColor: '#2196f3'
    };
    const acceptStyle = {
        borderColor: '#00e676'
    };
    const rejectStyle = {
        borderColor: '#ff1744'
    };

    //Configuracion del drop zone 
    const fileMaxSize = 100000000;//100MB 
    const [loadingFbQuery, setloadingFbQuery] = useState(false);
    const [localURLcontenido, setlocalURLcontenido] = useState('');
    const [uploadPorcentaje, setUploadPorcentaje] = useState(0);
    const [updloadProgressText, setUploadProgressText] = useState('');

    const onDropeoRechazado = (res) => {
        console.log(res[0]);
        console.log(JSON.stringify(res[0]));
        if (res[0].errors[0].code == "file-too-large") {
            setnError("El archivo es demasiado largo, debe ser menor de " + fileMaxSize / 100000000 + " MB");
        }
        else if (res[0].errors[0].code == "file-invalid-type") {
            setnError("Introduce un archivo valido");
        }
        else {
            setnError(res[0].errors[0].code);
        }
    }

    const onDropeoAceptado = res => {
        console.log(JSON.stringify(res));
        console.log("archivo aceptado");
        setnError("");
        //Hay que setear la url del archivo y quiza un preview o un algo que indicque que se tiene listo para subir
        setlocalURLcontenido(res[0].path);
        setURLContenido(res);
    }

    //Hook del drop zone
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        maxSize: fileMaxSize, multiple: false,
        onDropRejected: onDropeoRechazado, onDropAccepted: onDropeoAceptado,accept:'image/jpeg, image/png, image/jpg'
    });

    //Estilo del dropZone
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);
    const { ref, ...rootProps } = getRootProps({ style })
    //Upload Handler
    const optionsUploader = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor(loaded * 100 / total)
            setUploadProgressText(`${(loaded / 1000000).toFixed(2)}Mb de ${(total / 1000000).toFixed(2)}Mb | ${percent}%`)
            console.log(`${loaded.toFixed(2)}kbof ${total.toFixed(2)}kb | ${percent}%`)

            if (percent < 100) {
                setUploadPorcentaje(percent);
            }
        }
    }

    //Carga de data de la sucursal usando redux
    const loadSucursalRedux = () =>{
    dispatch(loadSucursalAction(sucursal));
   }

    useEffect((props) => {
        console.log("idu sucursal " + props)
        console.log("variable sucursa" + JSON.stringify(sucursal))
        console.log(sucursal.nombre)
        console.log("useEffecrt usuario" + usuario)
        //loadSucursal(sucursal.id);
        loadSucursalRedux();
       // setSucursal(sucursalR);
    }, [])
  
     

    useMemo(() => {
        // componentWillMount events
        console.log(JSON.stringify(usuario) +"ssssss")
       // loadSucursal(sucursal.id);
      },[]);
  

    const procesarUpdateSucursalData = () =>{
        //calling redux function
        console.log("vamos a llamar por Redux")
        let contenidoData = {};

        if(direccion.trim()){
            contenidoData= {...contenidoData,direccion : direccion }
          }
        if(posicion.latitude.trim() && posicion.longitude.trim()){
            contenidoData= {...contenidoData,posicion : {latitude: parseFloat(posicion.latitude),longitude: parseFloat(posicion.longitude)}
             }
        }
        if(encargado.trim()){
            contenidoData= {...contenidoData,encargado : encargado }
          }
        if(telefono.trim()){
            contenidoData= {...contenidoData,telefono : telefono }
          }
        if(mailContacto.trim()){
            contenidoData= {...contenidoData,mailContacto : mailContacto }
          }
        if(localURLcontenido.trim()){
            contenidoData = {...contenidoData,urlImagen: URLContenido}
        }
        
        dispatch(updateSucursalAction(sucursal,contenidoData));       
    }
    
    return (
          <div className={classes.root}>
                <Paper elevation={3} className={classes.paper} >
            <Grid container  justify="flex-start" className={classes.root} spacing={3}> 
               <Grid item>
                    <Typography color='primary' variant="h4" children={sucursal.nombre} display='inline'/>
                </Grid>
                <Grid item>
                    <Divider orientation='vertical' className={classes.dividerVertical}/> 
                    </Grid>
                <Grid item>
                     <ListItemIcon><ConectadoWifi fontSize='large' color='primary'/></ListItemIcon> 
                     <Typography  color='primary' variant="subtitle1" children='Conectado' display = 'inline' className={classes.textoIcono}/>   
                </Grid>     
                      
            </Grid>
                <Divider className={classes.dividerHorizontal}/>
                <Grid container  justify="flex-start" spacing={2} >
                { isEditMode ? <> 
                    <Grid item  xs={12} sm={12} md={12} lg={12} xl={3}>
                 
                    <RootRef rootRef={ref}>
                            <Paper {...rootProps}>  <>
                                <input {...getInputProps()} />
                                <img alt="imagenSucursal" width = "300px" height="auto" className={classes.fotoSucursal} 
                                 src={sucursalR.urlImagen}/>
                                {localURLcontenido ? <p>{localURLcontenido}</p> : <p>Arrastra o da click para añadir un archivo .jpg, .png, .jpeg</p>}
                                {uploadPorcentaje === 0 ? <></> : <>
                                    <Box alignItems="flex-start">
                                        <Box width="100%" mr={1} p={0}>
                                            <LinearProgress variant="determinate" value={uploadPorcentaje} />
                                        </Box>
                                        <Box minWidth={35}>
                                            <Typography variant="body2" color="textSecondary">{updloadProgressText}</Typography>
                                        </Box>
                                    </Box> </>}
                            </>
                            </Paper>
                        </RootRef>
                        <Box display="flex" alignItems="center" p={1} m={0}>
                          <Typography variant="subtitle1" align="center" color="error" children={nError} />
                        </Box>
                        </Grid>
                    <Grid item  xs={12} sm={12}md={3} lg={3} xl={3}>
                    <Typography variant ='h6' color="primary"  display = 'block' children="Dirección:"/>
                    <Typography variant ='body1' color="textSecondary"  display = 'block'  children={sucursalR.direccion}/>
                    <TextField margin="dense" id="direccion" label="Dirección" onChange={e => setDireccion(e.target.value)}
                      fullWidth={false} size='normal' type="text" variant="outlined" value={direccion} />

                    {usuario.rol ==='admin'? <>
                    <Typography variant ='h6' color="primary"  display = 'block' children="Coordenadas:"/>
                    <Typography variant ='body1' color="textSecondary"  display = 'block'>lat: {sucursalR.posicion.latitude}, long: {sucursalR.posicion.longitude} </Typography>
                    <TextField  
                    inputProps={{
                        inputMode: 'numeric',pattern:'[0-9]*'
                    }}
                    margin="dense" id="posicionLat" label="Latitude" display = 'inline'
                        onChange={e => setPosicion({...posicion,latitude: e.target.value})}
                      fullWidth={false} size='normal' type="text" variant="outlined" value={posicion.latitude} />

                      <TextField 
                      inputProps={{
                        inputMode: 'numeric',pattern:'[0-9]*'
                    }}
                    margin="dense" id="posicionLong" label="Longitude"  display = 'inline'
                        onChange={e => setPosicion({...posicion,longitude:e.target.value})}
                      fullWidth={false} size='normal' type="text" variant="outlined" value={posicion.longitude} />
                    </>:<> </> }


                    </Grid>
                 <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Typography variant ='h6' color="primary" >Encargado: </Typography>
                    <Typography variant ='body1' color="textSecondary" children={sucursalR.encargado}/>
                    <TextField margin="dense" id="encargado" label="encargado" display = 'inline'
                    onChange={e => setEncargado(e.target.value)} fullWidth={false} size='normal' type="text" variant="outlined" value={encargado} />                
                    
                    <Typography variant ='h6' color="primary" >Telefono: </Typography>
                    <Typography variant ='body1' color="textSecondary" children={sucursalR.telefono}/>
                    <TextField margin="dense" id="encargado" label="Telefono" display = 'inline'
                    onChange={e => setTelefono(e.target.value)} fullWidth={false} size='normal' type="number" variant="outlined" value={telefono} />                
                
                    <Typography variant ='h6' color="primary" >Correo: </Typography>
                    <Typography variant ='body1' color="textSecondary"  children={sucursalR.mailContacto}/>  
                    <TextField margin="dense" id="encargado" label="Correo" display = 'inline'
                    onChange={e => setMailContacto(e.target.value)} fullWidth={false} size='normal' type="email" variant="outlined" value={mailContacto} />                
                
                    </Grid>   
                   
                    <Grid item xs={12} sm={12} md={2} lg={1}>
                    <Divider orientation='vertical' className={classes.dividerVertical}/> 
                    </Grid>
                    {usuario.rol ==='admin'? <>
                    <Grid item xs={12} sm={12} md={2} lg={1}>
                    <Box pl={2} display="flex">
                        {updatingSucursalData ?
                          <CircularProgress />
                          : <>
                            <IconButton pl={2} color="primary" size="medium" onClick={() => procesarUpdateSucursalData()}>
                                <UpdateIcon fontSize='large' />
                            </IconButton>
                            <IconButton color="secondary" size="medium" onClick={() => { setIsEditMode(false); setnErrorEditSucursal(null); }}>
                              <CancelIcon fontSize='large' />
                            </IconButton>
                          </>}  </Box>
                      {nErrorEditSucursal && (
                        <Box display="flex" alignItems="center" p={1} m={0}>
                          <Typography variant="subtitle1" align="center" color="error" children={nErrorEditSucursal} />
                        </Box>)}
                    </Grid>
                        </>
                    :<> </> }

                    

                    </>:<>
                    <Grid item  xs={12} sm={12} md={3} lg={3} xl={3}>
                    <img alt="imagenSucursal" width = "300px" height="auto" className={classes.fotoSucursal} 
                    src={sucursalR.urlImagen}/>
                        </Grid>
                    <Grid item  xs={12} sm={12}md={3} lg={3} xl={3}>
                    <Typography variant ='h6' color="primary"  display = 'block' children="Dirección:"/>
                    <Typography variant ='h6' color="textSecondary"  display = 'block' paragraph>
                        {sucursalR.direccion} 
                    </Typography>
                    {usuario.rol ==='admin'? <>
                    <Typography variant ='h6' color="primary"  display = 'block' children="Coordenadas:"/>
                    <Typography variant ='h6' display = 'block' color="textSecondary" >
                        lat: {sucursal.posicion.latitude}, long: {sucursal.posicion.longitude}
                    </Typography>
                    </>:<> </>              
                     }
                    </Grid>

                 <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Typography variant ='h6' color="primary" >Encargado: </Typography>
                    <Typography variant ='h6' color="textSecondary" paragraph children={sucursalR.encargado}/>                
                    <Typography variant ='h6' color="primary" >Telefono: </Typography>
                    <Typography variant ='h6' color="textSecondary"  paragraph children={sucursalR.telefono}/>
                    <Typography variant ='h6' color="primary" >Correo: </Typography>
                    <Typography variant ='h6' color="textSecondary"  children={sucursalR.mailContacto}/>  
                    </Grid>   
                   
                    <Grid item xs={12} sm={12} md={1} lg={1}>
                    <Divider orientation='vertical' className={classes.dividerVertical}/> 
                    </Grid>
                    {usuario.rol ==='admin'? 
                    <Grid item xs={12} sm={12} md={1} lg={1}>
                    <ListItem >
                        <ListItemIcon  onClick={()=>handleEditSucursalMode(true)} >
                            <IconButton color='inherit' ><EditIcon fontSize='large' style = {{color:'#3377AA'}}/></IconButton>
                        </ListItemIcon>                
                    </ListItem>
                    </Grid>
                    :<> </> }
                    </>}

                    </Grid>
                    
                               
                </Paper>
              




                {usuario.rol ==='admin'? <>
                    <Grid item >
                    <Paper elevation={3} className={classes.paper} >
                    <Button onClick={handleClickOpenAddSucursalModal} 
                            variant="contained" size='large' color="primary" 
                            className = {classes.button}  
                            startIcon={<AddSucursalIcon/>}>
                                Añadir incidencia
                            </Button>
                         
                        <MUIDataTable
                            title={"Lista de incidencias"}
                            data={data}
                            columns={columns}
                            options={options}
                        /> </Paper>     
                 </Grid>   
                     
                <Grid item >

                <Paper elevation={3} className={classes.paper} >
                    <Button onClick={handleClickOpenAddSucursalModal} 
                            variant="contained" size='large' color="primary" 
                            className = {classes.button}  
                            startIcon={<AddSucursalIcon/>}>
                                Añadir reporte
                            </Button>
                         
                        <MUIDataTable
                            title={"Lista de reportes"}
                            data={data}
                            columns={columns}
                            options={options}
                        /> </Paper>
                      
                     </Grid> </>:<> </>    }
            
               
            </div>
        
          
       
    )
}

export default Sucursal;


import React,{useEffect,useState,useCallback,useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Divider  from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import AddSucursalIcon from '@material-ui/icons/AddCircle';
import Grid from '@material-ui/core/Grid';
import MUIDataTable from 'mui-datatables';
import ConectadoWifi from  '@material-ui/icons/Wifi';
import DesconectadoWifi from '@material-ui/icons/WifiOff';
import ListItemIcon  from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListItem  from '@material-ui/core/ListItem';
import Description from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import {Route,Routes,Link} from 'react-router-dom';
import Sucursal from '../Componentes/sucursal';
import {db} from '../backEnd/firebase';
import {ModalSucursalesContext} from '../contexts/modalSucursalesProvider';



const estilos = makeStyles(theme =>( { 
    button:{
        margin: theme.spacing(3),
        marginRight: theme.spacing(6)
    },
    buttonModal:{
      margin: theme.spacing(1),
    },
    textoIcono:{
        verticalAlign : 'middle',
        display:'inline-flex',
        margin: 'inherit',
        color:'blue'
    },
    tituloModalEliminar:{
        color: theme.palette.secondary.light
    },
    textoModalEliminar:{
        color: theme.palette.secondary.dark
    },
    icono:{
        verticalAlign : 'middle',
        display:'inline-flex',
        backgroundColor: 'yellow',
        color: '#fff',
        background: 'yellow',
        margin: '400'
    }
}));

//variables de estados de iconos y tabla
const encendido =  <ListItem >
                        <ListItemIcon><ConectadoWifi fontSize='large' color='primary'/></ListItemIcon>   
                        <Typography  color='primary' variant="subtitle2" children='Conectado'/>   
                    </ListItem>;              
const apagado = <ListItem>
                    <ListItemIcon><DesconectadoWifi fontSize='large'  color='secondary'/></ListItemIcon>
                    <Typography  color='secondary' variant="subtitle2" children='Desconectado'/>     
                </ListItem>;
 const columns = ["Sucursal", "Ciudad","Lista de reproducción", "Estatus", "Ultima conexión","Acciones","Ver sucursal"];
 const options = {
    filterType: 'checkbox',
    filter: true,
    rowsPerPageOptions:[5,10,100],
    rowsPerPage :10,
    selectableRowsHeader:false,
    selectableRows:'none',
    textLabels: {
      body: {
        noMatch: "lo sentimos, busqueda no encontrada",
        toolTip: "Sort",
        columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: "Pagina siguiente",
        previous: "Pagina anterior",
        rowsPerPage: "Sucursales por pagina:",
        displayRows: "de",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    }
  
  };

function Sucursales()  {
  
  //cargamos los estilos
  const classes = estilos();
    
  //context del modal de Sucursales
  const {handleClickOpenEliminarSucursal,handleClickOpenEditarModal,
        handleClickOpenAddSucursalModal,ModalSucursales} = useContext(ModalSucursalesContext);

//variables de estado de sucursal y sucursales
 const [sucursales,setSucursales]= useState([]);

 //variable de Link de Sucursal y sucursal individual
 const [sucursalX,setSucursalX] = useState({});

 //Read de las Sucursales
 const obtenerSucursales = async()=>{
    try{ 
     await db.collection('sucursales').onSnapshot(dataSucursales =>{
      console.log(dataSucursales.docs);

      //obtenemos el ultimo sucursal obtenido
      //var lastVisible =  dataSucursales.docs[dataSucursales.docs.length-1];
     // console.log("last",lastVisible);

      //construimos la nueva query
      //var next = db.collection("sucursales").limit(20);

     //convertimos el objeto de fb a un array para el estado
     const arraySucursales = dataSucursales.docs.map(doc =>({id:doc.id,... doc.data()}));
    // console.log(arraySucursales);
     setSucursales(arraySucursales);


     
      }).catch(err=>{
        console.log(err);
      });
    }
    catch (error){
      console.log(error);
    }
  }
  useEffect(()=>{
    //llamamos la funcion del efecto para obtener las sucursales
    obtenerSucursales();
     },[]); 
    

    const MenuyTablas  = () => {
      
         return(<>
            <Grid container direction="row" justify="flex-end" alignItems="center">    
                            <Button onClick={handleClickOpenAddSucursalModal} 
                            variant="contained" size='large' color="primary" 
                            className = {classes.button}  
                            startIcon={<AddSucursalIcon/>}>
                                Añadir Sucursal
                            </Button>
                        </Grid>   
                        <MUIDataTable
                            title={"Lista de sucursales"}
                            data={data}
                            columns={columns}
                            options={options}
                        />

         </>);}
  

  const iconosDeRowElement = (sucursal) =>{ 
   // console.log(sucursal);

    return(
    <ListItem >
          <ListItemIcon  onClick={()=> {handleClickOpenEditarModal(sucursal);}}>
            <IconButton color='inherit' ><EditIcon fontSize='large' style = {{color:'#3377AA'}}/></IconButton>
        </ListItemIcon>
        <ListItemIcon  onClick={()=> {handleClickOpenEliminarSucursal(sucursal);}}>
            <IconButton color='secondary' ><DeleteIcon fontSize='large' color='secondary'/></IconButton>  
        </ListItemIcon>                   
    </ListItem>)
    }

const detalles= (sucursal) => { 

              return(
                    <Link to ={`${sucursal.nombre}`} onClick={()=>setSucursalX(sucursal)}>
                        <ListItem>
                          <ListItemIcon><IconButton color='primary'><Description  fontSize='large'  /></IconButton>
                          </ListItemIcon> 
                        </ListItem>
                        </Link>  )
};

//Data de la tabla
const data = sucursales.map(sucursales => (
  [sucursales.nombre,sucursales.ciudad,sucursales.lista,sucursales.isEncendido ? encendido : apagado,
  sucursales.fecha,iconosDeRowElement(sucursales), detalles(sucursales)]
));

    return (
 
        <Container maxWidth='xl'>        
            <Typography color='textSecondary' variant="h5">Sucursales</Typography>         
            <Divider className={classes.divisor}/>
            <Routes>
                <Route path='/' element={<MenuyTablas/>} />
                <Route path={encodeURIComponent(sucursalX.nombre)} element={<Sucursal sucursal={sucursalX}/>} />   
            </Routes>
            <ModalSucursales/>
        </Container>
     
    );
}

export default Sucursales;

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Divider  from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import AddSucursalIcon from '@material-ui/icons/AddCircle';
import Grid from '@material-ui/core/Grid';
import MUIDataTable from 'mui-datatables';
import ListItemIcon  from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListItem  from '@material-ui/core/ListItem';
import Description from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import {Route,Routes,Link} from 'react-router-dom';
import Sucursal from '../Componentes/sucursal';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';


const estilos = makeStyles(theme =>( {

    
    button:{
        margin: theme.spacing(3),
        marginRight: theme.spacing(6)
    },
    textoIcono:{
        verticalAlign : 'middle',
        display:'inline-flex',
        margin: 'inherit',
        color:'blue'
    },
    tituloModalEliminar:{
        color: theme.palette.secondary.light
    },
    textoModalEliminar:{
        color: theme.palette.secondary.dark
    },
    icono:{
        verticalAlign : 'middle',
        display:'inline-flex',
        backgroundColor: 'yellow',
        color: '#fff',
        background: 'yellow',
        margin: '400'
    }
}));

//variables de estados de iconos
const encendido =  <ListItem >
                        <ListItemIcon><AssignmentTurnedInIcon fontSize='large' color='Primary'/></ListItemIcon>   
                        <Typography  color='Primary' variant="subtitle2" children='Realizado'/>   
                    </ListItem>;              
const apagado = <ListItem>
                    <ListItemIcon><AssignmentLateIcon fontSize='large'  color='Secondary'/></ListItemIcon>
                    <Typography  color='Secondary' variant="subtitle2" children='Por revisar'/>     
                </ListItem>;


 const detalles = <Link to ="sucursalX"><ListItem><ListItemIcon ><IconButton color='primary'><Description  fontSize='large'  /></IconButton></ListItemIcon> </ListItem></Link>  ;             
 const columns = ["Sucursal", "Ciudad", "Estatus","Acciones","Ver reporte"];
 const options = {
    filterType: 'checkbox',
    filter: true,
    rowsPerPageOptions:[5,10,100],
    selectableRowsHeader:false,
    selectableRows:'none'
  };
 

function Reportes() {
       //Variables de Modal de eliminar Sucursal
       const [open, setOpen] = React.useState(false);
       const handleClickOpen = () => {setOpen(true);};
       const handleClose = () => {setOpen(false);};
   
       //Variables de Modal Editar Sucursal
       const [openModalEditar, setOpenModalEditar] = React.useState(false);
       const handleClickOpenEditarModal = () => {setOpenModalEditar(true);};
       const handleCloseEditarModal = () => {setOpenModalEditar(false);};
       const [estado, setEstado] = React.useState('[CMX]');
       const handleChangeCiudad = (event) => { setEstado(event.target.value)};
       const estados = [{value :'[CMX]',label:'[CMX]'},{value :'[SLP]',label:'[SLP]'},{value :'[MEX]',label:'[MEX]'},{value :'[GUA]',label:'[GUA]'}];
   
       //Variables de Modal Añadir Sucursal
       const [openModalAddSucursal, setOpenModalAddSucursal] = React.useState(false);
       const handleClickOpenAddSucursalModal = () => {setOpenModalAddSucursal(true);};
       const handleCloseAddSucursalModal = () => {setOpenModalAddSucursal(false);};
       
       //console.log("Entre a huevo");
   
       const MenuyTablas  = () => {
           const classes = estilos();
            return(<>
               <Grid container direction="row" justify="flex-end" alignItems="center">    
                               <Button onClick={handleClickOpenAddSucursalModal} variant="contained" size='large' color="primary" className = {classes.button}  startIcon={<AddSucursalIcon/>}>
                                   Añadir reporte
                               </Button>
                           </Grid>   
                           <MUIDataTable
                               title="Reportes"
                               data={data}
                               columns={columns}
                               options={options}
                           />
   
           <>{/*MODAL ELIMINAR SUCURSAL */}
                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle className={classes.tituloModalEliminar} id="alert-dialog-title">{"¿Desea eliminar la sucursal XVariable?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.textoModalEliminar} id="alert-dialog-description">
                            Si se borra la sucursal NO podra ser recuperada y todos los datos de analiticos seran tambien eliminados.
             </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={handleClose}>
                            Cancelar
             </Button>
                        <Button variant="contained" color="secondary" onClick={handleClose} autoFocus>
                            Borrar
             </Button>
                    </DialogActions>
                </Dialog></>
           <>{/*MODAL EDITAR SUCUSAL */}
           <form className={classes.root} noValidate autoComplete="off">
           <Dialog maxWidth= 'sm' fullWidth open={openModalEditar} onClose={handleCloseEditarModal} aria-labelledby="form-dialog-title">
           <DialogTitle id="form-dialog-title">Editar sucursalX</DialogTitle>
           <Divider/>
                <DialogContent >
                <DialogContentText className={classes.root} pt='1'>
                   Edita la sucursal
               </DialogContentText>
            <TextField
               autoFocus
               margin="dense"
               id="nameSucursal"
               label="Nombre de Sucursal"
               type="email"
               variant="outlined"
             /> 
           </DialogContent>
           <DialogContent >
               <DialogContentText>Elije la ciudad</DialogContentText>
             <TextField
             id="electorDeCiudad"
             select
             label="Ciudad"
             value={estado}
             onChange={handleChangeCiudad}
             helperText="Selecciona una ciudad"
             variant="outlined">
             {estados.map((option) => (
               <MenuItem key={option.value} value={option.value}>
                 {option.label}
               </MenuItem>
             ))}
           </TextField>
           </DialogContent> 
   
           <Divider/>
           <DialogContent>
             <DialogContentText>
              Elije la lista de reproducción
             </DialogContentText>
            <TextField
               margin="dense"
               id="name"
               label="Nombre de Sucursal"
               type="email"
             /> 
           </DialogContent>
           <DialogActions>
             <Button variant='contained' onClick={handleCloseEditarModal} color="secondary">
               Cancelar
             </Button>
             <Button variant = 'contained' onClick={handleCloseEditarModal} color="primary">
               Actualizar
             </Button>
           </DialogActions>
         </Dialog>
         </form></>
           <>{/*MODAL AÑADIR SUCURSAL */}
           <form id="formSucursal" className={classes.root} noValidate autoComplete="off">
           <Dialog maxWidth= 'sm' fullWidth open={openModalAddSucursal} onClose={handleCloseAddSucursalModal} aria-labelledby="form-dialog-title">
           <DialogTitle id="formAddSucursalTitulo">Añadir un reporte</DialogTitle>
           <Divider/>
                <DialogContent >
                <DialogContentText className={classes.root} pt='1'>
                  Añade un reporte
               </DialogContentText>
            <TextField
               autoFocus
               margin="dense"
               id="nameAddSucursal"
               label="Nombre de Sucursal"
               type="email"
               variant="outlined"
             /> 
           </DialogContent>
           <DialogContent >
               <DialogContentText>Elije la ciudad</DialogContentText>
             <TextField
             id="electorDeCiudadAdd"
             select
             label="Ciudad"
             value={estado}
             onChange={handleChangeCiudad}
             helperText="Selecciona una ciudad"
             variant="outlined">
             {estados.map((option) => (
               <MenuItem key={option.value} value={option.value}>
                 {option.label}
               </MenuItem>
             ))}
           </TextField>
           </DialogContent> 
   
           <Divider/>
           <DialogContent>
             <DialogContentText>
              Elije la lista de reproducción
             </DialogContentText>
            <TextField
               margin="dense"
               id="name"
               label="Nombre de Sucursal"
               type="email"
             /> 
           </DialogContent>
           <DialogActions>
             <Button variant='contained' onClick={handleCloseAddSucursalModal} color="secondary">
               Cancelar
             </Button>
             <Button variant = 'contained' onClick={handleCloseAddSucursalModal} color="primary">
               Añadir reporte
             </Button>
           </DialogActions>
         </Dialog>
         </form></>
       </>);}
   
   
   const iconosDeRowElement = <ListItem >
   <ListItemIcon  onClick={handleClickOpenEditarModal} >
       <IconButton color='inherit' ><EditIcon fontSize='large' style = {{color:'#3377AA'}}/></IconButton>
   </ListItemIcon>
   <ListItemIcon  onClick={handleClickOpen}>
       <IconButton color='Secondary' ><DeleteIcon fontSize='large' color='Secondary'/></IconButton>  
   </ListItemIcon>                   
   </ListItem>
        
        const data = [
           ["Pedregal","[CMX]" , encendido,iconosDeRowElement,detalles],
           ["Aeropuerto","[CMX]" , encendido,iconosDeRowElement,detalles],
           ["Interlomas","[MEX]", apagado, iconosDeRowElement,detalles],
           ["La Villa","[CMX]", encendido, iconosDeRowElement,detalles],
           ["Coacalco","[MEX]" , apagado,iconosDeRowElement,detalles],
           [" Vallejo","[CMX]", encendido, iconosDeRowElement,detalles],
           ["Polanco","[CMX]", apagado, iconosDeRowElement,detalles],
           ["Tlalpan", "[CMX]", encendido,iconosDeRowElement,detalles],
           ["Universidad","[CMX]", apagado, iconosDeRowElement,detalles],
          ];
   
       return (
           <Container maxWidth='xl'>        
               <Typography color='textSecondary' variant="h5">Reportes</Typography>         
               <Divider/>
               <Routes>
                   <Route path='/' element={<MenuyTablas/>} />
                   <Route path='sucursalX' element={<Sucursal/>} />   
               </Routes>
           </Container>
       );
}

export default Reportes;

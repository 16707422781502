import React, { useEffect, useState, useCallback, createContext } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import AddUsuarioIcon from '@material-ui/icons/AddCircle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/CancelRounded';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { db, functions, storage } from '../backEnd/firebase';
import Progress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import AccordionActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ToolTips from '@material-ui/core/Tooltip';
import UpdateIcon from '@material-ui/icons/Sync';


//Estilos de la tabla de usuarios
const estilos = makeStyles(theme => ({
  button: {
    margin: theme.spacing(3),
    marginRight: theme.spacing(6)
  },
  buttonModal: {
    margin: theme.spacing(1),
  },
  textoIcono: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    margin: 'inherit',
    color: 'blue'
  },
  tituloModalEliminar: {
    color: theme.palette.secondary.light
  },
  textoModalEliminar: {
    color: theme.palette.secondary.dark
  },
  icono: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    backgroundColor: 'yellow',
    color: '#fff',
    background: 'yellow',
    margin: '400'
  }, imgUser: {

    margin: theme.spacing(1),
    width: theme.spacing(9),
    height: theme.spacing(9),
  }, dividerVertical: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }, dividerHorizontal: {
    margintop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }, avatarSize: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(2)
  }, closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0),
  }, tituloModal: {
    flexGrow: 1
  }, textUpdate: {

    flexGrow: 1,
    verticalAlign: 'middle',
    display: 'inline-flex',
  }
}));

//context que sera usado en componentes
export const ModalAdminUsuariosContext = createContext();



function ModalAdminUsuariosProvider(props) {

  //llamamos el estilo a la funcion
  const classes = estilos();

  //Variables de Modal de eliminar Sucursal
  const [openEliminarUserModal, setOpenEliminarUserModal] = React.useState(false);
  const handleClickOpenEliminarUserModal = (user) => { setOpenEliminarUserModal(true); setSelectedUser(user); };
  const handleCloseEliminarUserModal = () => { setOpenEliminarUserModal(false); };

  //Variables de Modal Editar Sucursal
  const [openModalEditarUserModal, setOpenModalEditarUserModal] = React.useState(false);
  const handleClickOpenEditarUserModal = (user) => { setOpenModalEditarUserModal(true); setSelectedUser(user); };
  const handleCloseEditarUserModal = () => { setOpenModalEditarUserModal(false); };

  //Variables de Modal Añadir Sucursal
  const [openModalAddUser, setOpenModalAddUser] = React.useState(false);
  const handleClickOpenAddUserModal = () => { setOpenModalAddUser(true); };
  const handleCloseAddUserModal = () => { setOpenModalAddUser(false); };

  //Usuario seleccionado
  const [selectedUser, setSelectedUser] = useState({});
  //Sucursales variables de estado de sucursal y sucursales
  const [sucursales, setSucursales] = useState([]);

  //Read de las Sucursales
  const obtenerSucursales = async () => {
    try {
      await db.collection('sucursales').onSnapshot(dataSucursales => {
        console.log(dataSucursales.docs);
        //convertimos el objeto de fb a un array para el estado
        const arraySucursales = dataSucursales.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log(arraySucursales);
        setSucursales(arraySucursales);
      }).catch(err => {
        console.log(err);
      });
    }
    catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    //llamamos la funcion del efecto para obtener las sucursales
    obtenerSucursales();
  }, []);

  const ModalAdminUsuarios = () => {

    const roles = [{ value: 'admin', label: 'Administrador' }, { value: 'sucursal', label: 'Sucursal' },{value:'Agencia',label:'Agencia'}];
    const [role, setRole] = React.useState(selectedUser.rol);
    const handleChangeRole = (event) => { setRole(event.target.value); console.log(event.target.value) };

    const [sucursal, setSucursal] = React.useState("");
    const handleChangeSucursal = (event) => { setSucursal(event.target.value) };

    const [nEmail, setnEmail] = useState();
    const [nNombre, setnNombre] = useState(selectedUser.nombre);
    const [nError, setnError] = useState(null);

    const [nUpdateOkPass, setnUpdateOkPass] = useState(null);
    const [loadingFbQuery, setloadingFbQuery] = useState(false);
    const [loadingFbQueryPass, setloadingFbQueryPass] = useState(false);
    const [botonesDisabled, setbotonesDisabled] = useState(false);

    //editar foto
    const [imgToUpload, setImgToUpload] = useState(selectedUser.imgUsuario);
    const [errorImgUser, setErrorImageUser] = useState(false);
    const [loadingImgUserUpdate, setloadingImgUserUpdate] = useState(false);

    //Editar Usuario
    const [modoEdicionNombre, setModoEdicionNombre] = useState(false);
    const [updatingNombre, setUpdatingNombre] = useState(false);
    const [nErrorEditUser, setnErrorEditUser] = useState(null);
    const [nNombrePivote, setNombrePivote] = useState(selectedUser.nombre);

    const [modoEdicionRol, setModoEdicionRol] = useState(false);
    const [updatingRol, setUpdatingRol] = useState(false);
    const [nErrorEditRol, setnErrorEditRol] = useState(null);
    const [nRolPivote, setnRolPivote] = useState(selectedUser.rol);

    const [modoEdicionSucursal, setModoEdicionSucursal] = useState(false);
    const [updatingSucursal, setUpdatingSucursal] = useState(false);
    const [nErrorEditSucursal, setnErrorEditSucursal] = useState(null);
    const [nSucursalPivote, setnSucursalPivote] = useState(selectedUser.sucursal)


    const procesarAddUsuario = e => {
      console.log("Vamos a intentar añadir un usuario")
      e.preventDefault();


      if (nEmail === undefined || nPass === undefined || role === undefined || nNombre === undefined) {
        console.log('Datos vacios');
        setnError('Datos vacios ');
        return
      }

      if (!nEmail.trim()) {
        console.log('Datos vacios');
        setnError('Error en email');
        return
      }
      if (!nPass.trim()) {
        console.log('Datos vacios en el pass');
        setnError('Inserta un password');
        return
      }
      if (nPass.length < 6) {
        console.log('inserta 6 o mas caracteres');
        setnError('Escribe un password de mas de 6 caracteres.')
        return
      }
      console.log('vamos a revisar')
      setnError(null);
      setloadingFbQuery(true);
      setbotonesDisabled(true);
      //Añadimos usuario
      addUsuario(nEmail, nPass, role, nNombre);

    }
    const addUsuario = useCallback(async (n, m, r, nombreForm) => {
      try {
        //Creamos el usuario en Auth
        console.log("el mail " + n + "pass " + m + "rol " + r);
        const agregarUsuarioAuth = functions.httpsCallable('agregarUsuarioAuth');
        await agregarUsuarioAuth({ email: n, password: m, rol: r }).then((res2) => {
          /*console.log("respuesta del server");
            console.log(res2); console.log(res2.data.uid); console.log(res2.data.email);*/

          return db.collection('usuarios').doc(res2.data.email).set({
            email: res2.data.email,
            uid: res2.data.uid,
            nombre: nombreForm,
            rol: r,
          }).then(res => {
            console.log("Usuario añadido a los documentos");
            setloadingFbQuery(false);
            setnEmail('');
            setnPass('');
            setnError('');
            /*obtenerUsuarios().then(()=> {  handleCloseAddUserModal();    
            setbotonesDisabled(false);});*/
            handleCloseAddUserModal();
            setbotonesDisabled(false);


          }).catch((err) => {
            console.log("fallo la principal de agregar usuario con FB");
            setloadingFbQuery(false);
            setnEmail('');
            setnPass('');
            setbotonesDisabled(false);
            setnError(err);
          });

        })
      } catch (error) {
        console.log('catch pricnipal');
        console.log(error.message);

        if (error.code === 'auth/invalid-email') {
          setnError("Email no valido");
        }
        if (error.code === 'auth/email-already-in-use') {
          setnError("Email ya utilizado");
        }
        else {
          setnError(error.code);
        }
        setloadingFbQuery(false);
        setnEmail('');
        setnPass('');
        setbotonesDisabled(false);
      }

    }, []);

    const eliminarUsuario = useCallback(async () => {
      setloadingFbQuery(true);
      setbotonesDisabled(true);
      try {
        const eliminarUsuario = functions.httpsCallable('eliminarUsuarioAuth');
        eliminarUsuario(selectedUser).
          then(response => {

            if (response.data.message === "usuario borrado") {
              db.collection('usuarios').doc(selectedUser.email).delete()
                .then(respuesta => {
                  console.log('usuario eliminado de los documentos ' + respuesta)
                  handleCloseEliminarUserModal();
                })
            }
            console.log(response.data);
            setbotonesDisabled(true);
          })


        return "usuario Eliminar con Exito";
      } catch (error) {
        setbotonesDisabled(true);
        return error
      }

    });

    const procesarUpdateNombre = () => {
      setnErrorEditUser(null);
      setUpdatingNombre(true)
      console.log("Vamos a intentar modificar un usuario")

      if (!nNombre.trim()) {
        console.log('Datos vacios');
        setUpdatingNombre(false)
        setnErrorEditUser('Datos vacios en Nombre');
        return
      }

      console.log("primer filtro de updatear listo");
      setnErrorEditUser(null);
      //vamos aupdatear Usuario
      updateUsuario(nNombre);

    }

    const updateUsuario = useCallback(async (nom) => {
      console.log("nombre desde el update" + nom);

      return db.collection('usuarios').doc(selectedUser.email).update({
        nombre: nom
      }).then(res => {
        console.log("se actualizo el nombre ");
        setUpdatingNombre(false);
        setModoEdicionNombre(false);
        setNombrePivote(nom);

      }).catch((err) => {
        console.log("fallo al actualizar con FB");
        setUpdatingNombre(false);
        setnErrorEditUser(err.error);
      });

    });

    const procesarUpdateUsuarioRol = () => {

      setnErrorEditRol(null);
      setUpdatingRol(true)
      console.log("Vamos a intentar modificar un usuario")

      if (!nNombre.trim()) {
        console.log('Datos vacios');
        setUpdatingRol(false)
        setnErrorEditRol('Datos vacios en Nombre');
        return
      }

      console.log("primer filtro de updatear listo");
      setnErrorEditRol(null);
      //vamos aupdatear Usuario
      actualizarRoldeUsuario();

    }
    const actualizarRoldeUsuario = useCallback(async () => {

      try {
        const actualizarRolUsuario = functions.httpsCallable('actualizarRoldeUsuario');
        actualizarRolUsuario(selectedUser).
          then(response => {

            db.collection('usuarios').doc(selectedUser.email).update({
              rol: role
            }).then(respuesta => {
              console.log('usuario actualizado en el rol ' + respuesta)
              setUpdatingRol(false);
              setnRolPivote(role);
              setModoEdicionRol(false);
            })
            console.log(response.data);
          })


        return "Rol actualizado con exito";
      } catch (error) {
        setUpdatingRol(false);
        setnErrorEditRol(error);
        return error
      }

    });

    const procesarUpdateUsuarioSucursal = () => {
      setnErrorEditSucursal(null);
      setUpdatingSucursal(true);
      if (!sucursal.nombre.trim()) {
        setUpdatingSucursal(false);
        setnErrorEditSucursal("Selecciona una sucursal")
        return
      }
      actualizarSucursalDeUsuario(sucursal);

    }

    const actualizarSucursalDeUsuario = useCallback(async (sucur) => {
      console.log("actualizar sucursal" + sucur);

      return db.collection('usuarios').doc(selectedUser.email).update({
        sucursal: sucur
      }).then(res => {
        console.log("se actualizo la sucursal ");
        setUpdatingSucursal(false);
        setModoEdicionSucursal(false);
        setnSucursalPivote(sucur);

      }).catch((err) => {
        console.log("fallo al actualizar con FB");
        setUpdatingSucursal(false);
        setnErrorEditSucursal(err.error);
      });

    });

    const handleImageChange = async (e) => {

      setloadingImgUserUpdate(true);
      console.log("vamos a cambiar la imagen");
      let imagen = e.target.files[0];
      console.log(imagen)
      //imagen =URL.createObjectURL(imagen);
      if (imagen === undefined) {
        console.log("no se seleccion ninguna imagen")
        setErrorImageUser("No se selecciono una imagen")
        setloadingImgUserUpdate(false);
        return
      }
      console.log(imagen.size);
      if (imagen.size > 2000000) {
        console.log("La imagen debe ser menor a 2Mb")
        setErrorImageUser("La imagen debe ser menor a 2Mb ")
        setloadingImgUserUpdate(false);
        return
      }
      console.log(imagen.type)
      //Subimos la imagen
      if (imagen.type === 'image/png' || imagen.type === 'image/jpg' || imagen.type === 'image/jpeg') {
        console.log("intentaremos subir la imagen");
        //subimos la imagen a Firestorage
        const imgRefStorage = await storage.ref('imgPerfiles').child(selectedUser.email).child('fotoPerfil');
        await imgRefStorage.put(imagen);
        console.log("imagen subida a firestorage");
        const imagenStorageURL = await imgRefStorage.getDownloadURL();

        //actualizamos la imagen en Firestore
        await db.collection('usuarios').doc(selectedUser.email).update({
          imgUsuario: imagenStorageURL
        }).then(() => {
          console.log("actualizada la tabla");
        }
        );
        console.log("imagensubida a firestore");
        setloadingImgUserUpdate(false);
        //setSelectedUser({...selectedUser,'imgUsuario':imagenStorageURL})
        //cuando finaliza podemos actualizar el usuario y se refrescara la info completa
        //const usuarioUpdateado = {...selectedUser,img:imagenStorageURL}
        setImgToUpload(URL.createObjectURL(imagen));
        console.log("cambio la imagen");
      } else {
        //mandar a imprimir un estado de error
        setErrorImageUser("No se selecciono un archivo del tipo .png y .jpg ")
        setloadingImgUserUpdate(false);
        return
      }
    }

    const handleImageUpdate = () => {
      const fileinput = document.getElementById('imageInput');
      fileinput.click();
    }

    //variables para el panel del password
    const [expanded, setExpanded] = useState(false);
    const [nPass, setnPass] = useState('');
    const [nErrorPass, setnErrorPass] = useState(null);
    const handlePanelPassword = () => { setExpanded(!expanded); }
    const cambiarPass = useCallback(async (password) => {
      if (!nPass.trim()) {
        console.log('Datos vacios en el pass');
        setnErrorPass('Inserta un password');
        return
      }
      if (nPass.length < 6) {
        console.log('inserta 6 o mas caracteres');
        setnErrorPass('Escribe un password de mas de 6 caracteres.')
        return
      }
      setloadingFbQueryPass(true);
      setbotonesDisabled(true);
      console.log("vamos a cambiar el pass" + password);
      console.log({ password, ...selectedUser });
      const FBcambiarPass = functions.httpsCallable("cambiarPassword");
      FBcambiarPass({ password, ...selectedUser }).then(res => {
        console.log(res.data.respuestaOk);
        setloadingFbQueryPass(false);
        setnUpdateOkPass("Password actualizado");
      }).catch(err => {
        console.log(err);
        setnErrorPass(err.error);
        setloadingFbQueryPass(false);
      });
    });

    return (<>
      <>{/*MODAL ELIMINAR Usuario */}
        <Dialog open={openEliminarUserModal} onClose={handleCloseEliminarUserModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle className={classes.tituloModalEliminar} id="alert-dialog-title">{"¿Desea eliminar el usuario " + selectedUser.email + "?"}</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.textoModalEliminar} id="alert-dialog-description">
              Si se borra el usuario NO podra ser recuperado y todos los datos de analiticos seran tambien eliminados.
         </DialogContentText>
          </DialogContent>
          <Divider />
          {nError && (<Typography variant="subtitle1" align="center" color="error" children={nError} />)}
          {loadingFbQuery && (<Progress />)}
          <DialogActions>
            <Button className={classes.buttonModal} variant="contained" color="primary" onClick={handleCloseEliminarUserModal} disabled={botonesDisabled}>
              Cancelar
         </Button>
            <Button className={classes.buttonModal} variant="contained" color="secondary" onClick={() => eliminarUsuario()} disabled={botonesDisabled} autoFocus>
              Borrar
         </Button>
          </DialogActions>
        </Dialog></>
      <>{/*MODAL EDITAR Usuario */}
        <form className={classes.root} noValidate autoComplete="off">
          <Dialog open={openModalEditarUserModal} fullWidth maxWidth='sm' onClose={handleCloseEditarUserModal} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" style={{ display: 'flex', justifyContent: 'space-between' }} >
              {"Editar usuario "}
              <Typography display='inline' variant='h6' color="primary" >{nNombrePivote}</Typography>
              <IconButton className={classes.closeButton} variant='contained' onClick={handleCloseEditarUserModal} color="secondary">
                <CancelIcon fontSize='large' />
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent scroll='paper' >
              <Grid container spacing={2} direction="row" justify="space-between" alignItems="flex-start">
                <Grid item xs={12} sm={5} spacing={2}>
                  <DialogContentText className={classes.root} pt='1'>
                    Cambiar imagen de perfil
                </DialogContentText>
                  <Box display="flex" justifyContent="center" mb={0.5} pt={0.5}>
                    <ToolTips title="cambiar imagen" placement="bottom">
                      {loadingImgUserUpdate ? <CircularProgress size={80} /> : <Avatar alt="avatarImg" src={imgToUpload} onClick={() => handleImageUpdate()} className={classes.avatarSize} />
                      }
                    </ToolTips>
                    <input type="file" id="imageInput" hidden accept="image/*" onChange={handleImageChange} />
                  </Box>
                  {errorImgUser && <Typography variant="subtitle1" align="center" color="error" children={errorImgUser} />}
                  <DialogContentText>Password</DialogContentText>
                  <Accordion expanded={expanded} onChange={handlePanelPassword}>
                    <AccordionSummary id="panelexpansion" paddingBottom='0' expandIcon={<ExpandMoreIcon />}>
                      <Box >
                        <Typography variant="subtitle1" children={"Cambiar password"} />
                      </Box >
                    </AccordionSummary>
                    <AccordionDetails>
                      <TextField variant="outlined" margin="dense" required name="password" label="Contraseña" type="password" id="password" autoComplete={false}
                        onChange={e => setnPass(e.target.value)}
                        value={nPass}
                      />
                    </AccordionDetails>
                    {nErrorPass && (<Typography variant="subtitle1" align="center" color="error" children={nErrorPass} />)}
                    {nUpdateOkPass && (<Typography variant="subtitle1" align="center" color="primary" children={nUpdateOkPass} />)}
                    {loadingFbQueryPass && (<Progress />)}
                    <Divider />

                    <AccordionActions>
                      <Button className={classes.buttonModal} variant='contained' onClick={() => cambiarPass(nPass)} color="primary">
                        Actualizar Password
              </Button>
                    </AccordionActions>
                  </Accordion>
                </Grid>
                <Grid item sm={1} >
                  <Divider orientation='vertical' flexItem={true} className={classes.dividerVertical} />
                </Grid>
                <Grid item sm={6} spacing={2} >
                  <DialogContentText>Cambiar nombre de perfil</DialogContentText>
                  {modoEdicionNombre ? <><Box display="flex" alignItems="center" p={1} m={0}>
                    <TextField margin="dense" id="nameUsuario2" label="Nombre de Usuario" onChange={e => setnNombre(e.target.value)}
                      fullWidth={false} size='large' type="text" variant="outlined" value={nNombre} /> <Box pl={2}>
                      {updatingNombre ? <CircularProgress />
                        : <>
                          <IconButton pl={2} color="primary" size="medium" onClick={() => procesarUpdateNombre()}><UpdateIcon fontSize='large' /></IconButton>
                          <IconButton color="secondary" size="medium"
                            onClick={() => { setModoEdicionNombre(false); setnErrorEditUser(null); setnNombre(selectedUser.nombre) }}>
                            <CancelIcon fontSize='large' /></IconButton>
                        </>}  </Box></Box>
                    {nErrorEditUser && (
                      <Box display="flex" alignItems="center" p={1} m={0}>
                        <Typography variant="subtitle1" align="center" color="error" children={nErrorEditUser} />
                      </Box>)}
                  </>
                    : <Box display="flex" alignItems="center" p={1} m={0}>
                      <Typography display="inline" margin="dense" variant="subtitle1" align="left" color="primary" children={nNombrePivote} />
                      <IconButton pl={2} color="primary" size="medium" onClick={() => setModoEdicionNombre(true)} component="span" children={<EditIcon />} />
                    </Box>}
                  <Divider className={classes.dividerHorizontal} />
                  <DialogContentText>Rol de seguridad</DialogContentText>
                  {modoEdicionRol ?
                    <><Box display="flex" alignItems="center" p={1} m={0}>
                      <TextField id="electorDeNivelRole2" size='small' select margin="none" label="Nivel" variant="outlined" helperText="Nivel de acceso"
                        value={role}
                        defaultValue={nRolPivote}
                        onChange={handleChangeRole}
                      >
                        {roles.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Box pl={2}>
                        {updatingRol ?
                          <CircularProgress />
                          : <>
                            <IconButton pl={2} color="primary" size="medium" onClick={() => procesarUpdateUsuarioRol()}><UpdateIcon fontSize='large' /></IconButton>
                            <IconButton color="secondary" size="medium" onClick={() => { setModoEdicionRol(false); setnErrorEditRol(null); setRole(selectedUser.rol) }}>
                              <CancelIcon fontSize='large' /></IconButton>
                          </>}  </Box></Box>
                      {nErrorEditRol && (
                        <Box display="flex" alignItems="center" p={1} m={0}>
                          <Typography variant="subtitle1" align="center" color="error" children={nErrorEditRol} />
                        </Box>)}
                    </>
                    : <>
                      <Box display="flex" alignItems="center" p={1} m={0}>
                        <Typography display="inline" margin="dense" variant="subtitle1" align="left" color="primary" children={nRolPivote} />
                        <IconButton pl={2} color="primary" aria-label="editar-Rol" component="span"
                          onClick={() => setModoEdicionRol(true)}>
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </>}

                  <Divider className={classes.dividerHorizontal} />

                  <DialogContentText>Seleccionar sucursal</DialogContentText>
                  {
                    modoEdicionSucursal ? <><Box display="flex" alignItems="center" p={1} m={0}>
                      <TextField id="electorDeNvielSucursal" select size='small' margin="none"
                        label="Sucursal"
                        value={sucursal}
                        defaultValue={sucursal}
                        onChange={handleChangeSucursal}
                        helperText="Selecciona sucursal"
                        variant="outlined">
                        {sucursales.map((sucursal) => (
                          <MenuItem key={sucursal.id} value={sucursal}>
                            {sucursal.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Box pl={2}>
                        {updatingRol ? <CircularProgress /> : <>
                          <IconButton pl={2} color="primary" size="medium" onClick={() => procesarUpdateUsuarioSucursal()}><UpdateIcon fontSize='large' /></IconButton>
                          <IconButton color="secondary" size="medium" onClick={() => { setModoEdicionSucursal(false); setnErrorEditSucursal(null); setSucursal(selectedUser.sucursal) }}>
                            <CancelIcon fontSize='large' /></IconButton>
                        </>}  </Box></Box>
                      {nErrorEditSucursal && (
                        <Box display="flex" alignItems="center" p={1} m={0}>
                          <Typography variant="subtitle1" align="center" color="error" children={nErrorEditSucursal} />
                        </Box>)}

                    </>
                      : <>
                        <Box display="flex" alignItems="center" p={1} m={0}>
                          <Typography display="inline" margin="dense" variant="subtitle1" align="left" color="primary" children={typeof (nSucursalPivote) === 'undefined' ? "" : nSucursalPivote.nombre} />
                          <IconButton pl={2} color="primary" aria-label="editar-Rol" component="span"
                            onClick={() => setModoEdicionSucursal(true)}>
                            <EditIcon />
                          </IconButton>
                        </Box>

                      </>}

                </Grid>
              </Grid>
            </DialogContent>
            <Divider className={classes.dividerHorizontal} />
            {nError && (<Typography variant="subtitle1" align="center" color="error" children={nError} />)}
            {loadingFbQuery && (<Progress />)}
          </Dialog>
        </form></>
      <>{/*MODAL AÑADIR Usuario */}
        <form id="formSucursal" className={classes.root} noValidate onSubmit={procesarAddUsuario}>
          <Dialog maxWidth='sm' fullWidth open={openModalAddUser} onClose={handleCloseAddUserModal} aria-labelledby="form-dialog-title">
            <DialogTitle id="formAddSucursalTitulo">Añade un usuario</DialogTitle>
            <Divider /> <DialogContent >
              <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                <Grid item xs={5}>
                  <DialogContentText className={classes.root} pt='1'>
                    Correo y contraseña
                </DialogContentText>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="idnEmail"
                    onChange={e => setnEmail(e.target.value)}
                    value={nEmail}
                    label="Correo"
                    type="email"
                    required fullWidth
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    onChange={e => setnPass(e.target.value)}
                    value={nPass}
                  />
                </Grid>
                <Divider orientation='vertical' flexItem className={classes.dividerVertical} />
                <Grid item xs={6}>
                  <DialogContentText className={classes.root} pt='1'>
                    Nombre y nivel de acceso
              </DialogContentText>
                  <TextField
                    margin="dense"
                    required fullWidth
                    id="idnNombre"
                    onChange={e => setnNombre(e.target.value)}
                    value={nNombre}
                    label="Nombre de usuario"
                    type="text"
                    variant="outlined"
                  />
                  <TextField
                    id="electorDeCiudadAdd"
                    margin="dense"
                    select required fullWidth
                    label="nivel"
                    value={role}
                    onChange={handleChangeRole}
                    helperText="Selecciona un nivel"
                    variant="outlined">
                    {roles.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

              </Grid>  </DialogContent>
            <Divider />
            {nError && (<Typography variant="subtitle1" align="center" color="error" children={nError} />)}
            {loadingFbQuery && (<Progress />)}
            <DialogActions>
              <Button className={classes.buttonModal} variant='contained' onClick={handleCloseAddUserModal} color="secondary" disabled={botonesDisabled}>
                Cancelar
              </Button>
              <Button form="formSucursal" type='submit' className={classes.buttonModal} variant='contained' color="primary" disabled={botonesDisabled}>
                Añadir Usuario
              </Button>
            </DialogActions>

          </Dialog>
        </form></></>);
  }

  return (
    <ModalAdminUsuariosContext.Provider value={{
      handleClickOpenEliminarUserModal,
      handleClickOpenEditarUserModal, handleClickOpenAddUserModal, ModalAdminUsuarios
    }}>
      {props.children}
    </ModalAdminUsuariosContext.Provider>
  )

}

export default ModalAdminUsuariosProvider

import {createMuiTheme} from '@material-ui/core/styles';
import suzukiFont from './../assets/Fonts/SuzukiPROBold.woff';



const suzuki  = {
        fontFamily:"SuzukiPROBold Regular",
        src:  `  url('${suzukiFont}') format('woff')`   
      };



const tema =  createMuiTheme({
    palette:{
      primary:{
          main:'#005595',
          dark:'#003B68',
          light:'#3377AA'
        },//0B3340azul fuerte 3ro
      secondary:{
          main:'#EE2E22',
          dark:'#A62017',
          light:'#f1574e' 
        },
       info:{
           main:'#0B3340'
       }  
    },

    typography :{
        fontFamily: suzuki.fontFamily
       
   },
   overrides:{
     CssBaseline:{
      '@global': {
        '@font-face': [suzuki],
      },
     }
   }

})

export default tema;
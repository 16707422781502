import React, {useState,useEffect,useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Divider  from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import AddSucursalIcon from '@material-ui/icons/AddCircle';
import Grid from '@material-ui/core/Grid';
import MUIDataTable from 'mui-datatables';
import Link from '@material-ui/core/Link';
import ListItemIcon  from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListItem  from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import {ModalListaDeContenidosContext} from '../contexts/modalListaDeContenidosProvider';
import {db} from '../backEnd/firebase';




const estilos = makeStyles(theme =>( {

    button:{
        margin: theme.spacing(3),
        marginRight: theme.spacing(6)
    }
}));

//Sucursales con esta lista
 const columns = ["Nombre de lista", "cantidad de videos", "Acciones",];

  const data = [
   ["Lista Actual", "4", "60"],
   ["Lista Agosto 2020", "3", "1"],
   ["Lista Mayo 2020", "6", "0"],
  ];
  
  const options = {
    filterType: 'checkbox',
    filter: true,
    rowsPerPageOptions:[5,10,100],
    rowsPerPage:5,
    selectableRowsHeader:false,
    selectableRows:'none'
  };


const ListasDeContenido = () => {
    const classes = estilos();
    console.log(classes);

    //context del modal Listas de Contenido
    const {handleClickOpenModalEliminarListasDeContenido, handleClickOpenModalEditarContenidoModal,
        handleClickOpenModalAddListasDeContenido, ModalListaDeContenidos,AlertaSnackBar} = useContext(ModalListaDeContenidosContext);
    
    //variables de estado 
    const [listasDeContenido,setListasDeContenido]= useState([]);

     //Read de las listas de Contenidos
    const obtenerListasDeContenidos = async()=>{ 
    console.log("READ de Lista")
    try{ 
        db.collection('listaDeContenidos').onSnapshot(dataLContenidos =>{
        console.log(dataLContenidos.docs);
    //convertimos el objeto de fb a un array para el estado
    const arrayListaContenidos = dataLContenidos.docs.map(doc =>({id:doc.id,... doc.data()}));
    console.log(arrayListaContenidos);
    setListasDeContenido(arrayListaContenidos);
     })
   }
   catch (error){
     console.log(error.message);
   }
 }
 useEffect(()=>{
   //llamamos la funcion del efecto para obtener las listas de contenido
   obtenerListasDeContenidos();
    },[]);

    
const iconosDeRowElement = (Lcontenido) =>{ 
    console.log(Lcontenido);
    return(
    <ListItem >
          <ListItemIcon  onClick={()=> {handleClickOpenModalEditarContenidoModal(Lcontenido)}}>
            <IconButton color='inherit' ><EditIcon fontSize='large' style = {{color:'#3377AA'}}/></IconButton>
        </ListItemIcon>
        <ListItemIcon  onClick={()=> {handleClickOpenModalEliminarListasDeContenido(Lcontenido);}}>
            <IconButton color='secondary' ><DeleteIcon fontSize='large' color='secondary'/></IconButton>  
        </ListItemIcon>                   
    </ListItem>)
    }

    
//Data de la tabla de Listas de contenidos
const data = listasDeContenido.map(listaDeContenido => (
    [listaDeContenido.nombre,listaDeContenido.listaDeVideos.length,iconosDeRowElement(listaDeContenido)]
  ));

    return (
        <Container maxWidth='xl'>
            <Typography color='textSecondary' variant="h5">Listas de reproducción de contenido</Typography>
            <Divider/>
            <Grid container direction="row" justify="flex-end" alignItems="center">    
                <Button variant="contained" onClick ={handleClickOpenModalAddListasDeContenido}  size='large' color="primary" className = {classes.button}  startIcon={<AddSucursalIcon/>}>
                    Añadir Lista de contenido
                </Button>
            </Grid>   
            <MUIDataTable
                title={"Listas de contenido"}
                data={data}
                columns={columns}
                options={options}
            />
         
            < ModalListaDeContenidos/>
            
           
    </Container>
    );
}

export default ListasDeContenido

import React, { useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FolderIcon from '@material-ui/icons/Folder';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FileViewer from 'react-file-viewer';
import FilePreviewer from 'react-file-previewer';
import { FilePreviewerThumbnail } from 'react-file-previewer';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { db,firebase } from '../backEnd/firebase';
import _ from 'lodash';
//import { downloadFile } from 'react-file-downloader';
import fileDownload from 'js-file-download';
import axios from 'axios';
import moment from 'moment';
import { UsuarioContext } from '../contexts/usuarioProvider';




//Estilo del componente de documentos
const estilosX = makeStyles(theme => ({
    thumbnail:{
        maxWidth: 345,
    },
    root: {
        maxWidth: 345,
      },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    button: {
        margin: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    textoIcono: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        margin: 'inherit',
        color: 'blue'
    },
    tituloModalEliminar: {
        color: theme.palette.secondary.light
    },
    textoModalEliminar: {
        color: theme.palette.secondary.dark
    },
    icono: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        backgroundColor: 'yellow',
        color: '#fff',
        background: 'yellow',
        margin: '400'
    },
    botones: {
        padding: theme.spacing(2),

    }
}));

function Carpetas(props) {
    console.log("zona de carpetas")
    console.log(props.data);
    const x = props.data;
    console.log(x.data)
    const carpetaArray = _.filter(x.data, docu => docu.tipo === "folder");
    console.log("testing carpetas " + carpetaArray);

    return (
        <>
            {props.data &&
                carpetaArray.map((carpetaData, index) => (
                    console.log("Carpetas en loop"),
                    console.log(carpetaData),
                    console.log(index),
                    <Carpeta key={index} dataCarpetas={carpetaData} pasarUrl={props.data.callBackFunSetNodoDocumentos}/>
                ))
            }
        </>)
}

export function Archivos(props) {
    console.log("zona de archivos")
    console.log(props.data);
    const x = props.data;
    console.log(x.data)
    const archivosArray = _.filter(x.data, docu => docu.tipo === "archivo");

    console.log("testing arcvhivos " + archivosArray);
    return (
        <>
            {props.data &&
                archivosArray.map((archivoData, index) => (
                    console.log("archivos en loop"),
                    console.log( firebase.firestore.Timestamp(archivoData.fecha)),
                    console.log(archivoData.fecha && archivoData.fecha),
                    console.log(archivoData),
                    console.log(index),
                    <Archivo key={index} dataArchivo={archivoData} />
                ))
            }
        </>
    )
}


function Carpeta(props) {
    //Pasamos el estilo del este componente
    const estilos = estilosX();

    //Estado para abrir el menu de popover
    const [anchorEl, setAnchorEl] = React.useState(null);

    //Variables de estado de Modal Eliminar Folder
    const [isVisibleModalDeleteFolder, setIsVisibleModalDeleteFolder] = useState(false);
    const mostrarModalDeleteFolder = () => setIsVisibleModalDeleteFolder(true);
    const ocultarModalDeleteFolder = () => { setIsVisibleModalDeleteFolder(false); handleClosePopOver(); };

    //Variables de estado de Modal Editar Nombre
    const [isVisibleModalEditFolder, setIsVisibleModalEditFolder] = useState(false);
    const mostrarModalEditFolder = () => setIsVisibleModalEditFolder(true);
    const ocultarModalEditFolder = () => { setIsVisibleModalEditFolder(false); handleClosePopOver(); }


    const clickIzquierdo = (e) => {
        console.log('Left click');
      loadNode(props);
    }
    const loadNode = async (props) =>{
       
      //  let x = await db.collection('documentos').sub(props.dataCarpetas.id).collection("subcarpeta2").get();
        console.log("datacarpeyads id:" + (props.dataCarpetas.id).trim())
      //  db.collection('documentos').where(firebase.firestore.FieldPath.documentId(), '==',(props.dataCarpetas.id+"/"+"subcarpeta2")).get().then((querySnapshot)=>{
        let path= 'documentos/'+(props.dataCarpetas.id).trim()  ;
        db.collectionGroup('subcarpeta').where('id','==',(props.dataCarpetas.id).trim()).get().then(querySnapshot=>{

        console.log(path)
        
   //  db.collection(path).get().then((querySnapshot)=>{
            console.log(querySnapshot.docs);
            if (!querySnapshot.docs.length == 0){
                querySnapshot.forEach(function(doc) {
                console.log(doc.id, " => ", doc.data());
                
                if (!querySnapshot.empty) {
                    console.log("existe la ruta")
                    let arrayDocumentos = querySnapshot.docs.map(doc => ({ id: doc.id,...doc.data()}));
                    path = arrayDocumentos.ruta;
                    arrayDocumentos = _.remove(arrayDocumentos,function(n){
                        return n.key === 'id';
                    })
                    console.log(arrayDocumentos);
                    props.pasarUrl(arrayDocumentos,path);
                
                } else {
                    console.log("no exciste la ruta")
                    props.pasarUrl(null);
                }
            });
        } else {
            console.log("no exciste la ruta")
            props.pasarUrl(null);
        }
              
        }
        ).catch(function(error) {
            console.log("Error getting documents: ", error);
        });
      //  console.log("subcoleccion " + x.docs.keys );
       // console.log("json de data " + JSON.stringify(x.docs.keys()));
        
      // props.pasarUrl(y.docs);
    }

    //click derecgo para abrir el PopOver Menu
    const clickDerecho = (e) => {
        e.preventDefault();
        setAnchorEl(e.currentTarget);
    }

    //se cierra el PopOverMenu haciendo nullo el AnchorElement
    const handleClosePopOver = () => {
        setAnchorEl(null);
    };



    const eliminarFolder = useCallback(async (id) => {
        db.collection('documentos').doc(id).delete()
            .then(respuesta => {
                console.log('documentos eliminads de los documentos ' + respuesta)
                ocultarModalDeleteFolder();
                return "documento Eliminado con Exito";
            }).catch(error => {
                return error
            });

    });



    return (
        <Grid item>
            <Button onClick={(e) => clickIzquierdo(e)}
                onContextMenu={(e) => clickDerecho(e)} variant="contained" color="default" 
                className={estilos.button}
                startIcon={<FolderIcon />}>
                {props.dataCarpetas.nombre}
            </Button>
            <Popover open={Boolean(anchorEl)}
                anchorEl={anchorEl} onClose={handleClosePopOver}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                transformOrigin={{ vertical: 'center', horizontal: 'left', }}>
                <List component="nav" aria-label="main mailbox folders">
                    <ListItem button onClick={mostrarModalEditFolder}>
                        <ListItemIcon >
                            <EditIcon fontSize='default' color='Primary' />
                        </ListItemIcon>
                        <ListItemText primary="Cambiar Nombre" />
                    </ListItem>
                    <ListItem button onClick={mostrarModalDeleteFolder}>
                        <ListItemIcon >
                            <DeleteIcon fontSize='default' color='Secondary' />
                        </ListItemIcon>
                        <ListItemText primary="Eliminar Carpeta" />
                    </ListItem>
                </List>
                <Divider />
            </Popover>
            <>{/*MODAL ELIMINAR Folder */}
                <Dialog open={isVisibleModalDeleteFolder} onClose={ocultarModalDeleteFolder} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle className={estilos.tituloModalEliminar} id="alert-dialog-title">{"¿Desea eliminar la carpeta " + props.dataCarpetas.nombre + "?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText className={estilos.textoModalEliminar} id="alert-dialog-description">
                            ¿Estas seguro que deseas eliminar la carpeta?
     </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={ocultarModalDeleteFolder}>
                            Cancelar
     </Button>
                        <Button variant="contained" color="secondary" onClick={() => eliminarFolder(props.dataCarpetas.id)} autoFocus>
                            Borrar
     </Button>
                    </DialogActions>
                </Dialog></>
            <>{/*MODAL EDITAR Nombre Folder */}
                <form className={estilos.root} noValidate autoComplete="off">
                    <Dialog maxWidth='sm' open={isVisibleModalEditFolder} onClose={ocultarModalEditFolder} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Nombre de carpeta</DialogTitle>
                        <Divider />
                        <DialogContent >
                            <TextField
                                autoFocus
                                margin="dense"
                                id="nameUsuario"
                                label="Nombre de carpeta"
                                type="text"
                                variant="outlined"
                            />
                        </DialogContent>
                        <DialogActions className={estilos.botones}>
                            <Button variant='contained' onClick={ocultarModalEditFolder} color="secondary">
                                Cancelar
          </Button>
                            <Button variant='contained' onClick={ocultarModalEditFolder} color="primary">
                                Actualizar nombre
          </Button>
                        </DialogActions>
                    </Dialog>
                </form></>
        </Grid>
    );
}

function Archivo(props){

    const estilos = estilosX();
    console.log("porps data Archivo")
    console.log(props.dataArchivo);
    const extension = props.dataArchivo.URLContenido.split('.').pop(); 
    console.log("la extension" + extension)

     //context del usuario
    const { usuario } = React.useContext(UsuarioContext);

    //Variables de estado de Modal Eliminar Archivo
    const [isVisibleModalDeleteArchivo, setIsVisibleModalDeleteArchivo] = useState(false);
    const mostrarModalDeleteArchivo = (isopen) => setIsVisibleModalDeleteArchivo(isopen);
   


    const eliminarArchivo = useCallback(async (id) => {
        db.collection('documentos').doc(id).delete()
            .then(respuesta => {
                console.log('documentos eliminads de los documentos ' + respuesta)
                mostrarModalDeleteArchivo(false);
                return "documento Eliminado con Exito";
            }).catch(error => {
                return error
            });

    });


    return(<>
    <Grid item>
   <Card className={estilos.root}>
   
   <CardActionArea onClick={()=>{window.open(props.dataArchivo.URLContenido, '_blank');}}>
       {extension==="png"|| extension==="jpg"||extension==="PNG"|| extension==="JPG"  ?
       <CardMedia
          component="img"
          hideControls="false"
          alt={props.dataArchivo.nombre}
          height="140"
          image={props.dataArchivo.URLContenido}
          title= {props.dataArchivo.nombre}
        />: 
        
        <FilePreviewerThumbnail file={{
            url:  props.dataArchivo.URLContenido,   
            name:  props.dataArchivo.nombre
             }}
            hideControls="false"
            style={{maxWidth: 200,minHeight:140,
                    display: "table",textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",alignItems:'middle'}}
            className={estilos.pdfViewer}
        />}
        
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" display="inline">
          {props.dataArchivo.nombre}
          </Typography>
          <Typography variant="body2" color="textSecondary"display="inline" >
          {" ."+extension}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          {
              props.dataArchivo.fecha &&
             moment.unix(props.dataArchivo.fecha.seconds).format("MM/DD/YYYY , h:mm:ss a")}{ /* props.dataArchivo.fecha.toDate().toLocaleTimeString() }*/}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
          { usuario.rol === 'admin' || usuario.rol === 'Agencia' ?
        <Button size="small" color="secondary" onClick={()=>mostrarModalDeleteArchivo(true)}>
          Borrar
        </Button>:<></>}
        <Button size="small" color="primary" onClick={()=>{    
                    axios.get(props.dataArchivo.URLContenido,{responseType:'blob'}).then(res=>fileDownload(res.data,props.dataArchivo.nombre+"."+extension))  
                    }
                }>
          Descargar 
        </Button>
      </CardActions>
                
            </Card>
     <>{/*MODAL ELIMINAR Arcvhivo */}
                <Dialog open={isVisibleModalDeleteArchivo} onClose={()=>mostrarModalDeleteArchivo(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle className={estilos.tituloModalEliminar} id="alert-dialog-title">{"¿Desea eliminar el archivo " + props.dataArchivo.nombre + "?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText className={estilos.textoModalEliminar} id="alert-dialog-description">
                            ¿Estas seguro que deseas eliminar el archivo?
     </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={()=>mostrarModalDeleteArchivo(false)}>
                            Cancelar
     </Button>
                        <Button variant="contained" color="secondary" onClick={() => eliminarArchivo(props.dataArchivo.id)} autoFocus>
                            Borrar
     </Button>
                    </DialogActions>
                </Dialog></>
    </Grid>
   </>)
}

//{*<a  href={props.dataArchivo.URLContenido} target="_blank" download={props.dataArchivo.nombre}>ssss*}</a>

//Fetching y carga de Nodos
export const NodoPrincipalDocumentos = (props) => {


    console.log("seteo de var doen carpetc" + JSON.stringify(props.data))
    console.log(props.data)
    return (<>
        <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item xs={12}>
                <Typography color='textSecondary' variant="h6">Carpetas</Typography>
            </Grid>
            <Carpetas data={props} />
            <Divider />
        </Grid>

    
        <Grid container direction="row" justify="flex-start" alignItems="cen
        ter" spacing={4}>
             <Grid item xs={12}>
                <Typography color='textSecondary' variant="h6">Archivos</Typography>
            </Grid>
            <Archivos data={props} />
        </Grid>

    </>);
}




import React, { useState, useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider  from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Graficas from '../Componentes/graficas';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import SucursalIcon from '@material-ui/icons/EmojiTransportation';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import * as d3 from "d3";
import PieChart from "../Componentes/PieChart";
import "../Estilos/pieChart.css";
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from  '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import FiltroIcon from '@material-ui/icons/FilterTwoTone';
import LimpiarIcon from '@material-ui/icons/FilterNone';
import { MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme =>({
  root: {
    flexGrow: 1,
    maxWidth: 500,
  }, button:{
    margin: theme.spacing(1),
    marginRight: theme.spacing(1)
}, column: {
    flexBasis: '33.33%',
  },
  dividerVertical:{
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(3)
  }
}));

const filtroSucursales = [
  {
    value:'general',
    label:'General'
  },
  {
    value: 'pedregal',
    label: 'Pedregal',
  },
  {
    value: 'coacalco',
    label: 'coacalco',
  },
  {
    value: 'vallejo',
    label: 'Vallejo',
  },
  {
    value: 'tangamanga',
    label: 'Tangamanga',
  },
];

const filtroPeriodo = [
  {
    value: 'mensual',
    label: 'Mensual',
  },
  {
    value: 'trimestral',
    label: 'Trimestral',
  },
  {
    value: 'semestral',
    label: 'Semestral',
  },
  {
    value: 'anual',
    label: 'Anual',
  },
];

const filtroMeses = [
  {
    value: 'enero',
    label: 'Enero',
  },
  {
    value: 'febrero',
    label: 'Febrero',
  },
  {
    value: 'marzo',
    label: 'Marzo',
  },
  {
    value: 'abril',
    label: 'Abril',
  },
  {
    value: 'mayo',
    label: 'Mayo',
  },
  {
    value: 'junio',
    label: 'Junio',
  },
  {
    value: 'julio',
    label: 'Julio',
  },
  {
    value: 'agosto',
    label: 'Agosto',
  },
  {
    value: 'septiembre',
    label: 'Septiembre',
  },
   {
    value: 'octubre',
    label: 'Octubre',
  },
  {
   value: 'noviembre',
   label: 'Noviembre',
 },
 {
  value: 'diciembre',
  label: 'Diciembre',
}
];
const filtroYears = [
  {
    value: '2020',
    label: '2020',
  }
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


const Analiticos = () => {

  const estilos = useStyles();

  const generateData = (value, length = 5) =>
    d3.range(length).map((item, index) => ({
      date: index,
      value: value === null || value === undefined ? 25 : value
    }));

  const [data, setData] = useState(generateData(0));
  const changeData = () => {
    setData(generateData());
  };

  useEffect(
    () => {
      setData(generateData());
    },
    [!data]
  );
  

  //Variables y metodos para selector de filtro por Sucursal
  const [fSucursal, setfSucursal] = React.useState('general');
  const handleChangefSucursales = (event) => {
    setfSucursal(event.target.value);
  };

  //Variables y metodos para selector de filtro por Periodo
  const [fPeriodo, setfPeriodo] = React.useState('mensual');
  const handleChangefPeriodo = (event) => {
    setfPeriodo(event.target.value);
  };

  //Variables y metodos para selector de filtro para Mes Final
  const [fMesFinal, setfMesFinal] = React.useState('enero');
  const handleChangefMesFinal = (event) => {
    setfMesFinal(event.target.value);
  };

   //Variables y metodos para selector de filtro por Año
   const [fyear, setfYear] = React.useState('2020');
   const handleChangefyear = (event) => {
     setfYear(event.target.value);
   };
 
    //Constante para Selector de Fecha de Inicio
  const [selectedDateInicio, setSelectedDateInicio] = React.useState(new Date('2014-08-18T21:11:54'));
  const handleDateInicioChange = (date) => {
    setSelectedDateInicio(date);
  };
  
  //Constante para Selector de Fecha de Final
  const [selectedDateFinal, setSelectedDateFinal] = React.useState(new Date('2014-08-18T21:11:54'));
  const handleDateFinalChange = (date) => {
    setSelectedDateFinal(date);
  };


  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 

    return (
        <Container maxWidth='xl'>
           <Typography color='textSecondary' variant="h5">Analiticos</Typography>
           <Divider style ={{marginBottom:'20px'}}/>
           <Paper >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="primary"
              aria-label="icon label tabs example"
            >
              <Tab icon={<SucursalIcon />} label="Internet/signage" />
              <Tab icon={<SucursalIcon />} label="Incidencias" />
            </Tabs>
 
  <TabPanel open value={value} index ={0}>
  <Paper elevation={6}>
              <Box  m={1} p={1} tm={0.5}>
                <Typography variant="h6" >Grafica general de conexiónes</Typography>
                </Box>
                <Divider/>
                 <Accordion defaultExpanded >
                 <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                >
                 <Box >
                 <Typography variant="subltitle1" > Filtros</Typography>
                 </Box>
                 </AccordionSummary>
                 <AccordionDetails>
                    <Grid container spacing={2} >
                    <Grid item>
                   <TextField
                      id="selectorSucursal"
                      select
                      label="Sucursales"
                      value={fSucursal}
                      onChange={handleChangefSucursales}
                      helperText="Selecciona una sucursal"
                      variant="outlined"
                    >
                      {filtroSucursales.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                  </Grid>
                   <Grid item>
                   <TextField
                      id="selectorPeriodo"
                      select
                      label="Periodo"
                      value={fPeriodo}
                      onChange={handleChangefPeriodo}
                      helperText="Selecciona un periodo"
                      variant="outlined"
                    >
                      {filtroPeriodo.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                  </Grid>
                  <Grid item>
                   <TextField
                      id="selectorMesFinal"
                      select
                      label="MesFinal"
                      value={fMesFinal}
                      onChange={handleChangefMesFinal}
                      helperText="Selecciona un Mes"
                      variant="outlined"
                    >
                      {filtroMeses.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                  </Grid>
                  <Grid item>
                   <TextField
                      id="selectorYear"
                      select
                      label="Año"
                      value={fyear}
                      onChange={handleChangefyear}
                      helperText="Selecciona un Año"
                      variant="outlined"
                    >
                      {filtroYears.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                  </Grid>
                  <Divider orientation='vertical' className={estilos.dividerVertical} />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item>
                      <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog-inicio"
                          label="Fecha de inicio"
                          format="MM/dd/yyyy"
                          value={selectedDateInicio}
                          onChange={handleDateInicioChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                  
                  </Grid>
                  <Grid item>
                
                      <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label="Fecha final"
                          format="MM/dd/yyyy"
                          value={selectedDateFinal}
                          onChange={handleDateFinalChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                  
                  </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                 <Grid item>         
                   <Button size='medium' className={estilos.button} startIcon={<LimpiarIcon />} variant='outlined'  color="primary">
                   Limpiar
                </Button>
                <Button size='medium'  className={estilos.button} startIcon={<FiltroIcon />} variant = 'contained'  color="primary">
                   Filtrar
                </Button>
                </Grid>
            </AccordionDetails>
                 </Accordion>
                <Divider/>
               <Grid container direction="row" justify="center" alignItems='center' style={{paddingTop: '20px', paddingRight: '320px'}}>
                   <Grid item xs={4}>
                   <PieChart tituloGrafica="Conexión a Digital Signage"
                      data={data}
                      width={200}
                      height={200}
                      innerRadius={40}
                      outerRadius={120}
                    />
                  </Grid>
                  <Grid item xs={4}>
                   <PieChart
                      tituloGrafica="Computadoras Encendidas"
                      data={data}
                      width={200}
                      height={200}
                      innerRadius={40}
                      outerRadius={120}
                    />
                  </Grid>
                  <Grid  item xs={4}>
                    <Graficas tituloGrafica="Conexión a Digital Signage" color1="blue" color2="white"/>
                  </Grid> 
              </Grid>
           </Paper>
  </TabPanel>

  <TabPanel value={value} index ={1}>
  <Paper elevation={3} >
               <Grid container direction="row" justify="center" alignItems='center' style={{paddingTop: '20px', paddingRight: '320px'}}>
                    <Graficas tituloGrafica="Conexión a Digital Signage"color1="red" color2="yellow"/>
              </Grid>
           </Paper>
  </TabPanel>
</Paper>
           
        </Container>
    )
}

export default Analiticos

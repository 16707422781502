import React,{useContext,useEffect,useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Divider  from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import AddSucursalIcon from '@material-ui/icons/AddCircle';
import Grid from '@material-ui/core/Grid';
import MUIDataTable from 'mui-datatables';
import Link from '@material-ui/core/Link';
import ListItemIcon  from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListItem  from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import {ModalContenidosContext} from '../contexts/modalContenidosProvider';
import {db} from '../backEnd/firebase';

const estilos = makeStyles(theme =>( {

    button:{
        margin: theme.spacing(3),
        marginRight: theme.spacing(6)
    }
}));

const columns = ["", "Nombre","Visualizar","Acciones"];
const options = {
    filterType: 'checkbox',
    filter: true,
    rowsPerPageOptions:[5,10,20],
    rowsPerPage :10,
    selectableRowsHeader:false,
    selectableRows:'none'
  };

const Contenidos = () => {

//variables de estado de sucursal y contenido
const [contenidos,setContenidos]= useState([]);

const classes = estilos();
console.log(classes);
  //context del modal de contenido
  const {handleClickOpenEliminarContenido, handleClickOpenEditarContenidoModal,
        handleClickOpenAddContenidoModal, handleClickOpenVideoModal,
        ModalContenidos,AlertaSnackBar} = useContext(ModalContenidosContext);

    //Read de los Contenidos
 const obtenerContenidos = async()=>{ 
     console.log("READ de Contenidos")
    try{ 
    db.collection('contenidos').onSnapshot(dataContenidos =>{
      console.log(dataContenidos.docs);
     //convertimos el objeto de fb a un array para el estado
     const arrayContenidos = dataContenidos.docs.map(doc =>({id:doc.id,... doc.data()}));
     console.log(arrayContenidos);
     setContenidos(arrayContenidos);
      })
    }
    catch (error){
      console.log(error);
    }
  }
  useEffect(()=>{
    //llamamos la funcion del efecto para obtener las contenido
    obtenerContenidos();
     },[]); 


const iconosDeRowElement = (contenido) =>{ 
    console.log(contenido);
    return(
    <ListItem >
          <ListItemIcon  onClick={()=> {handleClickOpenEditarContenidoModal(contenido)}}>
            <IconButton color='inherit' ><EditIcon fontSize='large' style = {{color:'#3377AA'}}/></IconButton>
        </ListItemIcon>
        <ListItemIcon  onClick={()=> {handleClickOpenEliminarContenido(contenido);}}>
            <IconButton color='secondary' ><DeleteIcon fontSize='large' color='secondary'/></IconButton>  
        </ListItemIcon>                   
    </ListItem>)
    }

const videoData = (url) =>{
        
       // return(<ReactPlayer height={100} url={url} light={true}/> )
       return(<video height={100} src={url+'#t=10'} /> )
    }

const linkVideo = (contenido) =>{

    return(
        <Typography>
             <Link href= {contenido.URLContenido} onClick={(e)=>{e.preventDefault();handleClickOpenVideoModal(contenido)}} color="primary">
                {contenido.URLContenido}
            </Link>
        </Typography>
    )
}    

//Data de la tabla de contenidos
const data = contenidos.map(contenido => (
    [videoData(contenido.URLContenido),contenido.nombre,linkVideo(contenido) ,iconosDeRowElement(contenido)]
  ));

    return (
        <Container maxWidth='xl'>
            <Typography color='textSecondary' variant="h5">Biblioteca de Contenidos</Typography>
            <Divider/>
            <Grid container direction="row" justify="flex-end" alignItems="center">    
                <Button onClick ={handleClickOpenAddContenidoModal} variant="contained" size='large' color="primary" className = {classes.button}  startIcon={<AddSucursalIcon/>}>
                    Añadir video contenido
                </Button>
            </Grid>   
            <MUIDataTable
                title={"Video contenido"}
                data={data}
                columns={columns}
                options={options}
            />
            <AlertaSnackBar/>
            <ModalContenidos />
            
    </Container>
    );
}

export default Contenidos

import React from 'react';
import {Route,Routes} from "react-router-dom";
import Login from './Vistas/login';
import Dashboard from './Vistas/dashboard';
import {ThemeProvider} from '@material-ui/core/styles';
import tema from './Estilos/temaPersonalizado';
import  CssBaseline  from '@material-ui/core/CssBaseline';
import './Estilos/index.css';
import UsuarioProvider from './contexts/usuarioProvider';
import {Provider} from 'react-redux';
import generateStore from './redux/store';


function App() {

  const store = generateStore();

  return (
    <Provider store = {store}>
    <UsuarioProvider>
    <ThemeProvider theme={tema}>
      <CssBaseline/>
        <Routes>
          <Route path="/" element = {  <Login/>}/>
          <Route path="login" element ={  <Login/>}/>
          <Route path="dashboard/*" element={ <Dashboard/>}/>
        </Routes>  
    </ThemeProvider>
    </UsuarioProvider>
    </Provider>
  );
}

export default App;

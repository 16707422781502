//Context o provider de usuario
import React from 'react';
import {auth,db} from '../backEnd/firebase';

//context que sera usado en componentes
export const UsuarioContext = React.createContext();

const UsuarioProvider = (props) => {

    const dataUsuarioInicial = {
        email:null, uid:null
    }

    //Variable de estado del context
    const[usuario,setUsuario] = React.useState(dataUsuarioInicial);

    React.useEffect(()=>{
        console.log("voy a detectar un usuario desde el provider")
       detectarUsuario()
    },[]);
/*
    const setUsuarioFirestoreData = async(email)=>{
        //vamos por la imagen
            let dataUsuario =  await db.collection('usuarios').doc(email).get();
            dataUsuario = dataUsuario.data();
            console.log(dataUsuario.imgUsuario);
            let  {imgUsuario} =  dataUsuario.imgUsuario;  
            console.log(JSON.stringify(dataUsuario));
            setUsuario({...usuario,'imgUsuario':imgUsuario,'sucursal':dataUsuario.sucursal});
            console.log("Que pedul " + usuario);
    }*/

    const detectarUsuario = async()=>{
        auth.onAuthStateChanged(async user=>{
            console.log(user + "user si es true")
            if(user){
                console.log("Cambio el state de auht")
                console.log(user);
                //Actualiza la data del usuario logeado del admin
                let dataUsuario = await db.collection('usuarios').doc(user.email).get();
                      dataUsuario = dataUsuario.data();

                user.getIdTokenResult()
                    .then(idTokenResult =>{
                        console.log(idTokenResult);
                          //dataUsuario = dataUsuario.data();
                        console.log("dataUsuario" + JSON.stringify(dataUsuario))  
                        let  {imgUsuario} =  dataUsuario//dataUsuario.imgUsuario;  
                        if(idTokenResult.claims.rol === 'admin'){
                            console.log('tiene el token con claim de admin es adimnistrador')
                            
                            
                            setUsuario({...usuario,
                                email:user.email,
                                uid:user.uid,
                                rol:'admin',
                                nombre:dataUsuario.nombre,
                                imgUsuario:imgUsuario,
                                sucursal:dataUsuario.sucursal,
                            })//.then(()=>{ console.log("funcion despues de logear admin")})
                         // .then(()=> {setUsuarioFirestoreData(user.email);});
                        }else if(idTokenResult.claims.rol === 'sucursal'){
                            console.log('tiene el Token con claims de sucursal es sucursal')
                            setUsuario({...usuario,
                                email:user.email,
                                uid:user.uid,
                                rol:'sucursal',
                                imgUsuario:imgUsuario,
                                sucursal:dataUsuario.sucursal
                            })
                         //setUsuarioFirestoreData(user.email);
                  
                        }else{
                            console.log('rol indefinido');
                        }
                        //setUsuarioFirestoreData(user.email);
                        
                    })//.then(()=> {setUsuarioFirestoreData(user.email);});
            }else{
                console.log('usuario no ha iniciado sesion')
                setUsuario({
                    email:null,
                    uid: null,
                    rol:null
                })
            }
        })
    }


    //Metodos de logeo
    const iniciarSesion = async(username,password) =>{
        
        try{
              const res = await auth.signInWithEmailAndPassword(username,password);
              const existe = await db.collection('usuarios').doc(res.user.email).get();
              console.log()
               setUsuario(existe.data());
              console.log("res de iniciarSesion" + JSON.stringify(res))
              //await setUsuarioFirestoreData(res.user.email);
              console.log("Si existe usuario")
            // detectarUsuario();
           console.log("El current user saliendo del login"+ res.user)
             return res.user;
        }catch(error){
            console.log("Error en el inicio de sesion" + error);
            return error;
        }

       
    } 

    const cerrarSesion = ()=>{
        
        auth.signOut();
       
    }

    return (
        <UsuarioContext.Provider value={{usuario, iniciarSesion,cerrarSesion}}> 
            {props.children}
        </UsuarioContext.Provider>
    )
}

export default UsuarioProvider

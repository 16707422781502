export default  [
    { value : "AGU", label: "[AGU]" },
    { value : "BCN", label: "[BCN]" },
    { value : "BCS", label: "[BCS]" },
    { value : "CHH", label: "[CHH]" },
    { value : "CHP", label: "[CHP]" },
    { value : "CAM", label: "[CAM]" },
    { value : "CMX", label: "[CMX]" },
    { value : "COA", label: "[COA]" },
    { value : "COL", label: "[COL]" },
    { value : "DUR", label: "[DUR]" },
    { value : "GRO", label: "[GRO]" },
    { value : "GUA", label: "[GUA]" },
    { value : "HID", label: "[HID]" },
    { value : "JAL", label: "[JAL]" },
    { value : "MIC", label: "[MIC]" },
    { value : "MEX", label: "[MEX]" },
    { value : "MOR", label: "[MOR]" },
    { value : "NAY", label: "[NAY]" },
    { value : "NLE", label: "[NLE]" },
    { value : "OAX", label: "[OAX]" },
    { value : "PUE", label: "[PUE]" },
    { value : "ROO", label: "[ROO]" },
    { value : "QUE", label: "[QUE]" },
    { value : "SIN", label: "[SIN]" },
    { value : "SLP", label: "[SLP]" },
    { value : "SON", label: "[SON]" },
    { value : "TAB", label: "[TAB]" },
    { value : "TLA", label: "[TLA]" },
    { value : "TAM", label: "[TAM]" },
    { value : "VER", label: "[VER]" },
    { value : "YUC", label: "[YUC]" },
    { value : "ZAC", label: "[ZAC]" } 
]
import React, { useEffect, useState, useCallback, createContext, useMemo, useRef } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import RootRef from '@material-ui/core/RootRef';
import Paper from '@material-ui/core/Paper';
import { useDropzone } from 'react-dropzone';
import Progress from '@material-ui/core/LinearProgress';
import { db, firebase, storage } from '../backEnd/firebase';
import Button from '@material-ui/core/Button';
import { UsuarioContext } from '../contexts/usuarioProvider';
import TiposDeIncidencias from '../assets/listaTiposDeIncidencias';
import TiposDeStatus from '../assets/listaTiposDeStatus';
import moment, { HTML5_FMT } from 'moment';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/CancelRounded';
import { useDispatch, useSelector } from 'react-redux';
import { subirIncidenciaAccion, obtenerChatAccion,postUserMensajeAction } from '../redux/incidenciasDucks';
import UpdateIcon from '@material-ui/icons/Sync';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';




//context que sera usado en componentes
export const ModalIncidenciasContext = createContext();

//Estilos de la tabla de usuarios {background: blue[200],
const estilos = makeStyles(theme => ({
  colores: {
    okBlue: theme.palette.primary
  },
  button: {
    margin: theme.spacing(3),
    marginRight: theme.spacing(6)
  },
  buttonModal: {
    margin: theme.spacing(1),
  },
  textoIcono: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    margin: 'inherit',
    color: 'blue'
  },
  tituloModalEliminar: {
    color: theme.palette.secondary.light
  },
  textoModalEliminar: {
    color: theme.palette.secondary.dark
  },
  icono: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    backgroundColor: 'yellow',
    color: '#fff',
    background: 'yellow',
    margin: '400'
  }, closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0),
  }, chatBoxMe: {
    background: theme.palette.primary.light,
    color: '#fff',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    textAlign: "left",
    borderRadius: "20px",
    textJustify: "auto",
    marginLeft: theme.spacing(6),
    overflowWrap:"break-word",  
    paddingLeft:theme.spacing(2)

  }, chatBoxOther: {
    background: theme.palette.primary.dark,
    color: '#fff',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    textAlign: "left",
    borderRadius: "20px",
    textJustify: "auto",
    marginRight: theme.spacing(6),
    overflowWrap:"break-word",
    paddingLeft:theme.spacing(2)

  }, buttonSendChat: {
    verticalAlign: "text-bottom",
  }
}));



const ModalIncidenciasProvider = (props) => {

  //Estilos de componente
  const classes = estilos();

  //Variables de Modal de eliminar Incidencia
  const [openEliminarIncidenciaModal, setopenEliminarIncidenciaModal] = React.useState(false);
  const handleClickopenEliminarIncidenciaModal = (incidencia) => { setopenEliminarIncidenciaModal(true); setSelectedIncidencia(incidencia) };
  const handleCloseEliminarIncidencia = () => { setopenEliminarIncidenciaModal(false); };

  //Variables de Modal Editar Sucursal
  const [openModalEditarIncidenciaModal, setOpenModalEditarIncidenciaModal] = React.useState(false);
  const handleClickOpenEditarIncidenciaModal = (incidencia) => { setnStatusPivote(incidencia.status); setOpenModalEditarIncidenciaModal(true); setSelectedIncidencia(incidencia); };
  const handleCloseEditarIncidencia = () => { setOpenModalEditarIncidenciaModal(false); };


  //Variables de Modal Añadir Incidencia 
  const [openModalAddIncidencia, setopenModalAddIncidencia] = React.useState(false);
  const handleClickOpenAddIncidenciaModal = (isopen) => { setopenModalAddIncidencia(isopen); };

  //seleccion de Incidencia
  const [selectedIncidencia, setSelectedIncidencia] = useState(null);

  //Inicializamos el context del usuario
  const { usuario } = React.useContext(UsuarioContext);

  //Variables y funciones de Selector lista de reproduccion
  const listaIncidencias = TiposDeIncidencias;
  const listaTiposDeStatus = TiposDeStatus;
  const [nStatusPivote, setnStatusPivote] = useState({});

  const ModalIncidencias = () => {
    // Variables de TextFields
    const [tipoProblema, setTipoProblema] = React.useState('');
    const handleChangetipoProblema = (event) => { setTipoProblema(event.target.value) ;};
    const [mensaje, setMensaje] = useState("");
    const handleChangeMensaje = (event) => { setMensaje(event.target.value) };

    //Variables y funciones de Selector lista de reproduccion
    const [URLContenido, setURLContenido] = React.useState('');

    const [nError, setnError] = useState(null);
    const [nOkResponse, setOkResponse] = useState(null);
    const [errorText, setErrorText] = useState(null);
    const [botonesEnabled, setbotonesEnabled] = useState(true);

    //Configuracion del drop zone 
    const fileMaxSize = 100000000;//100MB 
    const [loadingFbQuery, setloadingFbQuery] = useState(false);
    const [localURLcontenido, setlocalURLcontenido] = useState('');
    const [uploadPorcentaje, setUploadPorcentaje] = useState(0);
    const [updloadProgressText, setUploadProgressText] = useState('');

    const loading = useSelector(store => store.loading);
  //  const incidenciaSeleccionada = useSelector(store => store.sucursalseleccionada);
    const mensajes = useSelector(store => store.incidencias.chat);

    //variables para el update status
    const [status,setStatus] = useState(nStatusPivote)
    const [modoEdicionStatus, setModoEdicionStatus] = useState(false);
    const handleChangeStatus = (event) => { setStatus(event.target.value); console.log(event.target.value) };
    const [updatingStatus, setUpdatingStatus] = useState(false);
   
    const [nErrorEditStatus, setnErrorEditStatus] = useState(null);
    
    //console.log("color de textyo : " , _.find(listaTiposDeStatus,['status', "Por resolver"]))
    //let X = _.find(listaTiposDeStatus,['status', "Por resolver"])
   //const [colorTextoStatus,setTextoStatus] = useState(X.color);

    //Contenido nuevo
    const baseStyle = {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '10px',
      marginBottom: '0px',
      marginTop: '20px',
      marginRight: '10px',
      marginLeft: '10px',
      height: '120px',
      borderWidth: 4,
      borderRadius: 4,
      borderColor: 'rgba(51, 119, 170, 0.6)',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out',
      wordWrap: 'break-word'
    };
    const activeStyle = {
      borderColor: 'rgb(51, 119, 170)'
    };
    const acceptStyle = {
      borderColor: '#00e676'
    };
    const rejectStyle = {
      borderColor: '#ff1744'
    };


    const onDropeoRechazado = (res) => {
      console.log(res[0]);
      console.log(JSON.stringify(res[0]));
      if (res[0].errors[0].code == "file-too-large") {
        setnError("El archivo es demasiado largo, debe ser menor de " + fileMaxSize / 100000000 + " MB");
      }
      else if (res[0].errors[0].code == "file-invalid-type") {
        setnError("Introduce un archivo valido");
      }
      else {
        setnError(res[0].errors[0].code);
      }
    }

    const onDropeoAceptado = res => {
      console.log(JSON.stringify(res));
      console.log("archivo aceptado");
      setnError("");
      //Hay que setear la url del archivo y quiza un preview o un algo que indicque que se tiene listo para subir
      setlocalURLcontenido(res[0].path);

      setURLContenido(res);
    }

    //Hook del drop zone
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject
    } = useDropzone({
      maxSize: fileMaxSize, multiple: false,
      onDropRejected: onDropeoRechazado, onDropAccepted: onDropeoAceptado
    });
    //Estilo del dropZone
    const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isDragActive,
      isDragReject,
      isDragAccept
    ]);
    const { ref, ...rootProps } = getRootProps({ style })
    //Upload Handler
    const optionsUploader = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor(loaded * 100 / total)
        setUploadProgressText(`${(loaded / 1000000).toFixed(2)}Mb de ${(total / 1000000).toFixed(2)}Mb | ${percent}%`)
        console.log(`${loaded.toFixed(2)}kbof ${total.toFixed(2)}kb | ${percent}%`)

        if (percent < 100) {
          setUploadPorcentaje(percent);
        }
      }
    }

    //Notacion de Redux en documentacion
    const dispatch = useDispatch()

    const postMensaje = () =>{
      dispatch(postUserMensajeAction(mensaje,selectedIncidencia.id,usuario.uid,usuario.nombre));
      setMensaje("");
      
      
    }

    const addIncidencia = () => {

      setbotonesEnabled(false);
      setloadingFbQuery(true);

      if (tipoProblema == '') {
        console.log("tipo de problema vacioo")
        setnError("Selecciona un tipo de problema");
        setbotonesEnabled(true);
        setloadingFbQuery(false);

        return
      }
      if (!mensaje.trim()) {
        console.log("Mensaje vacioo")
        setnError("Escribe una breve descripción");
        setbotonesEnabled(true);
        setloadingFbQuery(false);
        return
      }
      if (!mensaje.trim()) {
        console.log("Mensaje vacioo")
        setnError("Escribe una breve descripción");
        setbotonesEnabled(true);
        setloadingFbQuery(false);
        return
      }

      if (!localURLcontenido.trim()) {
        console.log("Imagen vacia")
        setnError("Sube una foto del problema");
        setbotonesEnabled(true);
        setloadingFbQuery(false);
        return
      }

      setnError(null);
      fbAddIncidencia();
    }

    const fbAddIncidencia = async () => {

      console.log("Vamos a comunicarnos con firestore")

      dispatch(subirIncidenciaAccion({

        nombre: usuario.nombre,
        sucursal: usuario.sucursal,
        uid: usuario.uid,
        tipoDeProblema: tipoProblema,
        status: "Por resolver",
        fecha: firebase.firestore.FieldValue.serverTimestamp()
      }, URLContenido, mensaje));

      handleClickOpenAddIncidenciaModal(false)
      setbotonesEnabled(true);
      setloadingFbQuery(false);

      /*
            db.collection('incidencias').add({
              nombre: usuario.nombre,
              sucursal: usuario.sucursal,
              //URLfoto: 'https://suzukiv2.sangsang.mx/ArchivosDocumentos/uploads/' + dato[0].path,
              tipoDeProblema: tipoProblema,
              chat: mensaje,
              status: "por resolver",
              fecha: firebase.firestore.FieldValue.serverTimestamp()
            }).then((res) => {
              console.log(res)
              // setOkResponse("Video agregado " + res);
              handleClickOpenAddIncidenciaModal(false)
              setbotonesEnabled(true);
              setloadingFbQuery(false);
              // setCerrarClickBack(false);
      
      
            }).catch(e => {
              console.error(e);
              setnError(e);
              setbotonesEnabled(true);
              setloadingFbQuery(false);
              //setCerrarClickBack(false);
            });
      */


      /*
           setCerrarClickBack(true);
           let data = new FormData();
           let jeders = { headers: { 'Content-Type': 'multipart/form-data' } };
           let dato = URLContenido;
           data.append("archivo", dato[0]);
           axios.post('https://suzukiv2.sangsang.mx/ArchivosDocumentos/index2.php', data, optionsUploader, jeders).
               then(res => {
                   console.log(res);
                   if (res.data === "hubo un error en el lado del Servidor") {
                       setnError("Video NO subido, " + res.data);
                       setloadingFbQuery(false);
                       setbotonesEnabled(true);
                   }
     
     
                   setUploadPorcentaje(100, setTimeout(() => {
                       if (res.data === "El archivo " + dato[0].path + " ha sido subido.") {
                           setOkResponse("El archivo " + dato[0].path + " ha sido subido.")
                       }
                       setloadingFbQuery(false);
                       setbotonesEnabled(true);
                       setUploadPorcentaje(0);
                   }, 1000));
     
     
               }).then(() => {
     
                   console.log("Vamos a comunicarnos con firestore")
                   db.collection('documentos').add({
                       nombre: archivoNombre,
                       URLContenido: 'https://suzukiv2.sangsang.mx/ArchivosDocumentos/uploads/' + dato[0].path,
                       tipo:"archivo",
                       fecha: firebase.firestore.FieldValue.serverTimestamp()
                   }).then((res) => {
                       console.log(res)
                       // setOkResponse("Video agregado " + res);
                       ocultarModalAddFile();
                       setbotonesEnabled(true);
                       setloadingFbQuery(false);
                       setCerrarClickBack(false);
                       
     
                   }).catch(e => {
                       console.error(e);
                       setnError(e);
                       setbotonesEnabled(true);
                       setloadingFbQuery(false);
                       setCerrarClickBack(false);
                   });
               })*/
    }

    const eliminarIncidencia = async (id) => {

      // let batch = db.batch();
      //batch.delete(db.collection('incidencias').doc(id));
      //batch.delete(storage.ref("imgIncidencias").child(id).child("fotoIncidencia"));

      //db.runTransaction((trans)=>{

      await storage.ref("imgIncidencias").child(id).child("fotoIncidencia").delete();
      await db.collection('incidencias').doc(id).delete();
      //trans.delete(db.collection('incidencias').doc(id));
      //trans.delete(storage.ref("imgIncidencias").child(id).);
      // https://stackoverflow.com/questions/37749647/firebasestorage-how-to-delete-directory

      //}).then(()=>{
      handleCloseEliminarIncidencia();

      //}).catch(err=>{
      // console.error(err);
      //});
      /*
            batch.commit().then((res)=>{
              console.log('Incidencia eliminada de Incidencias ' + res)
             // await storage.ref("imgIncidencias").child(id).delete();
              handleCloseEliminarIncidencia();
            }).catch(e=>{ 
              console.log(e);
            })*/

    };

    const refZonaChat = useRef({});

    useEffect(()=>{
      console.log("me active")
   
      console.log("referencia",refZonaChat);
      console.log("referencia" + refZonaChat.current.scrollTop +  "  ddd " + refZonaChat.current.scrollHeight);
      refZonaChat.current.scrollTop = refZonaChat.current.scrollHeight;
    },[mensajes])



    const procesarUpdateStatus = async (id) => {
      setUpdatingStatus(true);
//updateamos la referencia de la url
await db.collection('incidencias').doc(id).update({
  status: status
})

setUpdatingStatus(false);
setModoEdicionStatus(false);

    }

    return (<>
      <>{/*MODAL ELIMINAR Usuario */}
        <Dialog open={openEliminarIncidenciaModal} onClose={() => handleCloseEliminarIncidencia()}
          aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle className={classes.tituloModalEliminar} id="alert-dialog-title">
            {"¿Desea eliminar la incidencia de la sucursal: " +
              (selectedIncidencia && selectedIncidencia.sucursal.nombre) +
              " con fecha: " + moment.unix(selectedIncidencia && selectedIncidencia.fecha.seconds).format("DD/MM/YYYY")}</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.textoModalEliminar} id="alert-dialog-description">
              Si se borra la incidencia NO podra ser recuperada y todos los datos de analiticos seran tambien eliminados.
</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => handleCloseEliminarIncidencia()}>
              Cancelar
</Button>
            <Button variant="contained" color="secondary" onClick={() => { eliminarIncidencia(selectedIncidencia.id) }} autoFocus>
              Borrar
</Button>
          </DialogActions>
        </Dialog></>
      <>{/*MODAL EDITAR Incidencia */}
        <form className={classes.root} noValidate autoComplete="off">
          <Dialog maxWidth='sm' fullWidth open={openModalEditarIncidenciaModal} onClose={() => handleCloseEditarIncidencia()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Incidencia 
                    <Typography variant="body2" color="primary">Fecha de Incidencia {selectedIncidencia && moment.unix(selectedIncidencia.fecha.seconds).
                      format("MM/DD/YYYY , h:mm:ss a")}</Typography><IconButton className={classes.closeButton} variant='contained'
              onClick={() => {handleCloseEditarIncidencia();}} color="secondary">
              <CancelIcon fontSize='large' />
            </IconButton> </DialogTitle>
            <Divider />
            <Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-start">
              <Grid item xs={6} sm={3}>
                <DialogContent >
                  <DialogContentText className={classes.root} pt='1'>
                    Sucursal
                    <Typography variant="body2" color="primary">{usuario.sucursal && usuario.sucursal.nombre}</Typography>
                  </DialogContentText>
                  <DialogContentText className={classes.root} pt='1'>
                    Encargado 
                    <Typography variant="body2" color="primary">{usuario.nombre && usuario.nombre}</Typography>
                  </DialogContentText>
                  <DialogContentText className={classes.root} pt='1'>
                    
                  </DialogContentText>
                </DialogContent>
              </Grid>
              <Grid item xs={6} sm={4}>
                <DialogContent >
                  
                  <DialogContentText className={classes.root} pt='1'>
                    Problema
                    <Typography variant="body2" color="primary">{selectedIncidencia && selectedIncidencia.tipoDeProblema}</Typography>
                  </DialogContentText>
                  <DialogContentText className={classes.root} pt='1'>
                    Estatus
                    {usuario.rol === 'admin' ? <> {modoEdicionStatus ?
                    <><Box display="block" alignItems="center" p={1} m={0}>
                      <TextField id="electordeStatus" size='small' select margin="none" label="selecciona" variant="outlined" helperText="Status"
                       // value={selectedIncidencia.status}
                        defaultValue={selectedIncidencia.status}
                        onChange={handleChangeStatus}
                      >
                        {listaTiposDeStatus.map((option) => (
                          <MenuItem key={option.value} value={option.value} >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Box pl={2}>
                        {updatingStatus ?
                          <CircularProgress />
                          : <>
                            <IconButton pl={2} color="primary" size="medium" onClick={() => procesarUpdateStatus(selectedIncidencia.id)}><UpdateIcon fontSize='large' /></IconButton>
                            <IconButton color="secondary" size="medium" onClick={() => { setModoEdicionStatus(false); setnErrorEditStatus(null); setStatus(selectedIncidencia.status) }}>
                              <CancelIcon fontSize='large' /></IconButton>
                          </>}  </Box></Box>
                      {setnErrorEditStatus && (
                        <Box display="flex" alignItems="center" p={1} m={0}>
                          <Typography variant="subtitle1" align="center" color="error" children={setnErrorEditStatus} />
                        </Box>)}
                    </>
                    : <>
                      <Box display="flex" alignItems="center" p={1} m={0}>
                      {status && ( 
                        <Typography display="inline" margin="dense" variant="subtitle1" align="left" 
                     
                          color= {'primary'}/*{ ()=>{
                                   // let x = _.find(listaTiposDeStatus,{'value' : selectedIncidencia.status});
                                    console.log("color de textop" );//,x)
                                    //return x.color;
                                    return "primary";
                                  }}*/
                       
                        children={status} />)
                       }
                        <IconButton pl={2} color="primary" aria-label="editar--Status" component="span"
                          onClick={() => {setModoEdicionStatus(true); }  }>
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </>}</>  : <>{
                    status && (           
                                          <Typography variant="body2" 
                                                   // color={(_.find(listaTiposDeStatus,{'value' : selectedIncidencia.status})).color}>
                                                   /*
                                                   color={ ()=>{
                                                    // let x = _.find(listaTiposDeStatus,{'value' : selectedIncidencia.status});
                                                     console.log("color de textop" );//,x)
                                                     //return x.color;
                                                     return "primary"
                                                   }}*/
                                                   color= {'primary'}
                                                   >
                                                   {status}
                                          </Typography> )
                              }</>}
                  </DialogContentText>
                </DialogContent>
              </Grid>
              <Grid item xs={6} sm={5}>
                <DialogContent >
                  <Box><img src={selectedIncidencia && selectedIncidencia.photoURL} style={{ maxWidth:'100%',maxHeight:'200px'}} />
                  </Box><DialogContentText className={classes.root} pt='1'>
                    Imagen del caso
                  </DialogContentText>
                </DialogContent>
              </Grid>
            </Grid>
            <Divider />
            <DialogContent ref={refZonaChat} style={{ backgroundColor:'#E1E2E1'}}  >
              <Grid container direction="column" wrap="wrap"style={{display:'block'}} >
                { mensajes.map((item, index) => (
                  (usuario.uid === item.uid) ? (
                    <Grid item  key={index} className={classes.chatBoxMe}>
                      <Grid item style={{ textAlign: "Right" , paddingRight:"10px"}}>
                        <Typography alignItems="flex-end" variant="caption" >{(item.nombre +
                          " (" + moment.unix(item.fecha && item.fecha.seconds).
                            format("MM/DD/YYYY , h:mm:ss a") + ")")}</Typography>
                      </Grid>
                      <Typography variant="body2">{item.texto}</Typography>
                    </Grid>
                  ) : (
                      <Grid item key={index} className={classes.chatBoxOther}>
                        <Typography variant="caption" >
                          {(item.nombre +
                          " (" + moment.unix(item.fecha && item.fecha.seconds).
                            format("MM/DD/YYYY , h:mm:ss a") + ")")}</Typography>
                        <Typography variant="body2" >{item.texto}</Typography>
                      </Grid>)  
                ))}
              </Grid>
            </DialogContent>

            <Divider />
            <Box mt={2}>
              <DialogActions >
                <Grid container direction="row" justify="flex-start" alignItems="flex-end"   >
                  <Grid item xs={10} sm={10}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Escribe un mensaje"
                      multiline
                      rows={1}
                      rowsMax={3}
                      size="small"
                      value={mensaje}
                      variant="outlined"
                      onChange= {handleChangeMensaje}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <Button variant='contained' fullWidth onClick={() => {postMensaje(); }} color="primary" 
                    disabled={!mensaje} className={classes.buttonSendChat}>
                      Enviar
              </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Box>
          </Dialog>
        </form></>
      <>{/*MODAL AÑADIR Incidencia */}
        <form id="formSucursal" className={classes.root} noValidate autoComplete="off">
          <Dialog maxWidth='sm' fullWidth open={openModalAddIncidencia} onClose={() => handleClickOpenAddIncidenciaModal(false)}
           aria-labelledby="form-dialog-title">
            <DialogTitle id="formAddSucursalTitulo">Añade una Incidencia</DialogTitle>
            <Divider />
            <Grid container spacing={0} direction="row" justify="space-around" alignItems="center">
              <Grid item xs={12} sm={6}>
                <DialogContent >

                  <DialogContentText className={classes.root} pt='1'>
                    Sucursal
                    <Typography variant="body2" color="primary">{usuario.sucursal && usuario.sucursal.nombre}</Typography>
                  </DialogContentText>

                  <DialogContentText className={classes.root} pt='1'>
                    Encargado de incidencia
                    <Typography variant="body2" color="primary">{usuario.nombre && usuario.nombre}</Typography>
                  </DialogContentText>


                </DialogContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DialogContent >
                  <DialogContentText>Elige una problematica </DialogContentText>
                  <TextField
                    id="electorDeproblematica"
                    select
                    label="problema frecuente"
                    value={tipoProblema}
                    onChange={handleChangetipoProblema}
                    helperText="Selecciona un problema común"
                    variant="outlined">
                    {listaIncidencias.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </DialogContent>

              </Grid>
              <Divider />
            </Grid>
            <Divider />
            <Grid container spacing={0} direction="row" justify="space-evenly" alignItems="center">
              <Grid item xs={12} sm={8}>
                <DialogContent>
                  <DialogContentText>
                    Describe el problema:
                   </DialogContentText>
                  <TextField fullWidth
                    id="outlined-multiline-static"
                    label="Detalles de incidencia"
                    multiline
                    rows={4}
                    value={mensaje}
                    onChange={handleChangeMensaje}
                    defaultValue="Default Value"
                    variant="outlined"
                  />
                </DialogContent>
              </Grid>
              <Grid item xs={12} sm={4}>

                <RootRef rootRef={ref}>
                  <Paper {...rootProps}>  <>
                    <input {...getInputProps()} />

                    {localURLcontenido ? <p>{localURLcontenido}</p> : <p>Arrastra o da click para añadir un archivo</p>}
                    {uploadPorcentaje === 0 ? <></> : <>
                      <Box alignItems="flex-start">
                        <Box width="100%" mr={1} p={0}>
                          <LinearProgress variant="determinate" value={uploadPorcentaje} />
                        </Box>
                        <Box minWidth={35}>
                          <Typography variant="body2" color="textSecondary">{updloadProgressText}</Typography>
                        </Box>
                      </Box> </>}
                  </>
                  </Paper>
                </RootRef>
              </Grid>


            </Grid>
            {nError && (<Typography variant="subtitle1" align="center" color="error" children={nError} />)}
            {nOkResponse && (<Typography variant="subtitle1" align="center" color="primary" children={nOkResponse} />)}
            {loading && (<Progress />)}
            <Divider className={classes.dividerHorizontal} />
            <DialogActions>
              <Button className={classes.buttonModal} variant='contained' onClick={() => handleClickOpenAddIncidenciaModal(false)} color="secondary" disabled={!botonesEnabled}>
                Cancelar
     </Button>
              <Button className={classes.buttonModal} variant='contained' onClick={addIncidencia} color="primary" disabled={!botonesEnabled}>
                Añadir Incidencia
     </Button>
            </DialogActions>
          </Dialog>
        </form></></>);
  }
  return (
    <ModalIncidenciasContext.Provider value={{
      handleClickopenEliminarIncidenciaModal, handleClickOpenEditarIncidenciaModal,
      handleClickOpenAddIncidenciaModal, ModalIncidencias
    }}>
      {props.children}
    </ModalIncidenciasContext.Provider>
  )

};


export default ModalIncidenciasProvider


import {db,storage,firebase} from '../backEnd/firebase'

//constantes (constante para jalar la data de otro lado)
const dataInicialIncidencias = {
    array:[], //
    urlImageIncidencia:"",
    loading:false,
    chat:[]
}



// TYPES ACTIONS
const GET_INCIDENCIAS_EXITOSAS = "OBTENER_INCIDENCIAS_OK";
const GET_INCIDENCIAS_ERROR = "OBTENER_INCIDENCIAS_ERROR";
const GET_CHAT_OK = "OBTENER_CHAT_OK";

//REDUCER (reduce a una constante los datos)
//Toman un state anterior y devuelven uno nuevo
export default function incidenciasReducer(state = dataInicialIncidencias, action){
    

    switch(action.type){
            case  GET_INCIDENCIAS_EXITOSAS:
            return {...state, array: action.payload};

            case GET_CHAT_OK:
              return {...state, chat: action.payload};

            default:
              return state;

    }
}


//actions (aqui se cargan los datos) funcion de flecha que devuelve otra funcion de flecha
export const obtenerIncidenciasAccion = (usuario) => async (dispatch,getState) =>{

  //este getState es el que viene de la store
  // const array =  getSate().incidencias.array
  // const {array} = getState().incidencias
    console.log("get state" , getState().incidencias.array)
    console.log("USUARIO EN DUCKS INCIDENCIAS", usuario)
if(usuario.rol === "admin"){
  console.log("soy admin")
    try {
        db.collection('incidencias').onSnapshot(dataIncidencias => {
        console.log(dataIncidencias.docs);
        console.log("estoy fetcheando los documentos")
        console.log(dataIncidencias);
        console.log("existye" + dataIncidencias.empty)
        console.log(dataIncidencias.docs.exists);


        //convertimos el objeto de fb a un array para el estado
        //if (!dataIncidencias.empty) {
          console.log("existe la ruta y se obtuvieron estas incidencias")
          const arrayIncidencias = dataIncidencias.docs.map(doc => ({ id: doc.id,...doc.data() }));
          console.log(arrayIncidencias);
          //Envio
          dispatch({
              type: GET_INCIDENCIAS_EXITOSAS,
              payload: arrayIncidencias
          })
        //setIncidencias(arrayIncidencias);
        //} else {
          //console.log("no exciste la ruta")
          //setIncidencias([]);
        //}

      });
    }
    catch (error) {
      console.log("error en fetching Incidencias" + error);
      //setIncidencias([]);
    }
  }else if (usuario.rol === "sucursal"){
    console.log("soy sucursal")
    try {
      db.collection('incidencias').where("uid", "==", usuario.uid).onSnapshot(dataIncidencias => {

       console.log("uwu")
      //convertimos el objeto de fb a un array para el estado
      //if (!dataIncidencias.empty) {
        console.log("existe la ruta y se obtuvieron estas incidencias")
        const arrayIncidencias = dataIncidencias.docs.map(doc => ({ id: doc.id,...doc.data() }));
        console.log(arrayIncidencias);
        //Envio
        dispatch({
            type: GET_INCIDENCIAS_EXITOSAS,
            payload: arrayIncidencias
        })
    });
  }
  catch (error) {
    console.log("error en fetching Incidencias" + error);
    //setIncidencias([]);
  }
  }else{

  }

}

export const obtenerChatAccion = (id) => async (dispatch,getState) =>{

  db.collection('incidencias').doc(id).collection("chat").orderBy('fecha').onSnapshot(dataIncidencias =>{
  const mensajes = dataIncidencias.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  dispatch({
    type: GET_CHAT_OK,
    payload: mensajes
    })
  })


}

export const subirIncidenciaAccion = (jsonIncidencia,fotoblob,msj) => async(dispatch,getState)=>{

  
try {

  //Añadimos a la base de datos la data
   const res = await db.collection('incidencias').add(jsonIncidencia);

  //obtenemos la imagen url y la subimos a un hijo
   const imgRef =  storage.ref("imgIncidencias").child(res.id).child('fotoIncidencia');
   await imgRef.put(fotoblob[0]);
   const imgURL = await imgRef.getDownloadURL();

   //updateamos la referencia de la url
   await db.collection('incidencias').doc(res.id).update({
     photoURL: imgURL
   })

   //Añadimos datos al chat
   await db.collection('incidencias').doc(res.id).collection('chat').add({
    uid: jsonIncidencia.uid,
    texto: msj,
    fecha: jsonIncidencia.fecha,
    nombre: jsonIncidencia.nombre
 });




}catch(e){
  console.log("El error en el add de img es: ", e)
}


}

export const postUserMensajeAction =(mensaje,selectedId,userId,nombreX) => async(dispatch,getState) =>{

 
  await db.collection('incidencias').doc(selectedId).collection("chat").add({
    fecha:  firebase.firestore.FieldValue.serverTimestamp(),
    texto: mensaje,
    uid: userId,
    nombre: nombreX
  })

  //Envio
  /*dispatch({
    type: GET_INCIDENCIAS_EXITOSAS,
    payload: arrayIncidencias
})*/

}
import React,{useEffect,useState,useCallback, Fragment} from 'react';
import {GoogleMap,LoadScript, useLoadScript} from '@react-google-maps/api';
import credencial from '../assets/credenciales';
import Container  from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import tema from '../Estilos/temaPersonalizado';
import InfoMarker from '../Componentes/infoMarker';
import {db, auth, functions} from '../backEnd/firebase';
import Progress from '@material-ui/core/LinearProgress';


const containerStyle2 = makeStyles(tema => ({
   
  mapaGoogleMaps:{
    width:'100%',
    height:'75vh',
    borderStyle: 'outset',
    borderRadius: '16px',
    borderColor: tema.palette.primary.light 
}}));

const  containerStyle = {
  width:'100%',
  height:'75vh',
  borderStyle: 'outset',
  borderRadius: '16px',
  borderColor: '#005595',
 
}



const Mapa = () =>{

    const classes = containerStyle2();
    const {isLoaded,loadError} = useLoadScript({
        
      googleMapsApiKey:credencial.mapsllave,
      
    });
    const [sucursales,setSucursales]= useState([]);

  const obtenerSucursales = async()=>{
      try{ 
        const dataSucursales = await db.collection('sucursales').get();
        console.log(dataSucursales.docs);
        //convertimos el objeto de fb a un array para el estado
      const arraySucursales = dataSucursales.docs.map(doc =>({id:doc.id,... doc.data()}));
        console.log(arraySucursales);
        setSucursales(arraySucursales);
      }
      catch (error){
        console.log(error);
      }
    }

    useEffect(()=>{
      //llamamos la funcion del efecto
      obtenerSucursales();
       },[]);

   
   
    return (
        <Container  maxWidth='xl' >
              <Typography paragraph style={{ paddingTop:'0.5em'}}variant="h4" color ="textPrimary"align='center'>
                  Conexiones Suzuki Digital Signage en concesionarias de México
                </Typography>
                {
                  loadError ?
                      <Typography paragraph style={{ paddingTop:'0.5em'}}variant="h4" color ="textSecondary"align='center'>
                           Error al carga el mapa
                    </Typography>
                
                :         
                isLoaded ? <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={{lat: 23.910,  lng: -101.792}}
                            zoom={5}> 
                           { sucursales.map((dato)=>{
                            //console.log(dato)
                            return(
                            <InfoMarker key = {dato.id} dataX={dato} />)
                            })} </GoogleMap> : <Progress/>}
        </Container> 
    )
}


export default React.memo(Mapa)
import React,{useEffect,useState,createContext}from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {db} from '../backEnd/firebase';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 350,
    height: 330,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a,b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}


//context que sera usado en componentes
export const TransferListContext = createContext();

const TransferList =  (props) => {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

const handleAllRight = ()=>  {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
  setLeft(left.concat(right));
   setRight([])
  };
  

 //Declaramos la funcion del efecto para hacer el fetch de los contenidos disponibles
 const obtenerListaDeContenidos = async()=>{
  try{ 
   db.collection('contenidos').onSnapshot(dataListaDeContenidos =>{
   console.log(dataListaDeContenidos.docs);
   //convertimos el objeto de fb a un array para el estado
   const arrayListaDeContenidos = dataListaDeContenidos.docs.map(doc =>({id:doc.id,... doc.data()}));
  // console.log(arrayListaDeContenidos);
   if(props.listaSeleccionada === undefined){
   setLeft(arrayListaDeContenidos);
    }
    else{
      setRight(props.listaSeleccionada.listaDeVideos)
      props.callBackListaAComparar(props.listaSeleccionada.listaDeVideos);
      //console.log("ArrayListaDeContenidos_: " + JSON.stringify(arrayListaDeContenidos));
      //console.log("propslistaSeleccionadaListadeVIdeos_: "  + JSON.stringify(props.listaSeleccionada.listaDeVideos))
     setLeft(_.differenceWith(arrayListaDeContenidos,props.listaSeleccionada.listaDeVideos,_.isEqual));
    }
   })
  }
  catch (error){
    console.log(error);
  }
}

//Efectos del componente
 useEffect(()=>{
    //llamamos la funcion del efecto
    obtenerListaDeContenidos();
    console.log("obtener lista de contenidos")
 },[]);

 useEffect(()=>{
  //console.log("cambio la variable right comunicamos al padre");
  props.callBackTransferList(right)
 },[right])


  const customList = (data) => (
    <Paper elevation ={2}className={classes.paper}>
      <List dense component="div" role="list">
        {data.map((value) => {
          const labelId = `transfer-list-item-${value.nombre}-label`;

          return (
            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': value.id }}
                />
              </ListItemIcon>
              <ListItemText id={value.id} primary={`video: ${value.nombre }`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
 
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={(e)=>{handleAllRight()}}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={()=>{handleCheckedRight()}}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={()=>{handleCheckedLeft()}}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={()=>{handleAllLeft()}}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
 
  )
}




export default TransferList; 
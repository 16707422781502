import React ,{useState}from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LogoSuzuki from '../assets/suzuki.svg';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Navigate, useNavigate} from 'react-router-dom';
import Progress from '@material-ui/core/LinearProgress';
import {UsuarioContext} from '../contexts/usuarioProvider';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    suzukiLogo: {
      marginBottom: theme.spacing(3),
      height:100,
      width:'100%'
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(8),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        {'Suzuki Motor de México '} 
        { new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const Login = () => {
    const classes = useStyles();

    const [userName, setUserName] = useState('');
    const [password,setPassword] = useState('');
    const [error,setError] = useState(null);
    const [loadingLogin, setLoadingLogin] = useState(false);
    let navigate = useNavigate();

    //context del usuario
    const {iniciarSesion} = React.useContext(UsuarioContext);

    const LoginSubmit = e =>{
      e.preventDefault();
       if(!userName.trim()){
        console.log('ingresa un usuario');
        setError('Ingresa un usuario');
        return
      }
      if(!password.trim()){
        console.log('ingresa una Password');
        setError('Ingresa una contraseña');
        return
      }
      setError(null);
      loginFb();    
    }
    
    const loginFb = React.useCallback(async () => {
      
      try{
          setLoadingLogin(true);
          const res = await iniciarSesion(userName,password);
          console.log("resúesta del login" + JSON.stringify(res.uid));
          //Limpiamos el Formulario
          setUserName('');
          setPassword('');
          setError(null);
          setLoadingLogin(false);
         console.log("vamos a una parte loca que no se que pasa con el res")
         if(res.code ){
            if(res.code === 'auth/invalid-email'){
              setError("Usuario incorrecto");
              return
            }
            if(res.code === 'auth/user-not-found'){
              setError("Usuario no encontrado");
              return              
            }
            if(res.code === 'auth/wrong-password'){
              setError("Contraseña incorrecta");
              return
            }else{
              setError( "Error :" + res.code);
              return
            }
          }
           console.log("voy a navegar al dashboard")
            navigate('/dashboard');  //otra opcion para navegar ... return (<Redirect to="/dashboard/" />)
     

      }catch(err){
          console.error("Error" + err);
          
          if(err.code === 'auth/invalid-email'){
            setError("catch Usuario incorrecto");
          }
          if(err.code === 'auth/user-not-found'){
            setError("catch Usuario no encontrado");
          }
          if(err.code === 'auth/wrong-password'){
            setError(" catch Contraseña incorrecta");
          }else{
            setError("Catch " + err);
          }
          setLoadingLogin(false);
      }
    },[userName,password]);


    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>        
        <img src={LogoSuzuki} className={classes.suzukiLogo} alt="logoSuzuki"/>       
          <Typography component="h1" variant="h5">
            Suzuki Digital Signage
          </Typography>
          <form className={classes.form} noValidate onSubmit={LoginSubmit}>
            {
              error && ( <Typography  variant="subtitle1" align="center" color="error" children={error}/> )
            }
            {
              loadingLogin && ( <Progress/>)
            }
            <TextField
              variant="outlined" margin="normal"
              required fullWidth
              id="usuario"
              label="Usuario"
              name="usuario"
              onChange = { e=> setUserName(e.target.value)}
              value = {userName}
              autoComplete="usuario"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              onChange = { e=> setPassword(e.target.value)}
              value = {password}
              autoComplete="current-password"
            />{/*
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recordarme"
            />*/}
           < Link  href="/dashboard/" >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Ingresar
            
            </Button>  </Link>
            {/*<Grid container>
              <Grid item xs>
                <Link href="/dashboard" variant="body2">
                  ¿Olvidaste tu password?
                </Link>
              </Grid>
            </Grid>*/}
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
     { /*{userName ?<Navigate to="dashboard" />:<></>} */}
      </Container>
      
    );
}

export default Login

import React, {useRef, useEffect/*Dibuja todo antes*/, useState} from 'react';
import {select, line, curveCardinal, axisBottom, scaleLinear, axisRight, scaleBand} from "d3";
import '../Estilos/grafics.css';
import Typography from '@material-ui/core/Typography';


const useResizeObserver= ref =>{
  const [dimensions, setDimensions]= useState(null);
  useEffect(()=>{
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries)=>{
      entries.forEach(entry => {
        setDimensions(entry.contentRect);
      })
    });
    resizeObserver.observe(observeTarget);
    return () =>{
      resizeObserver.unobserve(observeTarget);
    }; 
  }, [ref]); 
  return dimensions;
};

function Graficas(props) {

  const [data, setData] = useState([2, 12, 2, 4, 20, 21, 5, 4, 2,12, 14, 14, 6, 12, 14, 16, 12, 13, 11, 14, 5, 13, 14, 3, 3, 12, 12, 12, 4, 5, 12 ]);
  const [hora, SetData] = useState([0,  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]);
  const svgRef = useRef();
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);
  //puede ser llamado cada vez que la información cambie
  useEffect(() =>{
    const svg = select(svgRef.current);
    if(!dimensions) return;
    const xScale = scaleBand().domain(data.map((value, index) => index)).range([0, 600]).padding(0.2);
    const yScale = scaleLinear().domain([0, 24]).range([300, 0]);
    const colorScale = scaleLinear().domain([0, 300]).range([props.color1, props.color2]);

    const xAxis = axisBottom(xScale).ticks(data.length);
    const yAxis = axisRight(yScale).ticks(hora.length);
    svg.select(".x-axis").style("transform", `translateY(300px)`).call(xAxis);
    svg.select(".y-axis").style("transform", `translateX(600px)`).call(yAxis);
    svg.selectAll(".bar").data(data).join("rect").attr("class", "bar").attr("fill", colorScale).style("transform", "scale(1, -1)").attr("x", (value, index) => xScale(index)).attr("y", -300).attr("width",xScale.bandwidth()).on("mouseenter", (value, index)=>{svg.selectAll(".tooltip").data([value]).join(enter => enter.append("text").attr("y", yScale(value)-4)).attr("class", "tooltip").text(value).attr("x", xScale(index)+ xScale.bandwidth() / 2).attr("text-anchor", "middle").transition().attr("y", yScale(value)-8).attr("opacity", 1);}).on("mouseleave", () => svg.select(".tooltip").remove()).transition().attr("height", value => 300 - yScale(value));

}, [data, dimensions]);

  return (
    <div ref={wrapperRef} style={{marginBottom: "2rem"}}>
      <Typography variant="h6" color="textSecondary" align="left">Conexión a digital signage</Typography>
        <svg ref = {svgRef}  width="500" height="300" >
          <g className="x-axis" />
          <g className="y-axis" />
        </svg>
   </div>
  );
}
export default Graficas;

import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import imgSuzuki2 from '../assets/suzukiDS2.png';
import imgSuzuki1 from '../assets/suzukiDS1.png';
import LogoSuzuki from '../assets/suzukiDSlogo.svg';
import LogoSangsang from '../assets/sangsangLogo.svg';
import Box from '@material-ui/core/Box';



const useStyles = makeStyles(theme =>({
    root: {
      flexGrow: 1,
    }, button:{
      margin: theme.spacing(1),
      marginRight: theme.spacing(1)
  }, column: {
      flexBasis: '33.33%',
    },
    dividerVertical:{
      marginRight: theme.spacing(5),
      marginLeft: theme.spacing(3)
    },  
     dividerHorizontal:{
         marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
      },
     paper:{ 
         padding: theme.spacing(2)},
    imagen:{
        maxWidth:'400px',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2)
    }
  }));

function Informacion() {
    
    const estilos = useStyles();

    return (
            <Container maxWidth='xl'>
                <Typography color='textSecondary' variant="h5">Información</Typography>
                <Divider style ={{marginBottom:'40px'}}/>
                    
                        <Grid container direction="row" justify="space-around" alignItems ="flex-start">
                        
                            <Grid item  xs={12} sm={5}>
                            <Paper className={estilos.paper} elevation={3}>
                                <img src ={LogoSuzuki} className={estilos.logoSuzuki} alt="logoSuzuki"/>
                              <Divider className={estilos.dividerHorizontal}/>  
                            <Typography variant ='h5' color="primary" gutterBottom >Suzuki Digital Signage</Typography>
                            <Typography variant ='body1' color="TextSecondary">Esta aplicación tiene como objetivo administrar el contenido digital de Suzuki en las sucursales de México.</Typography>
                            <Divider className={estilos.dividerHorizontal}/>
                            <img className = {estilos.imagen} src ={imgSuzuki1} alt="imagenSuzuki1"/>
                            <img className = {estilos.imagen} src ={imgSuzuki2} alt="imagenSuzuki2"/>
                            <Divider className={estilos.dividerHorizontal}/>
                            <Typography variant ='body1' color="primary" children="Marketing Suzuki corporativo" display='inline'/> 
                            <Typography variant ='body2' color="TextSecondary" children="Ernesto de Jesús"  />
                            <Typography variant ='body2' color="TextSecondary" children="55-8531-2261"  />
                            <Typography variant ='body2' color="TextSecondary" children="ernesto_dejesus@suzuki.com.mx"  />
                            </Paper>
                            </Grid>
                           
                            <Grid item  xs={12} sm={5} >
                            <Box pt={3}>
                            <Paper className={estilos.paper} elevation={3} pt={3}>
                                <img src ={LogoSangsang} className={estilos.logoSuzuki} alt="imagenSuzuki"/>
                                <Divider className={estilos.dividerHorizontal}/>  
                                <Typography variant ='h5' color="primary" gutterBottom >Sangsang Technologies</Typography>
                                <Typography paragraph variant ='body1' color="TextSecondary">Si se tienen dudas acerca del funcionamiento del sistema, contacte a 
                                                                                    <Typography  display='inline' variant ='body1' color="primary" children=" Sangsang Technologies "/> 
                                                                                     para asistencia.</Typography>
                                <Divider className={estilos.dividerHorizontal}/>
                                <Grid container direction="row" justify="flex-start">
                                    <Grid item direction ="column"  justify="flex-start" alignItems="flex-start" >
                                        <Grid item >
                                            <Typography variant ='body1' color="primary" children="Teléfonos" display='inline'/> 
                                            <Typography variant ='body2' color="TextSecondary" children=" 55-5772-5039, 55-5941-3714"  />
                                            <Typography variant ='body2' color="TextSecondary" children=" 55-5941-3715, ext 601,605 ó 606."  paragraph/>
                                        </Grid>
                                        <Grid item >  
                                            <Typography variant ='body1' color="primary" children="Correos" display='inline'/> 
                                            <Typography variant ='body2' color="TextSecondary" children=" edgar@sangsang.mx"/>
                                            <Typography variant ='body2' color="TextSecondary" children=" cesar@sangsang.mx" paragraph/>
                                        </Grid>
                                    </Grid> 
                                    <Grid>
                                    <Divider orientation='vertical' className={estilos.dividerVertical}/> 
                                    </Grid> 
                                    <Grid item direction ="column"  justify="flex-start" alignItems="flex-start" >
                                        <Grid item >  
                                            <Typography variant ='body1' color="primary" children="Dirección" display='inline'/> 
                                            <Typography variant ='body2' color="TextSecondary" children="Balderas #39, piso 6" />
                                            <Typography variant ='body2' color="TextSecondary" children="colonia Centro, alcaldia Cuauthemoc"/>
                                            <Typography variant ='body2' color="TextSecondary" children="CDMX, CP 06000" paragraph />
                                        </Grid>
                                        <Grid item >
                                            <Typography variant ='body1' color="primary" children="Horario" display='inline'/> 
                                            <Typography variant ='body2' color="TextSecondary" children=" de 08:00 a.m. a 05:00 p.m." />
                                        </Grid>
                                    </Grid>
                                </Grid>                                                 
                                </Paper>
                                </Box>
                            </Grid>
                          
                        </Grid>
                     
                        
                  
            </Container>
    )
}

export default Informacion;

import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import BussinesIcon from '@material-ui/icons/Business';
import ListasDeContenidoIcon from '@material-ui/icons/Dvr';
import ContenidosIcon from '@material-ui/icons/AddToQueue';
import AnaliticosIcon from '@material-ui/icons/Assessment';
import HelpIcon from '@material-ui/icons/Help';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LogoSuzuki from '../assets/suzuki.svg';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MapaInicio from './mapaInicio';
import ListasDeContenido from './listasDeContenido';
import Contenidos from './contenidos';
import Sucursales from './sucursales';
import Incidencias from './incidencias';
import Analiticos from './analiticos';
import AdminUsuarios from './adminUsuarios';
import Documentos from './documentos';
import { Route, Link,Routes,useNavigate, useParams} from "react-router-dom";
import IncidenciasIcon from '@material-ui/icons/AssignmentLateOutlined';
import AdminUsersIcon from '@material-ui/icons/SupervisorAccount';
import Avatar from '@material-ui/core/Avatar';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import Reportes from './reportes';
import ReportesIcon from '@material-ui/icons/AssignmentIndOutlined';
import Informacion from './informacion';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import Button from '@material-ui/core/Button';
import LogoutIcon from '@material-ui/icons/ExitToApp'
import {UsuarioContext} from '../contexts/usuarioProvider';
import ModalSucursalesProvider from '../contexts/modalSucursalesProvider';
import ModalAdminUsuariosProvider from '../contexts/modalAdminUsuariosProvider';
import ModalContenidosProvider from '../contexts/modalContenidosProvider';
import ModalListaDeContenidosProvider from '../contexts/modalListaDeContenidosProvider';
import ModalIncidenciasProvider from '../contexts/modalIncidenciasProvider';
import Sucursal from '../Componentes/sucursal';


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor: 'white', 
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  suzukiLogo: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  
    height:80,
    width:'100%'
  },
  imgUser:{
    
    margin: theme.spacing(1),
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  botonInfo:{
    color: 'white',
    background: 'black', 
  
    '&:hover':{
      color: 'white',
      background: theme.palette.primary.dark,
      '& $icono':{color:'white'}
    },
    "&:focus": {
      color: 'white',
      background: theme.palette.primary.dark,
      '& $icono':{color:'white'},
      '&:hover':{
        color: 'white',
        background: theme.palette.primary.dark,
        '& $icono':{color:'white'}
      }
    }
   
  },
  icono: {color:'white'}
  ,
  bottom:{
    position:'absolute',
    bottom:'0px',
  },
  expansionPanel:{
    display:'grid',
    root:{
    marginBottom:theme.spacing(0),
    marginTop:theme.spacing(0),
    },
    '&$expanded': {
      margin: '2px 0',
    }, '&:before': {
      margin: '1px 0',
    },
    
    content: {
      '&:before': {
        margin: '1px 0',
      },
      '&$expanded': {
        margin: '0px 0',
      }}
  }
}));

const Dashboard = (props) => {

  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const navigateInDashboard = useNavigate();
  //const [fireBaseusuario,setFirebaseUsuario] = React.useState(auth.currentUser);
  const { navegacionParam } = useParams();

  //Inicializamos el context del usuario
  const {cerrarSesion,usuario} = React.useContext(UsuarioContext);


 React.useEffect(() =>{
        if(usuario){
          console.log('usuario logeado');
          console.log(usuario.email);
         // setFirebaseUsuario(auth.currentUser);
          
        }else{
          console.log('no hay usuario logeado, redireccionando');
          navigateInDashboard('/login');
        }
 },[])

 const IcerrarSesion = async() => {
      
      console.log('cerrando sesion, redireccionando a login');
      console.log({navegacionParam});
      const res= await cerrarSesion();
      console.log("acabe de deslogearme " + res);
      navigateInDashboard('../');
}
 
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
//VAriables de estado de botones del menu
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const handleListItemClick = (event, index) => {
      setSelectedIndex(index);
    };

//variables de estado del panel de expansion para cerrar sesion
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
 
  const drawer = (<>
      <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={classes.expansionPanel} >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className={classes.expansionPanel}>
          <Box>
          <Box display="flex" justifyContent ="center" mb={0.5} pt={0.5}>
          <Avatar alt="user" src={usuario.imgUsuario} className={classes.imgUser} /> </Box>
          <Box display="flex" justifyContent ="center"  m={0} p={0}>
          <Typography color='primary' variant ="body1" align='center' children={ usuario && (usuario.email)}/></Box>
          <Box display="flex"  justifyContent ="center"  mb={1} p={0}>
          <Typography color='textSecondary' variant="body2" align='center'>{usuario.rol}</Typography>
          </Box></Box>
        </AccordionSummary>
        <AccordionDetails style={{display:'grid', paddingBottom:4,paddingTop:2}} ><>
          <Button onClick={()=>IcerrarSesion()} variant='text' color="secondary" size ="small" startIcon={<LogoutIcon />}>
          <Typography color='error'  variant="button" align='center' children="cerrar sesión" />
          </Button>
         
          </>
        </AccordionDetails>
        <Divider />
      </Accordion>
     
    <List>
    {usuario.rol ==='sucursal'?<><ListItem button key='sucursal' component={Link} to='sucursal' selected={selectedIndex === -1}
          onClick={(event) => handleListItemClick(event, -1)} >
            <ListItemIcon style={{ paddingTop: '11px', paddingBottom: '11px'}}> <BussinesIcon fontSize='large'/></ListItemIcon>
            <ListItemText primary='Sucursal'/>
        </ListItem> </>: <></>}
    { usuario.rol === 'admin' ? <>
        <ListItem button key='inicio' component={Link} to = 'mapaInicio' selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}>
            <ListItemIcon style={{ paddingTop: '11px', paddingBottom: '11px'}}> <HomeIcon fontSize='large' /></ListItemIcon>
            <ListItemText primary='Inicio' />
        </ListItem>  
        <ListItem button key='sucursales' component={Link} to='sucursales' selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)} >
            <ListItemIcon style={{ paddingTop: '11px', paddingBottom: '11px'}}> <BussinesIcon fontSize='large'/></ListItemIcon>
            <ListItemText primary='Sucursales'/>
        </ListItem>
      <ListItem button key='listasDeContenido' component={Link} to="listascontenido" selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)}>
          <ListItemIcon style={{ paddingTop: '11px', paddingBottom: '11px'}}> <ListasDeContenidoIcon fontSize='large'/></ListItemIcon>
          <ListItemText primary='Listas de contenido'/>
      </ListItem>
      <ListItem button key='contenidos'component={Link} to="contenidos" selected={selectedIndex === 4}
          onClick={(event) => handleListItemClick(event, 4)} >
          <ListItemIcon style={{ paddingTop: '11px', paddingBottom: '11px'}}> <ContenidosIcon fontSize='large'/></ListItemIcon>
          <ListItemText primary='Contenidos'/>
      </ListItem>
      </>: <></>}
      <ListItem button key='documentos' component={Link} to="documentos"  selected={selectedIndex === 5}
          onClick={(event) => handleListItemClick(event, 5)}>
          <ListItemIcon style={{ paddingTop: '11px', paddingBottom: '11px'}}> <CloudDownloadIcon fontSize='large'/></ListItemIcon>
          <ListItemText primary='Documentos'/>
      </ListItem>
      
      { usuario.rol === 'admin'|| usuario.rol === 'sucursal' ? 
      <ListItem button key='incidencias' component={Link} to="incidencias" selected={selectedIndex === 6}
          onClick={(event) => handleListItemClick(event, 6)} >
          <ListItemIcon style={{ paddingTop: '11px', paddingBottom: '11px'}}> <IncidenciasIcon fontSize='large'/></ListItemIcon>
          <ListItemText primary='Incidencias'/>
      </ListItem>: <></>}
      {/** 
      { usuario.rol === 'admin' ? <>
      <ListItem button key='reportes' component={Link} to="reportes" selected={selectedIndex === 7}
          onClick={(event) => handleListItemClick(event, 7)}>
          <ListItemIcon style={{ paddingTop: '11px', paddingBottom: '11px'}}> <ReportesIcon fontSize='large'/></ListItemIcon>
          <ListItemText primary='Reportes'/>
      </ListItem>

      </>: <></>}**/}

   
      
      { usuario.rol === 'admin' ? <>{/* 
      <ListItem button key='analiticos' component={Link} to="analiticos" selected={selectedIndex === 8}
          onClick={(event) => handleListItemClick(event, 8)}  >
          <ListItemIcon style={{ paddingTop: '11px', paddingBottom: '11px'}}> <AnaliticosIcon fontSize='large'/></ListItemIcon>
          <ListItemText primary='Analiticos'/>
      </ListItem>*/}
      <ListItem button key='adminUsers' component={Link} to="adminUsers" selected={selectedIndex === 9}
          onClick={(event) => handleListItemClick(event, 9)}>
          <ListItemIcon style={{ paddingTop: '11px', paddingBottom: '11px'}}> <AdminUsersIcon fontSize='large'/></ListItemIcon>
          <ListItemText primary='Administrador de Usuarios'/>
      </ListItem>
      </>: <></>}
      </List>
    
    <Divider/>{/*Añadir flexGrow:1 a lo que queremos que se vaya hasta abajo https://stackoverflow.com/questions/40020921/flexbox-fill-available-space-vertically/40021077 */}
    <Grid className={classes.bottom} container direction="column" justify='flex-end' alignItems="flex-start" alignItems="stretch">
        <ListItem  button key='Informacion' component={Link} to="informacion" selected={selectedIndex === 10}
          onClick={(event) => handleListItemClick(event, 10)} className={classes.botonInfo}>
          <ListItemIcon style={{ paddingTop: '11px', paddingBottom: '11px'}} className={classes.icono} ><HelpIcon /></ListItemIcon>
          <ListItemText primary='Información' />   
        </ListItem>
      </Grid>
  </>
);

const container = window !== undefined ? () => window().document.body : undefined;

    return (
      <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {/* Menu version mobile */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Typography color='primary' variant="h6" noWrap>
                Suzuki Digital Signage
            </Typography>
          </Grid>
          <Grid container direction="row" justify="flex-end" alignItems="center">    
            <img src={LogoSuzuki} className={classes.logoSuzuki} alt="logoSuzuki"/> 
          </Grid>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
         <Routes>
              {usuario.rol === "admin" ?  <Route  path="/" element =  {<MapaInicio/>}/>: 
              usuario.rol === "sucursal" ?
              <Route  path="/" element =  {<Sucursal sucursal={usuario.sucursal}/>} /> :
              <Route  path="/" element = {<Documentos/>} /> 
              }
              <Route  path="sucursal" element =  {<Sucursal sucursal={usuario.sucursal}/>}/>
              <Route  path="mapaInicio" element={<MapaInicio/>}/>
              <Route  path="sucursales/*" element={<ModalSucursalesProvider><Sucursales/></ModalSucursalesProvider>}/>
              <Route  path="listascontenido" element={<ModalListaDeContenidosProvider ><ListasDeContenido/></ModalListaDeContenidosProvider>}/>
              <Route  path="contenidos" element={<ModalContenidosProvider ><Contenidos/></ModalContenidosProvider>}/>
              <Route  path="incidencias" element={<ModalIncidenciasProvider ><Incidencias/></ModalIncidenciasProvider>}/>
              <Route  path="analiticos" element = {<Analiticos/>}/>
              <Route  path="reportes" element = { <Reportes/>}/>
              <Route  path="documentos" element = {<Documentos/>} /> 
              <Route  path="adminUsers" element = { <ModalAdminUsuariosProvider><AdminUsuarios/></ModalAdminUsuariosProvider>}/>
              <Route  path="informacion" element = { <Informacion/>}/>
          </Routes>
      </main>
    </div>
  );
}

export default Dashboard

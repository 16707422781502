export default  [
    { value : "PC no enciende", label: "PC no enciende" },
    { value : "PC no envía señal", label: "PC no envía señal" },
    { value : "PC no conecta a Wi-Fi", label: "PC no conecta a Wi-Fi" },
    { value : "No aparece la Barra de Tareas", label: "No aparece la Barra de Tareas" },
    { value : "Pantalla no enciende", label: "Pantalla no enciende" },
    { value : "Pantalla no recibe señal", label: "Pantalla no recibe señal" },
    { value : "Pantalla no se escucha", label: "Pantalla no se escucha" },
    { value : "Videos no cargan", label: "Videos no cargan" },
    { value : "Videos cortados", label: "Videos cortados" },
    { value : "Video en blanco", label: "Video en blanco" },
    { value : "Programa envía error", label: "Programa envía error" },
    { value : "Otro tipo de problema", label: "Otro tipo de problema" }
]
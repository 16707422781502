import React,{useEffect,useState,useCallback,createContext,useContext,useMemo,useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Divider  from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import EstadosLista from '../assets/listaEstados';
import Progress from '@material-ui/core/LinearProgress';
import {db} from '../backEnd/firebase';
import {useDropzone} from 'react-dropzone';
import Dropzone from 'react-dropzone';
import RootRef from '@material-ui/core/RootRef';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/CancelRounded';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import {TransferListContext} from '../Componentes/transferList';
import TransferList from '../Componentes/transferList';
import _ from 'lodash';





//context que sera usado en componentes
export const ModalListaDeContenidosContext = createContext();

const estilos = makeStyles(theme =>( {
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  colores:{
    okBlue: theme.palette.primary
  },
  button:{
      margin: theme.spacing(3),
      marginRight: theme.spacing(6)
  },
  buttonModal:{
    margin: theme.spacing(1),
  },
  textoIcono:{
      verticalAlign : 'middle',
      display:'inline-flex',
      margin: 'inherit',
      color:'blue'
  },
  tituloModalEliminar:{
      color: theme.palette.secondary.light
  },
  textoModalEliminar:{
      color: theme.palette.secondary.dark
  },
  icono:{
      verticalAlign : 'middle',
      display:'inline-flex',
      backgroundColor: 'yellow',
      color: '#fff',
      background: 'yellow',
      margin: '400'
  },closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0),
  }, dividerHorizontal:{
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

  


const ModalListaDeContenidosProvider = (props) => {
   
  //Estilos de componente
   const classes = estilos();

   //Variables de Modal de eliminar Lista de Contenido
   const [openModalEliminarListasDeContenido,setOpenEliminarListasDeContenido] = useState(false);
   const handleClickOpenModalEliminarListasDeContenido = (listasDeContenido) => {setOpenEliminarListasDeContenido(true);setselectedListaDeContenido(listasDeContenido);};
   const handleCloseEliminarListasDeContenido = () => {setOpenEliminarListasDeContenido(false);};

    //Variables de Modal Editar Listas de Contenido
    const [openModalEditarListasDeContenido, setOpenModalEditarListasDeContenido] = useState(false);
    const handleClickOpenModalEditarContenidoModal = (Contenido) => {setOpenModalEditarListasDeContenido(true);setselectedListaDeContenido(Contenido)};
    const handleCloseEditarModalListasDeContenido = () => {setOpenModalEditarListasDeContenido(false);};
    
    //Variables de Modal Añadir Contenido
    const [openModalAddListasDeContenido, setOpenModalAddListasDeContenido] = useState(false);
    const handleClickOpenModalAddListasDeContenido = () => {setOpenModalAddListasDeContenido(true);};
    const handleCloseAddListasDeContenidoModal = () => {setOpenModalAddListasDeContenido(false);};

    const [selectedListaDeContenido,setselectedListaDeContenido] = useState({});
    
  //context del modal Listas de Contenido
  //const {right} = useContext(TransferListContext);

    

    //VAriables para los snack
    const Alert = (props) => {
      return (<MuiAlert elevation={6} variant="filled" {...props} />);
    }
    const [abrirSnackBar,setAbrirSnackBar] = useState(false);

const AlertaSnackBar =()=>{
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAbrirSnackBar(false);
  }; 

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAbrirSnackBar(false);
  }

  const slideTransition = (props) =>{
    return (<Slide {...props} direction="down" />)
  }
 
  return(
    <Snackbar
          anchorOrigin={{ vertical:'top', horizontal:'center' }}
          open={abrirSnackBar}
          onClose={handleCloseSnackBar}
          autoHideDuration={2000}
          TransitionComponent={slideTransition}
          key={slideTransition}
        >
        <Alert onClose={handleClose}   severity="success" className={classes.colores}>
          contenido editado
        </Alert>
    </Snackbar>)
}


 


const ModalListaDeContenidos = ()=>{

  const [cerrarclickBack,setCerrarClickBack] = useState(false);
  //VAriables para los snack
  //const [abrirSnackBar,setAbrirSnackBar] = useState(false);

  /* CREAR Lista de Contenidos */
  const [nombre,setNombre] = useState('');
  const [nuevaListaDeContenidos,setNuevaListaDeContenidos] = useState([]);
  const [listaParaComparar,setListaParaComparar] = useState (nuevaListaDeContenidos);
  

  const handleSelectedListChange = (evt) =>{
    console.log("valor de funcion1 " + JSON.stringify(evt))
    console.log("seteado el right desde modallista")
    setNuevaListaDeContenidos(evt);
    //setListaParaComparar(evt);
    console.log("despues seteado el right desde modallista")
    console.log("valor de funcion2 " + JSON.stringify(evt))
    console.log(nuevaListaDeContenidos);
  }

  const setListToCompare = x =>{
    setListaParaComparar(x);
  }

  /* EDITAR UNA Contenido */ 
  const [nombrePivotEdit,setnombrePivotEdit] = useState(selectedListaDeContenido.nombre);

 //Variables de Error y loading
 const [nError,setnError] = useState(null);
 const [nOkResponse,setOkResponse] = useState(null);
 const [loadingFbQuery,setloadingFbQuery] = useState(false);
 const [botonesEnabled,setbotonesEnabled] = useState(true);


 //Funcion para eliminar Contenido
 const eliminarListaDeContenido =  useCallback(async () =>{
  db.collection('listaDeContenidos').doc(selectedListaDeContenido.id).delete()
                 .then(respuesta => {
                     console.log('Lista eliminada de los documentos ' + respuesta)
                     handleCloseEliminarListasDeContenido();
                    return "usuario Eliminado con Exito";
                 }).catch(error=>{
                   return error
                 }); 
  
  });

//Funcion para crear una Contenido
const crearContenido = () =>{

  setnError(null);
  setloadingFbQuery(true);
  setbotonesEnabled(false);
  console.log("Vamos a intentar añadir una Lista de Contenido")
  console.log(nuevaListaDeContenidos);
  console.log(JSON.stringify(nuevaListaDeContenidos));
  
  if(!nombre.trim() &&  nuevaListaDeContenidos.length === 0){
    console.log('no hay datos ');
    setloadingFbQuery(false);
    setbotonesEnabled(true);
    setnError('Campos vacios');
    return
  }

  if(!nombre.trim() ){
    console.log('no hay datos ');
    setloadingFbQuery(false);
    setbotonesEnabled(true);
    setnError('Agrega un nombre a la lista de reproducción');
    return
  }

  if(nuevaListaDeContenidos.length === 0 ){
    console.log('no hay lista de reproduccion ');
    setloadingFbQuery(false);
    setbotonesEnabled(true);
    setnError('No hay videos en la lista de reproducción');
    return
  }

  console.log('vamos a revisar')
  //Añadimos usuario
  fbCrearListaDeContenido();
}
const fbCrearListaDeContenido = async() =>{

    db.collection('listaDeContenidos').add({
      nombre:nombre,
      listaDeVideos: nuevaListaDeContenidos
    }).then((res)=>{
      console.log(res)
     // setOkResponse("Video agregado " + res);
      setbotonesEnabled(true);
      setloadingFbQuery(false);
      handleCloseAddListasDeContenidoModal(false);
  
    }).catch(e=>{
      console.error(e.error);
      setnError(e.error);
      setbotonesEnabled(true);
      setloadingFbQuery(false);  
    });

}

//Funcion para Updatear una Lista de Contenido 
const updateContenido = () =>{
  setnError(null);
  setloadingFbQuery(true);
  setbotonesEnabled(false);
  //console.log("Vamos a intentar editar una Lista deContenido")
  let contenidoData = null;

  if(!nombre.trim() && nuevaListaDeContenidos.length === 0 ){
    console.log('no hay datos que actualizar');
      setloadingFbQuery(false);
      setbotonesEnabled(true);
      setnError('Campos vacios');
    return
  }
  //Este if, evita que no se actualize con una lista vacia
  if(nuevaListaDeContenidos.length === 0){
    console.log('no hay datos que actualizar');
      setloadingFbQuery(false);
      setbotonesEnabled(true);
      setnError('Lista vacia, añade un video');
    return
  }
 
 //console.log("nuevaListaDeContenidos " + JSON.stringify(nuevaListaDeContenidos));
 //console.log("listaparaComparar" + JSON.stringify(listaParaComparar));
  let areEqual = _.isEqual(nuevaListaDeContenidos,listaParaComparar);
   
   if(!nombre.trim() && nuevaListaDeContenidos.length === 0 ){
    console.log('no hay datos que actualizar');
      setloadingFbQuery(false);
      setbotonesEnabled(true);
      setnError('Campos vacios 2');
    return
  }

  if(!nombre.trim() && areEqual === true ){
    console.log('nombre vacio y lista igual');
      setloadingFbQuery(false);
      setbotonesEnabled(true);
      setnError('nombre vacio y lista igual');
    return
  }

   if(nombre.trim()){
    contenidoData = {nombre:nombre};
  }


  if(nuevaListaDeContenidos.length !== 0 && areEqual === false)//&& _.differenceWith(nuevaListaDeContenidos,listaParaComparar,_.isEqual) !== []){
   {
     console.log("va a transferir la lista")
    contenidoData = {...contenidoData,listaDeVideos:nuevaListaDeContenidos};
  }

  //Actualizamos Contenido
  console.log("lista a Actualizar: " + JSON.stringify(contenidoData))
  fbEditarListaDeContenido(contenidoData);
}

const fbEditarListaDeContenido = async(contenidoData) =>{

console.log("actualizare nombre y video por lo tanto preparare el video");

 db.collection('listaDeContenidos').doc(selectedListaDeContenido.id).update(contenidoData)
    .then((res)=>{
      console.log("Lista de Video contenido updateado " + res)
      setOkResponse("Lista de videos actualizada ");
      //setURLContenido(contenidoData.URLContenido);
      //setAbrirSnackBar(true);
      //setbotonesEnabled(true);
      //setloadingFbQuery(false);
      handleCloseEditarModalListasDeContenido(false);
    }).catch(e=>{
      console.error(e);
      setnError(e.Error);
    });
  
}

console.log("modal Listas de Contenido")

return(<>
      <>{/*MODAL ELIMINAR Lista de Contenido */}
          <Dialog open={openModalEliminarListasDeContenido} aria-labelledby="alert-dialog-title" 
          aria-describedby="alert-dialog-eliminar-contenido" onClose={handleCloseEliminarListasDeContenido}  >
                  <DialogTitle  id="alert-dialog-title-EliminarContenido">
                  {"¿Desea eliminar la Lista de Contenidos "}
                  <Typography  display='inline' variant ='h6'className={classes.tituloModalEliminar}>{selectedListaDeContenido.nombre}</Typography>
                     {"?"}
                  </DialogTitle>
                 <DialogContent>
                    <DialogContentText className={classes.textoModalEliminar} id="alert-dialog-eliminar-contenido">
                      Si se borra la Lista NO podra ser recuperada.
                    </DialogContentText>
                 </DialogContent>
                 <DialogActions>
                     <Button variant="contained" color="primary" onClick={handleCloseEliminarListasDeContenido}>
                         Cancelar
          </Button>
          <Button className={classes.buttonModal} variant="contained" color="secondary" onClick={() => eliminarListaDeContenido ()} autoFocus>
                         Borrar
          </Button>
                 </DialogActions>
             </Dialog></>
      <>{/*MODAL EDITAR Lista de Contenidos */}
        <form className={classes.root} noValidate autoComplete="off">
        <Dialog maxWidth= 'md' fullWidth open={openModalEditarListasDeContenido} 
            onClose={handleCloseEditarModalListasDeContenido} aria-labelledby="form-dialog-Editar">
        <DialogTitle id="formEditarListaDeContenidoTitulo" >
        {"Editar Lista de contenido "} 
        <Typography  display='inline' variant ='h6' color="primary" >{selectedListaDeContenido.nombre}</Typography>
        </DialogTitle>
        <Divider/>
        <DialogContent >
             <DialogContentText className={classes.root} pt='1'>
               Actualiza la lista de videos
            </DialogContentText>
            <Grid container spacing = {1} direction="row" justify="flex-start" alignItems="flex-start">
       <Grid item xs={12} >
        <Box display="flex" >
        <Box display ="flex" alignItems="center" mx={2}> 
        <Typography display="inline" margin="dense"variant="subtitle1" align="left" color="primary" children={selectedListaDeContenido.nombre}/>
        </Box> 
        <Box> 
         <TextField
            autoFocus
            margin="dense"
            id="nameAddContenido"
            label="cambiar nombre"
            value={nombre}
            onChange={e=>{ setNombre(e.target.value);}}
            type="text"
            variant="outlined"
          /> </Box></Box>
       <Divider className= {classes.dividerHorizontal}/>
    </Grid>
    <DialogContentText className={classes.root} pt='1'>   
               Transfiere a la derecha los contenidos de la lista de reproducción
           </DialogContentText>
      <Grid item xs={12}> 
         <TransferList callBackTransferList = {handleSelectedListChange} callBackListaAComparar= {setListToCompare}
         listaSeleccionada= {selectedListaDeContenido}/>
     </Grid>  
      </Grid>
        </DialogContent>
        {  nError && ( <Typography  variant="subtitle1" align="center" color="error" children={nError}/> ) }
        {  nOkResponse && ( <Typography  variant="subtitle1" align="center" color="primary" children={nOkResponse}/> ) }
        { loadingFbQuery && ( <Progress/>) }
        <Divider/>
        <DialogActions>
          <Button className={classes.buttonModal} variant='contained' onClick={handleCloseEditarModalListasDeContenido} color="secondary" disabled ={!botonesEnabled}>
            Cancelar
          </Button>
          <Button className={classes.buttonModal} variant = 'contained' onClick={updateContenido} color="primary" disabled ={!botonesEnabled}>
            Actualizar
          </Button>
        </DialogActions>
      </Dialog>
      </form></>
      <>{/*MODAL AÑADIR Contenido */}
        <form id="formContenido" className={classes.root} noValidate autoComplete="off">
        <Dialog maxWidth= 'md' fullWidth open={openModalAddListasDeContenido} 
            onClose={handleCloseAddListasDeContenidoModal} aria-labelledby="form-dialog-addContenido">
        <DialogTitle id="formAddContenidoTitulo">Añade una Lista de contenido</DialogTitle>
        <Divider/>
        <DialogContent>
          <DialogContentText className={classes.root} pt='1'>
            Elije el nombre de la nueva lista de reproducción
          </DialogContentText>
          <Grid  container spacing = {1} direction="row" justify="flex-start" alignItems="baseline">
            <Grid item xs={12}>
              <TextField
                  autoFocus
                  margin="dense"
                  id="nameAddContenido"
                  label="Nombre de Contenido"
                  value={nombre}
                  onChange={e=>{setNombre(e.target.value);}}
                  type="text"
                  variant="outlined"
                /> 
              <Divider className= {classes.dividerHorizontal}/>
            </Grid>
           <DialogContentText className={classes.root} pt='1'>   
               Transfiere a la derecha los contenidos de la lista de reproducción
           </DialogContentText>
          <Grid item xs={12}>
            <TransferList callBackTransferList = {handleSelectedListChange} />
        </Grid>  
        </Grid>
        </DialogContent>
        {  nError && ( <Typography  variant="subtitle1" align="center" color="error" children={nError}/> ) }
        {  nOkResponse && ( <Typography  variant="subtitle1" align="center" color="primary" children={nOkResponse}/> ) }
        {  loadingFbQuery && ( <Progress/>) }
        <Divider/>
        <DialogActions>
          <Button className={classes.buttonModal} variant='contained' onClick={handleCloseAddListasDeContenidoModal} color="secondary" disabled ={!botonesEnabled}>
            Cancelar
          </Button>
          <Button  className={classes.buttonModal} variant = 'contained' onClick={crearContenido} color="primary" disabled ={!botonesEnabled}>
            Añadir Contenido
          </Button>
        </DialogActions>
      </Dialog>
      </form></>
    </>)
}


return (
  <ModalListaDeContenidosContext.Provider value={{handleClickOpenModalEliminarListasDeContenido, handleClickOpenModalEditarContenidoModal,
    handleClickOpenModalAddListasDeContenido, ModalListaDeContenidos,AlertaSnackBar}}> 
      {props.children}
  </ModalListaDeContenidosContext.Provider>
)          
}

export default ModalListaDeContenidosProvider;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddFolderIcon from '@material-ui/icons/CreateNewFolder';
import FolderIcon from '@material-ui/icons/Folder';
import AddArchivoIcon from '@material-ui/icons/NoteAdd';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { NodoPrincipalDocumentos } from '../Componentes/nodoDocumentos';
import { Routes, Route } from 'react-router-dom';
import { UsuarioContext } from '../contexts/usuarioProvider';
import { db ,firebase} from '../backEnd/firebase';
import RootRef from '@material-ui/core/RootRef';
import { useDropzone } from 'react-dropzone';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import Progress from '@material-ui/core/LinearProgress';



//Estilo del componente de documentos
const estilos = makeStyles(theme => ({

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    button: {
        margin: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    textoIcono: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        margin: 'inherit',
        color: 'blue'
    },
    tituloModalEliminar: {
        color: theme.palette.secondary.light
    },
    textoModalEliminar: {
        color: theme.palette.secondary.dark
    },
    icono: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        backgroundColor: 'yellow',
        color: '#fff',
        background: 'yellow',
        margin: '400'
    },
    archivo: {
        border: 'solid',
        margin: theme.spacing(2),
        height: '200px'
    }
}));

function Documentos(props) {

    //Pasamos el estilo del este componente
    const classes = estilos();

    //Variables de estado de Modal añadir Folder
    const [isVisibleModalAddFolder, setIsVisibleModalAddFolder] = useState(false);
    const mostrarModalAddFolder = () => setIsVisibleModalAddFolder(true);
    const ocultarModalAddFolder = () => setIsVisibleModalAddFolder(false);

    //Variables de estado de Modal add File
    const [isVisibleModalAddFile, setIsVisibleModalAddFile] = useState(false);
    const mostrarModalAddFile = () => setIsVisibleModalAddFile(true);
    const ocultarModalAddFile = () => setIsVisibleModalAddFile(false);

    //context del usuario
    const { usuario } = React.useContext(UsuarioContext);


    //Variable de documentos
    const [documentos, setDocumentos] = useState({});
    const [ruta,setRuta] = useState({SuzukiRuta:" ",fbRuta:"documentos"});

    console.log("seteo de var doc" + documentos)

    //Read de las Documentos
    const obtenerDocumentos = async () => {
        try {
            await db.collection(ruta.fbRuta).onSnapshot(dataDocumentos => {
                console.log(dataDocumentos.docs);
                console.log("estoy fetcheando los docymentos")
                console.log(dataDocumentos);
                console.log("existye" + dataDocumentos.empty)
                console.log(dataDocumentos.docs.exists);
                //convertimos el objeto de fb a un array para el estado
                if (!dataDocumentos.empty) {
                    console.log("existe la ruta")
                    const arrayDocumentos = dataDocumentos.docs.map(doc => ({ id: doc.id,...doc.data()}));
                    console.log(arrayDocumentos);
                    setDocumentos(arrayDocumentos);
                } else {
                    console.log("no exciste la ruta")
                    setDocumentos(null);
                }

            });
        }
        catch (error) {
            console.log("err3")
            console.log("error en fetching docymentos" + error);
        }
    }
    useEffect(() => {
        //llamamos la funcion del efecto para obtener las Documentos
        obtenerDocumentos();
    }, []);


    //Modales
    const ModalsDocumentos = () => {

        //Variables de Error y loading
    
        const [nOkResponse, setOkResponse] = useState(null);
        const [cerrarclickBack, setCerrarClickBack] = useState(false);
        const [nError, setnError] = useState(null);
        const [folderName, setFolderName] = useState('');
        const [archivoNombre, setArchivoNombre] = useState('');
        const [errorText, setErrorText] = useState(null);
        const [botonesEnabled, setbotonesEnabled] = useState(true);
        const [idDocumento, setIdDocumento] = useState(null);

        //Variables y funciones de Selector lista de reproduccion
        const [URLContenido, setURLContenido] = React.useState('');


        //Contenido nuevo
        const baseStyle = {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            marginBottom: '6px',
            borderWidth: 4,
            borderRadius: 4,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out',
            wordWrap: 'break-word'
        };
        const activeStyle = {
            borderColor: '#2196f3'
        };
        const acceptStyle = {
            borderColor: '#00e676'
        };
        const rejectStyle = {
            borderColor: '#ff1744'
        };

        //Configuracion del drop zone 
        const fileMaxSize = 100000000;//100MB 
        const [loadingFbQuery, setloadingFbQuery] = useState(false);
        const [localURLcontenido, setlocalURLcontenido] = useState('');
        const [uploadPorcentaje, setUploadPorcentaje] = useState(0);
        const [updloadProgressText, setUploadProgressText] = useState('');

        const onDropeoRechazado = (res) => {
            console.log(res[0]);
            console.log(JSON.stringify(res[0]));
            if (res[0].errors[0].code == "file-too-large") {
                setnError("El archivo es demasiado largo, debe ser menor de " + fileMaxSize / 100000000 + " MB");
            }
            else if (res[0].errors[0].code == "file-invalid-type") {
                setnError("Introduce un archivo valido");
            }
            else {
                setnError(res[0].errors[0].code);
            }
        }

        const onDropeoAceptado = res => {
            console.log(JSON.stringify(res));
            console.log("archivo aceptado");
            setnError("");
            //Hay que setear la url del archivo y quiza un preview o un algo que indicque que se tiene listo para subir
            setlocalURLcontenido(res[0].path);
            setURLContenido(res);
        }

        //Hook del drop zone
        const {
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject
        } = useDropzone({
            maxSize: fileMaxSize, multiple: false,
            onDropRejected: onDropeoRechazado, onDropAccepted: onDropeoAceptado
        });

        //Estilo del dropZone
        const style = useMemo(() => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }), [
            isDragActive,
            isDragReject,
            isDragAccept
        ]);
        const { ref, ...rootProps } = getRootProps({ style })
        //Upload Handler
        const optionsUploader = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor(loaded * 100 / total)
                setUploadProgressText(`${(loaded / 1000000).toFixed(2)}Mb de ${(total / 1000000).toFixed(2)}Mb | ${percent}%`)
                console.log(`${loaded.toFixed(2)}kbof ${total.toFixed(2)}kb | ${percent}%`)

                if (percent < 100) {
                    setUploadPorcentaje(percent);
                }
            }
        }

        const AddFolder = () => {
            setbotonesEnabled(false);
            setErrorText("");
            if (!folderName.trim()) {
                console.log("Nombre de Folder Vacio")
                setErrorText("Nombre de Folder Vacio");
                setbotonesEnabled(true);
                return
            }

            fbAddFolder();
        }

        const fbAddFolder = async () => {
            console.log("Vamos a comunicarnos con firestore")
            db.collection(ruta.fbRuta).add({
                nombre: folderName,
                tipo: 'folder',
                ruta: ruta.fbRuta
            }).then((res) => {
                ocultarModalAddFolder(false);
                console.log(res)
                // setOkResponse("Video agregado " + res);
                setbotonesEnabled(true);


            }).catch(e => {
                console.error(e.error);
                setErrorText(e.error);
                setbotonesEnabled(true);
                ocultarModalAddFolder(false);
            });
        }

        const fsAddFolder = async()=>{
            
        }

        const addFile = () => {
            setbotonesEnabled(false);
            setErrorText(null);
            setloadingFbQuery(true);

            if (!archivoNombre.trim() && !localURLcontenido.trim()) {
                console.log('no hay datos ');
                setloadingFbQuery(false);
                setbotonesEnabled(true);
                setnError('Campos vacios');
                return
            }

            if (!archivoNombre.trim()) {
                console.log('no hay nombre ');
                setloadingFbQuery(false);
                setbotonesEnabled(true);
                setnError('Campo de nombre vacio');
                return
            }
            else if (!localURLcontenido.trim()) {
                console.log('no hay archivo');
                setloadingFbQuery(false);
                setbotonesEnabled(true);
                setnError('No has agregado un video');
                return
            }

            fbAddFile();
        }

        const fbAddFile = async () => {
            setCerrarClickBack(true);
            let data = new FormData();
            let jeders = { headers: { 'Content-Type': 'multipart/form-data' } };
            let dato = URLContenido;
            data.append("archivo", dato[0]);
            axios.post('https://suzukiv2.sangsang.mx/ArchivosDocumentos/index2.php', data, optionsUploader, jeders).
                then(res => {
                    console.log(res);
                    if (res.data === "hubo un error en el lado del Servidor") {
                        setnError("Video NO subido, " + res.data);
                        setloadingFbQuery(false);
                        setbotonesEnabled(true);
                    }


                    setUploadPorcentaje(100, setTimeout(() => {
                        if (res.data === "El archivo " + dato[0].path + " ha sido subido.") {
                            setOkResponse("El archivo " + dato[0].path + " ha sido subido.")
                        }
                        setloadingFbQuery(false);
                        setbotonesEnabled(true);
                        setUploadPorcentaje(0);
                    }, 1000));


                }).then(() => {

                    console.log("Vamos a comunicarnos con firestore")
                    db.collection(ruta).add({
                        nombre: archivoNombre,
                        URLContenido: 'https://suzukiv2.sangsang.mx/ArchivosDocumentos/uploads/' + dato[0].path,
                        tipo:"archivo",
                        fecha: firebase.firestore.FieldValue.serverTimestamp()
                    }).then((res) => {
                        console.log(res)
                        // setOkResponse("Video agregado " + res);
                        ocultarModalAddFile();
                        setbotonesEnabled(true);
                        setloadingFbQuery(false);
                        setCerrarClickBack(false);
                        

                    }).catch(e => {
                        console.error(e);
                        setnError(e);
                        setbotonesEnabled(true);
                        setloadingFbQuery(false);
                        setCerrarClickBack(false);
                    });
                })
        }


        return (<>
            <>{/*Modal Add Folder*/}
                <Dialog maxWidth='xs' fullWidth open={isVisibleModalAddFolder} onClose={ocultarModalAddFolder}
                    aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Carpeta nueva"}</DialogTitle>
                    <DialogContent >
                        <form noValidate autoComplete="off">
                            <TextField margin='dense' fullWidth size='small' id="outlined-basic" value={folderName}
                                label={folderName ? folderName : "Carpeta sin titulo"} variant="outlined"
                                onChange={e => setFolderName(e.target.value)} />
                        </form>
                        {errorText && (<Typography variant="subtitle1" align="center" color="error" children={errorText} />)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ocultarModalAddFolder} variant="contained" color="secondary" disabled={!botonesEnabled}>
                            Cancelar
          </Button>
                        <Button onClick={AddFolder} variant="contained" color="primary" autoFocus disabled={!botonesEnabled}>
                            Crear
          </Button>
                    </DialogActions>
                </Dialog></>
            <>{/*Modal Add File*/}
                <Dialog maxWidth='xs' fullWidth open={isVisibleModalAddFile} onClose={ocultarModalAddFile}
                    aria-labelledby="alert-dialog-title2" aria-describedby="alert-dialog-description"
                    disableBackdropClick={cerrarclickBack}
                >
                    <DialogTitle id="alert-dialog-title2">{"Nuevo archivo"}</DialogTitle>
                    <DialogContent >
                    <Grid  container spacing = {2} direction="row" justify="space-around" alignItems="center">
                    <Grid item xs={5}>

                        <RootRef rootRef={ref}>
                            <Paper {...rootProps}>  <>
                                <input {...getInputProps()} />

                                {localURLcontenido ? <p>{localURLcontenido}</p> : <p>Arrastra o da click para añadir un archivo</p>}
                                {uploadPorcentaje === 0 ? <></> : <>
                                    <Box alignItems="flex-start">
                                        <Box width="100%" mr={1} p={0}>
                                            <LinearProgress variant="determinate" value={uploadPorcentaje} />
                                        </Box>
                                        <Box minWidth={35}>
                                            <Typography variant="body2" color="textSecondary">{updloadProgressText}</Typography>
                                        </Box>
                                    </Box> </>}
                            </>
                            </Paper>
                        </RootRef>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="nameAddContenido"
                                label="Nombre de Contenido"
                                value={archivoNombre}
                                onChange={e => { setArchivoNombre(e.target.value); }}
                                type="text"
                                variant="outlined"
                            />
                        </Grid></Grid>
                    </DialogContent>
                {  nError && (<Typography variant="subtitle1" align="center" color="error" children={nError} />)}
                {  nOkResponse && (<Typography variant="subtitle1" align="center" color="primary" children={nOkResponse} />)}
                { loadingFbQuery && (<Progress />)}
                <Divider />

                <DialogActions>
                    <Button onClick={ocultarModalAddFile} variant="contained" color="secondary">
                        Cancelar
          </Button>
                    <Button onClick={addFile} variant="contained" color="primary" autoFocus>
                        Subir
          </Button>
                </DialogActions>
            </Dialog>
        </>
        </>
        )
}

//CallbackFunction set documentos
const handleSelectedNodeDocumentChange = (evt,rut) =>{
    //console.log("valor de funcion1 callback  " + JSON.stringify(evt))
    console.log("seteado el right desde modallista")
    setDocumentos(evt);
    //setListaParaComparar(evt);
    setRuta(rut);
    console.log("despues seteado el right desde modallista")
   // console.log("valor de funcion2 " + JSON.stringify(evt))
    console.log(documentos);
    console.log(JSON.stringify(documentos));
  } 

return (
    <Container maxWidth='xl'>
        <Typography color='textSecondary' variant="h5">Documentos</Typography>
        <Typography color='textSecondary' variant="h6">Ruta: {ruta.fbRuta}  </Typography>
        <Divider style={{ marginBottom: '20px' }} />
        <Paper elevation={3} >
            {((usuario.rol === 'admin') || (usuario.rol === "Agencia")) && <>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Button onClick={mostrarModalAddFolder} variant="contained" size='large' color="primary"
                        className={classes.button} startIcon={<AddFolderIcon />} >
                        Añadir Carpeta
                </Button>
                    <Button onClick={mostrarModalAddFile} variant="contained" size='large' color="primary"
                        className={classes.button} startIcon={<AddArchivoIcon />}>
                        Añadir Archivo
                </Button>
                </Grid>
                <Divider /></>
            }
            <Routes>
            <Route path='/:ruta.fbRuta' element={<h1>testing</h1>} />
                <Route path='/' element={<NodoPrincipalDocumentos callBackFunSetNodoDocumentos ={handleSelectedNodeDocumentChange} data={documentos} />} />
               
            </Routes>

            <Divider />

        </Paper>
        <ModalsDocumentos />
    </Container>
)
}

export default Documentos;

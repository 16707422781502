import React, { useState, useEffect,useContext, useMemo ,useCallback} from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import AddIncidenciaIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import TiposDeIncidencias from '../assets/listaTiposDeIncidencias';
import ChatIcon from '@material-ui/icons/Chat';
import { db, firebase } from '../backEnd/firebase';
import { UsuarioContext } from '../contexts/usuarioProvider';

import moment from 'moment';
//Custom hooks Redux use dispatch es para consumir nuestra accion ,y use Selector es para leer la data 
import {useDispatch,useSelector} from 'react-redux';
import {obtenerIncidenciasAccion,obtenerChatAccion} from '../redux/incidenciasDucks';
import {ModalIncidenciasContext} from '../contexts/modalIncidenciasProvider';

//Estilos de la tabla de usuarios {background: blue[200],
const estilos = makeStyles(theme => ({

  button: {
    margin: theme.spacing(3),
    marginRight: theme.spacing(6)
  },
  textoIcono: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    margin: 'inherit',
    color: 'blue'
  },
  tituloModalEliminar: {
    color: theme.palette.secondary.light
  },
  textoModalEliminar: {
    color: theme.palette.secondary.dark
  },
  icono: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    backgroundColor: 'yellow',
    color: '#fff',
    background: 'yellow',
    margin: '400'
  },
  dividerHorizontal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }, buttonModal: {
    margin: theme.spacing(1),
  }
}));

//Variables de configuracion de la tabla
const columns = ["Usuario", "Sucursal", "Fecha de Incidencia","Tipo de incidencia", "Status", "Acciones"];
const options = {
  filterType: 'checkbox',
  filter: true,
  rowsPerPageOptions: [5, 10, 100],
  selectableRowsHeader: false,
  selectableRows: 'none'
};

function Incidencias() {

  //Estilos de componente
  const classes = estilos();

  //context del modal de contenido
  const {handleClickopenEliminarIncidenciaModal, handleClickOpenEditarIncidenciaModal,
    handleClickOpenAddIncidenciaModal, ModalIncidencias} = useContext(ModalIncidenciasContext);

  //Notacion de Redux en documentacion
  const dispatch = useDispatch()
  
  //READ Variable de Incidencias
  //const [incidencias, setIncidencias] = useState([]);
  const incidencias = useSelector(store => store.incidencias.array);

    //context del usuario
    const { usuario } = React.useContext(UsuarioContext);

  useEffect(() => {
    //llamamos la funcion del efecto para obtener las Incidencias
    dispatch(obtenerIncidenciasAccion(usuario))

  }, []);

  //Iconos de editar y borrar de la tabla
  const iconosDeRowElement = (incidencia) => {
    console.log(incidencia);
    return (
      <ListItem >
        <ListItemIcon onClick={() =>  {handleClickOpenEditarIncidenciaModal(incidencia); dispatch(obtenerChatAccion(incidencia.id));} }>
          <IconButton color='inherit' ><ChatIcon fontSize='large' style={{ color: '#3377AA' }} /></IconButton>
        </ListItemIcon>
        {usuario.rol === "admin" ? 
        <ListItemIcon onClick= {() =>handleClickopenEliminarIncidenciaModal(incidencia)}>
          <IconButton color='secondary' ><DeleteIcon fontSize='large' color='secondary' /></IconButton>
        </ListItemIcon>:<></>
        }
      </ListItem>)
  }

  //Data de la tabla
  const data = incidencias.map(incidencia => (
    [incidencia.nombre, incidencia.sucursal.nombre, incidencia.fecha && moment.unix(incidencia.fecha.seconds).format("DD/MM/YYYY , h:mm:ss a"),
    incidencia.tipoDeProblema, incidencia.status,
    iconosDeRowElement(incidencia)]
  ));

  return (
    <Container maxWidth='xl'>
      <Typography color='textSecondary' variant="h5">Incidencias en incidencia</Typography>
      <Divider />
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Button onClick={()=>handleClickOpenAddIncidenciaModal(true)} variant="contained" size='large' 
        color="primary" className={classes.button} startIcon={<AddIncidenciaIcon />}>
          Añadir Incidencia
        </Button>
      </Grid>
      <MUIDataTable
        title={"Lista de Incidencias"}
        data={data}
        columns={columns}
        options={options}
      />
      <ModalIncidencias />
    </Container>
  )
}

export default Incidencias;

import React,{useEffect,useState,useCallback,createContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Divider  from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import EstadosLista from '../assets/listaEstados';
import Progress from '@material-ui/core/LinearProgress';
import {db} from '../backEnd/firebase';

//context que sera usado en componentes
export const ModalSucursalesContext = createContext();


const estilos = makeStyles(theme =>( {
  button:{
      margin: theme.spacing(3),
      marginRight: theme.spacing(6)
  },
  buttonModal:{
    margin: theme.spacing(1),
  },
  textoIcono:{
      verticalAlign : 'middle',
      display:'inline-flex',
      margin: 'inherit',
      color:'blue'
  },
  tituloModalEliminar:{
      color: theme.palette.secondary.light
  },
  textoModalEliminar:{
      color: theme.palette.secondary.dark
  },
  icono:{
      verticalAlign : 'middle',
      display:'inline-flex',
      backgroundColor: 'yellow',
      color: '#fff',
      background: 'yellow',
      margin: '400'
  }
}));
 


const ModalSucursalesProvider = (props) => {
   
    //Estilos de componente
    const classes = estilos();

   //Variables de Modal de eliminar Sucursal
   const [open, setOpen] = React.useState(false);
   const handleClickOpenEliminarSucursal = (sucursal) => {setOpen(true);setSelectedSucursal(sucursal)};
   const handleCloseEliminarSucursal = () => {setOpen(false);};

    //Variables de Modal Editar Sucursal
    const [openModalEditar, setOpenModalEditar] = React.useState(false);
    const handleClickOpenEditarModal = (sucursal) => {setOpenModalEditar(true);setSelectedSucursal(sucursal)};
    const handleCloseEditarModal = () => {setOpenModalEditar(false);};
    
    //Variables de Modal Añadir Sucursal
    const [openModalAddSucursal, setOpenModalAddSucursal] = React.useState(false);
    const handleClickOpenAddSucursalModal = () => {setOpenModalAddSucursal(true);};
    const handleCloseAddSucursalModal = () => {setOpenModalAddSucursal(false);};

    const [selectedSucursal,setSelectedSucursal] = useState({});
 

const ModalSucursales = ()=>{

  /* CREAR SUCURSAL */
  const [nombre,setNombre] = useState('');

  /* EDITAR UNA SUCURSAL */ 
  const [nombrePivotEdit,setnombrePivotEdit] = useState(selectedSucursal.nombre);
  const [ciudadPivotEdit,setciudadPivotEdit] = useState(selectedSucursal.ciudad);
  const [listaPivotEdit,setListaPivotEdit] = useState(selectedSucursal.lista);

 //Variables de Error y loading
 const [nError,setnError] = useState(null);
 const [nOkResponse,setOkResponse] = useState(null);
 const [loadingFbQuery,setloadingFbQuery] = useState(false);
 const [botonesEnabled,setbotonesEnabled] = useState(true);

  //Variables y funciones de Selector lista de reproduccion
  const estados = EstadosLista ; 
  const [estado, setEstado] = React.useState('');

   //Variables y funciones de Selector listas de contenidos
  //const listasDeRepro = [{value:'listaComercialesX',label:'Lista de comercialesX'},{value:'listaPumas',label:'Lista de Pumas'},{value:'listaJulio',label:'Lista de Julio'}];
  const [listaDeRepro, setListaDeRepro] = React.useState('');
  const [listasDeRepro, setListasDeRepro] = React.useState([]);
   
   //Declaramos la funcion del efecto para hacer el fetch de los contenidos disponibles
 const obtenerListasDeContenidos = async()=>{
  try{ 
   db.collection('listaDeContenidos').onSnapshot(dataListaDeContenidos =>{
   console.log(dataListaDeContenidos.docs);
   //convertimos el objeto de fb a un array para el estado
  // const arrayListasDeContenidos = dataListaDeContenidos.docs.map(doc =>({id:doc.id,... doc.data()}));
 // const arrayListasDeContenidos = dataListaDeContenidos.docs.map(doc =>({value:doc.data.listaDeVideos,label:doc.data.nombre}));
 // const arrayListasDeContenidos = dataListaDeContenidos.docs.map(doc=>{return doc.data()});
 const arrayListasDeContenidos = dataListaDeContenidos.docs.map(doc=>({id:doc.id, ...doc.data()}));
 setListasDeRepro(arrayListasDeContenidos);
   console.log("listas de reproduccion " + JSON.stringify(arrayListasDeContenidos));
    })
  }
  catch (error){
    console.log(error);
  }
} 
  //Efectos del componente
 useEffect(()=>{
  //llamamos la funcion del efecto
  obtenerListasDeContenidos();
  console.log("obtener listas de contenidos")
},[]);


 //Funcion para eliminar sucursal
 const eliminarSucursal =  useCallback(async () =>{
 /* db.collection('sucursales').doc(selectedSucursal.id).delete()
                 .then(respuesta => {
                     console.log('Sucursal eliminada de los documentos ' + respuesta)

                    //db.collection('usuarios').

                     handleCloseEliminarSucursal();
                    //llamar el READ de usuarios
                   // obtenerSucursales();  
                    return "usuario Eliminado con Exito";
                 }).catch(error=>{
                   return error
                 });
*/
    var batch = db.batch();
    let deleteRef1 =  db.collection('sucursales').doc(selectedSucursal.id);
    batch.delete(deleteRef1);
    let deleteRef2;

    await db.collection('usuarios').where("sucursal.id","==",selectedSucursal.id).get().
    then(function (querySnapshot){
  
    console.log("en el query de borrar");
      querySnapshot.forEach((doc)=>{
        console.log("sucursal... " + doc.id+ "=>"+ JSON.stringify(doc.data()));
        //Update Ref en el usuario
        deleteRef2 = db.collection('usuarios').doc(doc.data().email);
        batch.update(deleteRef2,{"sucursal": ''});
      })
      console.log("pase ..")
      batch.commit().then(function(){
        handleCloseEliminarSucursal();
      console.log("se habra eliminado?")
      });
  
    }).catch(e=>{console.log(e + " error :" + e.error)})



 
  });

//Funcion para crear una sucursal
const crearSucursal = () =>{

  setnError(null);
  setloadingFbQuery(true);
  setbotonesEnabled(false);
  console.log("Vamos a intentar añadir una sucursal")
 
  if(!nombre.trim() || !estado.trim() || !listaDeRepro.trim()){
    console.log('Datos vacios');
    setloadingFbQuery(false);
    setbotonesEnabled(true);
    setnError('Campos Vacios');
    return
  }
  console.log('vamos a revisar')
 
  //Añadimos usuario
  fbCrearSucursal();
  
}
const fbCrearSucursal = async() =>{

  await db.collection('sucursales').add({
    nombre:nombre,
    ciudad:estado,
    lista:listaDeRepro,
    posicion: {latitude:28,longitude:-96}
  }).then((res)=>{
    console.log(res)
    setOkResponse("usuario creado " + res);
    setbotonesEnabled(true);
    setloadingFbQuery(false);
    handleCloseAddSucursalModal(false);

  }).catch(e=>{
    console.error(e);
    setnError(e);
  });

}

//Funcion para Updatear una sucursal 
const updateSucursal = () =>{
  setnError(null);
  setloadingFbQuery(true);
  setbotonesEnabled(false);
  console.log("Vamos a intentar editar una sucursal")
 
  if(!nombrePivotEdit.trim() || !ciudadPivotEdit.trim()){
    console.log('Datos vacios');
    setloadingFbQuery(false);
    setbotonesEnabled(true);
    setnError('Campos Vacios');
    return
  }
  console.log('vamos a comunicar con la DB')
 
  //Actualizamos sucursal
   fbEditarSucursal();
}

const fbEditarSucursal = async() =>{

  await db.collection('sucursales').doc(selectedSucursal.id).update({
    //nombre:nombrePivotEdit,
    ciudad:ciudadPivotEdit,
    lista:listaPivotEdit,
  }).then((res)=>{
    console.log("usuario updateado " + res)
    setOkResponse("Usuario actualizado ");
    setbotonesEnabled(true);
    setloadingFbQuery(false);
    
  }).catch(e=>{
    console.error(e);
    setnError(e);
  });

  var batch = db.batch();
  var updateRef = {};
  var updateSucur = {}; 
  

  //Update Sucursal el nombre
  updateSucur = db.collection('sucursales').doc(selectedSucursal.id);
  batch.update(updateSucur,{"nombre":nombrePivotEdit});

  await db.collection('usuarios').where("sucursal.id","==",selectedSucursal.id).get().
  then(function (querySnapshot){

  console.log("en el query");
    querySnapshot.forEach((doc)=>{
      console.log("hoooo" + doc.id+ "=>"+ JSON.stringify(doc.data()));
      //Update Ref en el usuario
      updateRef = db.collection('usuarios').doc(doc.data().email);
      batch.update(updateRef,{"sucursal":{...selectedSucursal,"nombre":nombrePivotEdit}});
    })

    batch.commit().then(function(){
      console.log("BATCHEDDDD")
      handleCloseEditarModal(false);
    });

  }).catch(e=>{console.log(e + " error :" + e.error)})
  
  
 

}




return(<>
        <>{/*MODAL ELIMINAR SUCURSAL */}
             <Dialog open={open} onClose={handleCloseEliminarSucursal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                 <DialogTitle className={classes.tituloModalEliminar} id="alert-dialog-title-EliminarSucursal">{"¿Desea eliminar la sucursal " + selectedSucursal.nombre + "?"}</DialogTitle>
                 <DialogContent>
                     <DialogContentText className={classes.textoModalEliminar} id="alert-dialog-description">
                         Si se borra la sucursal NO podra ser recuperada y todos los datos de analiticos seran tambien eliminados.
          </DialogContentText>
                 </DialogContent>
                 <DialogActions>
                     <Button variant="contained" color="primary" onClick={handleCloseEliminarSucursal}>
                         Cancelar
          </Button>
          <Button className={classes.buttonModal} variant="contained" color="secondary" onClick={() => eliminarSucursal ()} autoFocus>
                         Borrar
          </Button>
                 </DialogActions>
             </Dialog></>
        <>{/*MODAL EDITAR SUCUSAL */}
        <form className={classes.root} noValidate autoComplete="off">
        <Dialog maxWidth= 'sm' fullWidth open={openModalEditar} onClose={handleCloseEditarModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{"Editar sucursal "}
        <Typography  display='inline' variant ='h6' color="primary" >{selectedSucursal.nombre}</Typography>
        </DialogTitle>
        <Divider/>
             <DialogContent >
             <DialogContentText className={classes.root} pt='1'>
                Nombre de la sucursal
            </DialogContentText>
         <TextField
            autoFocus
            margin="dense"
            id="nameSucursal"
            value = {nombrePivotEdit}
            onChange = {(e)=>setnombrePivotEdit(e.target.value)}
            label="Sucursal"
            type="text"
            variant="outlined"
          /> 
        </DialogContent>
        <Divider/>
        <Grid  container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={6}>
        <DialogContent >
            <DialogContentText>Ciudad</DialogContentText>
          <TextField
          id="selectorDeCiudad"
          select
          label="Ciudad"
          value={ciudadPivotEdit}
          onChange={ (event)=>setciudadPivotEdit(event.target.value)}
          helperText="Selecciona una ciudad"
          variant="outlined">
          {estados.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        </DialogContent> 
        </Grid>    
        <Grid item xs={6}>
        <DialogContent>
        <DialogContentText>Lista de reproducción</DialogContentText>
        <TextField
          id="selectorDeListaDeRepro"
            select
            label="Lista"
            value={listaPivotEdit}
            onChange={event => setListaPivotEdit(event.target.value)}
            helperText="Selecciona una Lista"
            variant="outlined">
            {listasDeRepro.map((option) => (
              <MenuItem key={option.id} value={option.nombre}>
                {option.nombre}
              </MenuItem>
            ))}
        </TextField>
        </DialogContent>
        </Grid>
        </Grid>
        <Divider/>
        {  nError && ( <Typography  variant="subtitle1" align="center" color="error" children={nError}/> ) }
        {  nOkResponse && ( <Typography  variant="subtitle1" align="center" color="primary" children={nOkResponse}/> ) }
        { loadingFbQuery && ( <Progress/>) }
        <DialogActions>
          <Button className={classes.buttonModal} variant='contained' onClick={handleCloseEditarModal} color="secondary" disabled ={!botonesEnabled}>
            Cancelar
          </Button>
          <Button className={classes.buttonModal} variant = 'contained' onClick={updateSucursal} color="primary" disabled ={!botonesEnabled}>
            Actualizar
          </Button>
        </DialogActions>
      </Dialog>
      </form></>
        <>{/*MODAL AÑADIR SUCURSAL */}
        <form id="formSucursal" className={classes.root} noValidate autoComplete="off">
        <Dialog maxWidth= 'sm' fullWidth open={openModalAddSucursal} onClose={handleCloseAddSucursalModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="formAddSucursalTitulo">Añade una sucursal</DialogTitle>
        <Divider/>
             <DialogContent >
             <DialogContentText className={classes.root} pt='1'>
               Añade una Sucursal
            </DialogContentText>
         <TextField
            autoFocus
            margin="dense"
            id="nameAddSucursal"
            label="Nombre de Sucursal"
            value={nombre}
            onChange={e=>{ setNombre(e.target.value);}}
            type="text"
            variant="outlined"
          /> 
        </DialogContent>
        <Grid  container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={6}>
        <DialogContent >
            <DialogContentText>Elije la ciudad</DialogContentText>
          <TextField
          id="selectorDeCiudad"
          select
          label="Ciudad"
          value={estado}
          onChange={(e)=>{setEstado(e.target.value)}}
          helperText="Selecciona una ciudad"
          variant="outlined">
          {estados.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        </DialogContent> 
        </Grid>    
        <Grid item xs={6}>
        <DialogContent>
        <DialogContentText>Elije una lista de reproducción</DialogContentText>
        <TextField
          id="selectorDeListaDeRepro"
            select
            label="Lista"
            value={listaDeRepro}
            onChange={event =>setListaDeRepro(event.target.value)}
            helperText="Selecciona una Lista"
            variant="outlined">
            {listasDeRepro.map((option) => (
              <MenuItem key={option.id} value={option.nombre}>
                {option.nombre}
              </MenuItem>
            ))}
        </TextField>
        </DialogContent>
        </Grid>
        </Grid>
        <Divider/>
        {  nError && ( <Typography  variant="subtitle1" align="center" color="error" children={nError}/> ) }
        {  nOkResponse && ( <Typography  variant="subtitle1" align="center" color="primary" children={nOkResponse}/> ) }
        { loadingFbQuery && ( <Progress/>) }
        <DialogActions>
          <Button className={classes.buttonModal} variant='contained' onClick={handleCloseAddSucursalModal} color="secondary" disabled ={!botonesEnabled}>
            Cancelar
          </Button>
          <Button  className={classes.buttonModal} variant = 'contained' onClick={crearSucursal} color="primary" disabled ={!botonesEnabled}>
            Añadir Sucursal
          </Button>
        </DialogActions>
      </Dialog>
      </form></>
    </>)
}

return (
  <ModalSucursalesContext.Provider value={{handleClickOpenEliminarSucursal, handleClickOpenEditarModal,
                                      handleClickOpenAddSucursalModal, ModalSucursales}}> 
      {props.children}
  </ModalSucursalesContext.Provider>
)          
}

export default ModalSucursalesProvider;

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Divider  from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Mapa from '../Componentes/mapa';


const mapaInicio = () => {


    return (
        <Container maxWidth='xl'>
           <Typography color='textSecondary' variant="h5">Mapa de Sucursales</Typography>
           <Divider/>
           <Mapa />                 
        </Container>
    )
}

export default mapaInicio
